import FileSaver from "file-saver";
import { Button } from "@mui/material";
import { useSelector } from "react-redux";
import { ContentCopy, Download } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

import { apiRequest } from "../../utils";
import { serverUrl } from "../../component/ApiUrl";
import { notify, notifyFailed } from "../../component/Common/Notification";

export default function ListPerforma({ setTab, setPerformaData }) {
    let user = useSelector((state) => state.sar.data.user);

    const [performas, setPerformas] = useState([]);
    console.log("PERFORMAS - ", performas);
    useEffect(() => {
        const fetchData = async () => {
            const response = await apiRequest({
                url: `${serverUrl.u}/v3/performa/listByUser`,
                data: { createdBy: user._id },
                method: "POST",
            });

            if (!response.success) {
                notifyFailed(response.message);
                return;
            }
            setPerformas(response.data?.data?.performas);
        };

        fetchData();
    }, []);
    //   console.log(Object.keys(performas[0]));

    // HANDLE DOWNLOAD
    async function handleDownload(data) {
        console.log("DOWNLOAD data - ", data);

        notify("Regenrating your PI, please hold on.");
        const createPdf = await apiRequest({
            url: `${serverUrl.u}/api/tele/makePIPdf`,
            method: "POST",
            data: {
                ...data,
                performaNumber: data.piNumber,
            },
        });

        if (!createPdf.success) {
            notifyFailed("Failed to create PDF");
            notifyFailed(createPdf.message);
        }

        notify("Please wait 2 seconds to generate pdf");
        setTimeout(() => {
            FileSaver.saveAs(
                `${serverUrl.u + createPdf.data.urlObj[0].substring(6)}`,
                `${data.name}.pdf`
            );
        }, 2000);
    }

    // HANDLE DUPLICATION  OF PI
    function handleDuplicate(data) {
        setPerformaData(data);
        setTab("createPerforma");
    }
    const columns = [
        {
            field: "name",
            headerName: "Customer Name",
            width: 250,
        },
        {
            field: "piNumber",
            headerName: "Performa Number",
            width: 150,
        },
        {
            field: "item",
            headerName: "Number of Products",
        },
        {
            field: "product",
            headerName: "Product Names",
            width: 250,
        },
        {
            field: "TotalAmount",
            headerName: "Total Amount",
            width: 250,
        },
        {
            field: "billTo",
            headerName: "Billing Address",
            width: 250,
        },
        {
            field: "shipTo",
            headerName: "Shipping Address",
            width: 250,
        },
    ];

    // Add action buttons
    columns.push({
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params) => (
            <div style={{ display: "flex", gap: "10px" }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={() => handleDownload(params.row)}
                >
                    <Download />
                </Button>
                <Button
                    variant="outlined"
                    size="small"
                    style={{ color: "red", border: "1px solid red" }}
                    onClick={() => handleDuplicate(params.row)}
                    startIcon={<ContentCopy />}
                >
                    Duplicate
                </Button>
            </div>
        ),
    });
    const rows = performas.map((pi, i) => {
        return {
            ...pi,
            id: i, // Using index as unique ID
            product: pi.product.map((p) => p.name).join(", "),
            billTo: pi.billTo
                ? `${pi.billTo.address} ${pi.billTo.city}, ${pi.billTo.country}`
                : undefined,
            shipTo: pi.shipTo
                ? `${pi.shipTo.address} ${pi.shipTo.city}, ${pi.shipTo.country}`
                : undefined,
            item: pi.product.length,
            rawData: pi,
        };
    });
    return (
        <div>
            <h2>User Performa</h2>
            <div className="mt-4">
                {/* Table */}
                <div
                    className="table-container"
                    style={{
                        border: "1px solid #c6d0f0",
                        borderRadius: "10px",
                    }}
                >
                    <DataGrid
                        sx={{ minHeight: "70vh" }}
                        columns={columns}
                        rows={rows}
                        components={{
                            Toolbar: GridToolbar,
                        }}
                        componentsProps={{
                            toolbar: {
                                csvOptions: {
                                    fileName: `${
                                        user.first_name
                                    }_pi_${new Date().toLocaleDateString()}`,
                                },
                            },
                        }}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        pageSizeOptions={[5, 10]}
                    />
                </div>
            </div>
        </div>
    );
}
