import { Button } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import { CSVLink } from "react-csv";
import { serverUrl } from '../ApiUrl';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { daDK } from '@mui/x-data-grid';
import { orderTyepes } from '../Data';
import { UP_cities } from './data';

export default function NewInvoiceCsv() {
    let [csvData, setCsvData] = useState([])
    let [generate, setGenerate] = useState("Generate New Invoice CSV")
    let notify = (text) => toast(text)

    const outStanding = async (hex) => {
        return await axios.post(`${serverUrl.u}/app/tele/getOutstandingAmount`, {
            generatedHex: hex
        }).then(function (res) {
            console.log(res);
            if (res.data.success) {
                if (res.data.data.success) {
                    return res.data.data.outStandingAmount
                } else {
                    return 0
                }
            } else {
                return 0
            } 
        }).catch(function (err) {
            console.log(err);
            return 0
        })
    }
    const calculateInstallation = (inv)=>{
        if(inv.product.length>0){
         for(let i of inv.product){
          
             if(i.P_Id.trim()==="Installation"){
     
                 return i.sellingPricePerPic*i.qty
             }
         }
        }
        return 0
     }
    const getSubscriptionInvoice = async () => {
        setGenerate("Generating...")
        let bill = [];
        await axios.post(`${serverUrl.u}/api/tele/getAllInvoices`).then(
            async function (response) {
                let detail = response.data.data;
                console.log(detail, 'inv');
                detail.sort(function (a, b) {
                    return (a.invoiceNumber) - (b.invoiceNumber);
                });
                let Data = [];
                await axios.post(`${serverUrl.u}/api/tele/fetchLimitedData`).then(
                    function (res) {

                        if (res.data.success === true) {
                            Data = res.data.finalData
               
                        }
                    }
                ).catch(function (err) {
                    console.log(err);
                })
                let newData = []
                for (let d of Data) {
                    console.log(d.orderType, "drop");
                    if (d.orderType !== orderTyepes[2].value) {
                        let outStandingAmount = await outStanding(d.generatedHex)
                        newData.push({ ...d, Out_Standing_Amount: outStandingAmount })
                    } else {
                        newData.push({ ...d, Out_Standing_Amount: 0 })
                    }
                }
                for (let e of detail) {

                    let findData = newData.find(ele => ele.generatedHex === e.generatedHex)
                   let pro ={}
                    if (e.product.length > 0) {
                        if (e.product.length > 11) {
                            notify("Product is More than 11 Plz change Csv")
                        }
                        let obj = { ...e }
                        let count = 0
                        for(let i=0;i<e.product.length;i++){
                            let d=e.product[i]
                            pro={...pro,[`Product${i + 1}`]:`Product Name :${d.productName === undefined ? d.P_Id : d.productName} ,Selling Price :${d.sellingPricePerPic === undefined ? d.price : d.sellingPricePerPic} ,Qty :${d.qty} ,Hsn Code:${d.hsn_code}`}
                        }
                        for (let p of e.product) {
                            
                            obj = { ...obj, [`Product${count + 1}`]: p }
                            count++;
                        }
                        e = { ...obj }

                    }
                    let changesInSubscriptionLastObject = {}
                    if (findData !== undefined) {
                        changesInSubscriptionLastObject = findData.changesInSubscription[findData.changesInSubscription.length - 1]
                    }
                     console.log(pro,"pro");
                    ///new start
                        console.log(findData,"sub",e,"inv");
                    
                        bill.push({
                            "Invoice Number": e.invoiceNumber>7975?e.invoiceNumber-7975:(e.invoiceNumber>1200?e.invoiceNumber-1200:e.invoiceNumber),
                            "Client Name": `${e.name}('${e.generatedHex})`,
                            "Whether B2B or B2C": findData !== undefined ? ((findData.gstNumber === "") ? (e.BusinessGst !== "" ? "Yes" : "No") : (findData.gstNumber !== "" ? "Yes" : "No")) : "Subscription Not Found",
                            "GST Name": findData !== undefined ? ((findData.gstName === "") ? (e.BillTo) : (findData.gstName)) : "Subscription Not Found",
                            "GST Number": findData !== undefined ? ((findData.gstNumber === "") ? (e.BusinessGst) : (findData.gstNumber)) : "Subscription Not Found",
                            "Status": e.status,
                            "Is First Invoice": e.isFirstInvoice === true ? "Yes" : "No",
                            "TeleCaller Name": findData !== undefined ? (findData.teleCallerId) : "Subscription Not found",
                            "Second Sales Person": findData !== undefined ? (findData.secondarySalesPerson) : "Subscription Not found",
                            "Phone": findData !== undefined ? "`"+findData.phone : "subscription not found",
                            "House Id": findData !== undefined ? "`"+findData.houseId : "subscription not found",
                            "Email": findData !== undefined ? findData.email : "subscription not found",
                            "Invoice Upgrade Downgrade": e.isInvoiceUpgradeDowngrade === true ? "Yes" : "No",
                            "Current Appliances": e.basicDetails !== undefined ? (e.basicDetails.appliances !== undefined ? e.basicDetails.appliances : "") : findData !== undefined ? (((findData.partialPayment && changesInSubscriptionLastObject!==undefined && Object.keys(changesInSubscriptionLastObject).length !== 0) ? changesInSubscriptionLastObject.oldAppliances : findData.totalAppliances)) : "Subscription Not found",
                            "Old Appliances":e.isInvoiceUpgradeDowngrade === true?((findData!==undefined && e.isInvoiceUpgradeDowngrade && changesInSubscriptionLastObject!==undefined && Object.keys(changesInSubscriptionLastObject).length!==0)?changesInSubscriptionLastObject.oldAppliances:""):"",            
                            "Diff btw appliances":e.isInvoiceUpgradeDowngrade === true?((findData!==undefined && changesInSubscriptionLastObject!==undefined && Object.keys(changesInSubscriptionLastObject).length!==0)?((e.basicDetails !== undefined ? (e.basicDetails.appliances !== undefined ? e.basicDetails.appliances : 0) : findData !== undefined ? (((findData.partialPayment && changesInSubscriptionLastObject!==undefined && Object.keys(changesInSubscriptionLastObject).length !== 0) ? changesInSubscriptionLastObject.oldAppliances : findData.totalAppliances)) : 0)-changesInSubscriptionLastObject.oldAppliances):0):'',
                            "Reson of Converted":e.isInvoiceUpgradeDowngrade === true?((findData !== undefined) ? ((changesInSubscriptionLastObject!==undefined && Object.keys(changesInSubscriptionLastObject).length !== 0 ? (`${changesInSubscriptionLastObject.changeInSubscription} - ${changesInSubscriptionLastObject.statusOfSubscription}`) : "")) : ""):'' ,
                            "Dropped Client": findData !== undefined ? (findData.Dropped_Client === true ? "Dropped" : "Active") : "Subscription Not Found",
                            "HexCode":findData !== undefined ? ("`"+findData.generatedHex) : "Subscription Not found",
                            "Date":moment(e.createdTime).format("MMM Do YYYY"),
                            "City":findData !== undefined ? (findData.city) : "Subscription Not found",
                            "Location for gst":findData !== undefined ? (findData.city) : "Subscription Not found",
                            "Zoho invoice number": e.zohoInvoiceNumber,
                            "Plan Frequency": e.is_UpSell?"UpSell":(e.basicDetails !== undefined ? (e.basicDetails.planFrequency !== undefined ? e.basicDetails.planFrequency : "") : findData !== undefined ? (((findData.partialPayment && changesInSubscriptionLastObject!==undefined && Object.keys(changesInSubscriptionLastObject).length !== 0) ? changesInSubscriptionLastObject.planFrequency : findData.planFrequency)) : "Subscription Not found"),
                            "Recursive":e.is_UpSell?0:(e.additionalInfo !== undefined && e.additionalInfo.length!==0 ? (Number(e.additionalInfo[0].item_total)).toFixed(2) : (e.actualTotalAmount / 1.18)),
                            "Installation":e.basicDetails !== undefined ? (e.basicDetails.installation !== undefined ? e.basicDetails.installation : "") : findData !== undefined ? (((findData.partialPayment) ? findData.installation : findData.currentInstallation)) : "Subscription Not found",
                            "Upsell":(e.is_UpSell && findData!==undefined)?findData.recursiveAmount-calculateInstallation(e):0,
                            "Upsell Installation":(e.is_UpSell && findData!==undefined)?calculateInstallation(e):0,
                            "Taxable Value":e.is_UpSell?(findData!==undefined?((Number(findData.recursiveAmount)+Number(findData.installation))).toFixed(2):0):(((e.additionalInfo !== undefined && e.additionalInfo.length!==0 ? (Number(e.additionalInfo[0].item_total)) : Number((e.actualTotalAmount).toFixed(2))) + (e.basicDetails !== undefined ? (e.basicDetails.installation !== undefined ? Number(e.basicDetails.installation) : 0) : findData !== undefined ? (((findData.partialPayment) ? Number(findData.installation) : Number(findData.currentInstallation))) : 0) )),
                            "IGST":findData!==undefined && !UP_cities.includes(findData.city.toUpperCase()) ? (e.is_UpSell?(findData!==undefined?((Number(findData.recursiveAmount)+Number(findData.installation))*0.18).toFixed(2):0):(((e.additionalInfo !== undefined && e.additionalInfo.length!==0 ? (Number(e.additionalInfo[0].item_total)) : Number((e.actualTotalAmount / 1.18).toFixed(2))) + (e.basicDetails !== undefined ? (e.basicDetails.installation !== undefined ? Number(e.basicDetails.installation) : 0) : findData !== undefined ? (((findData.partialPayment) ? Number(findData.installation) : Number(findData.currentInstallation))) : 0) )*0.18)):"",
                            "CGST":findData!==undefined && UP_cities.includes(findData.city.toUpperCase()) ? (e.is_UpSell?(findData!==undefined?((Number(findData.recursiveAmount)+Number(findData.installation))*0.09).toFixed(2):0):(((e.additionalInfo !== undefined && e.additionalInfo.length!==0 ? (Number(e.additionalInfo[0].item_total)) : Number((e.actualTotalAmount / 1.09).toFixed(2))) + (e.basicDetails !== undefined ? (e.basicDetails.installation !== undefined ? Number(e.basicDetails.installation) : 0) : findData !== undefined ? (((findData.partialPayment) ? Number(findData.installation) : Number(findData.currentInstallation))) : 0) )*0.09)):"",
                            "SGST":findData!==undefined && UP_cities.includes(findData.city.toUpperCase()) ? (e.is_UpSell?(findData!==undefined?((Number(findData.recursiveAmount)+Number(findData.installation))*0.09).toFixed(2):0):(((e.additionalInfo !== undefined && e.additionalInfo.length!==0 ? (Number(e.additionalInfo[0].item_total)) : Number((e.actualTotalAmount / 1.09).toFixed(2))) + (e.basicDetails !== undefined ? (e.basicDetails.installation !== undefined ? Number(e.basicDetails.installation) : 0) : findData !== undefined ? (((findData.partialPayment) ? Number(findData.installation) : Number(findData.currentInstallation))) : 0) )*0.09)):"",
                            "Invoice Value":e.is_UpSell?(findData!==undefined?((Number(findData.recursiveAmount)+Number(findData.installation))*1.18).toFixed(2):0):(((e.additionalInfo !== undefined && e.additionalInfo.length!==0 ? (Number(e.additionalInfo[0].item_total)) : (e.actualTotalAmount / 1.18)) + (e.basicDetails !== undefined ? (e.basicDetails.installation !== undefined ? Number(e.basicDetails.installation) : 0) : findData !== undefined ? (((findData.partialPayment) ? Number(findData.installation) : Number(findData.currentInstallation))) : 0) )*1.18).toFixed(2),
                            "Closing Balance":findData !== undefined ? findData.Out_Standing_Amount : "No Subscription Found",
                            "Master Cancel":e.masterCancel === true ? "Yes" : "No",
                            "Transaction mode & id": e.trnxDetails.map((ele) => ele.gateway_transaction_id !== undefined ? `${ele.payment_mode} - ${ele.amount} - ${ele.gateway_transaction_id}  ` : `${ele.payment_mode} - ${ele.amount} - ${ele.transaction_id}  `),
                            "Transaction RazerPay": e.trnxRZP.map((ele) => `${ele.payment_mode} - ${ele.amount} - ${ele.gateway_transaction_id}  `),
                            "Transaction Bank Transfer": e.trnxBankCash.map((ele) => `${ele.payment_mode} - ${ele.amount} -> Transaction Number ${ele.gateway_transaction_id}  `),
                            "Transaction Credit Note": e.trnxCreditNotes.map((ele) => `${ele.payment_mode} -> Credit Note Number ${ele.gateway_transaction_id} -> ${ele.amount}   `),
                            "Difference BW final total & tranasction amount": findData !== undefined ? (Number(e.actualTotalAmount) - (e.trnxDetails.reduce((previousValue, currentValue) => {
                                return previousValue + Math.round(currentValue.amount)
                            }, 0) + e.trnxRZP.reduce((previousValue, currentValue) => {
                                return previousValue + Math.round(currentValue.amount)
                            }, 0) + e.trnxCreditNotes.reduce((previousValue, currentValue) => {
                                return previousValue + Math.round(currentValue.amount)
                            }, 0) + e.trnxBankCash.reduce((previousValue, currentValue) => {
                                return previousValue + Math.round(currentValue.amount)
                            }, 0))) : "Subscription not found",
                            "Total Product": e.product.length,
                            // "Product1": e.hasOwnProperty('Product1') ? `Product Name :${e.Product1.productName === undefined ? e.Product1.P_Id : e.Product1.productName} ,Selling Price :${e.Product1.sellingPricePerPic === undefined ? e.Product1.price : e.Product1.sellingPricePerPic} ,Qty :${e.Product1.qty} ,Hsn Code:${e.Product1.hsn_code}` : "",
                            // "Product2": e.hasOwnProperty('Product2') ? `Product Name :${e.Product2.productName === undefined ? e.Product2.P_Id : e.Product2.productName} ,Selling Price :${e.Product2.sellingPricePerPic === undefined ? e.Product2.price : e.Product2.sellingPricePerPic} ,Qty :${e.Product2.qty} ,Hsn Code:${e.Product2.hsn_code}` : "",
                            // "Product3": e.hasOwnProperty('Product3') ? `Product Name :${e.Product3.productName === undefined ? e.Product3.P_Id : e.Product3.productName} ,Selling Price :${e.Product3.sellingPricePerPic === undefined ? e.Product3.price : e.Product3.sellingPricePerPic} ,Qty :${e.Product3.qty} ,Hsn Code:${e.Product3.hsn_code}` : "",
                            // "Product4": e.hasOwnProperty('Product4') ? `Product Name :${e.Product4.productName === undefined ? e.Product4.P_Id : e.Product4.productName} ,Selling Price :${e.Product4.sellingPricePerPic === undefined ? e.Product4.price : e.Product4.sellingPricePerPic} ,Qty :${e.Product4.qty} ,Hsn Code:${e.Product4.hsn_code}` : "",
                            // "Product5": e.hasOwnProperty('Product5') ? `Product Name :${e.Product5.productName === undefined ? e.Product5.P_Id : e.Product5.productName} ,Selling Price :${e.Product5.sellingPricePerPic === undefined ? e.Product5.price : e.Product5.sellingPricePerPic} ,Qty :${e.Product5.qty} ,Hsn Code:${e.Product5.hsn_code}` : "",
                            // "Product6": e.hasOwnProperty('Product6') ? `Product Name :${e.Product6.productName === undefined ? e.Product6.P_Id : e.Product6.productName} ,Selling Price :${e.Product6.sellingPricePerPic === undefined ? e.Product6.price : e.Product6.sellingPricePerPic} ,Qty :${e.Product6.qty} ,Hsn Code:${e.Product6.hsn_code}` : "",
                            // "Product7": e.hasOwnProperty('Product7') ? `Product Name :${e.Product7.productName === undefined ? e.Product7.P_Id : e.Product7.productName} ,Selling Price :${e.Product7.sellingPricePerPic === undefined ? e.Product7.price : e.Product7.sellingPricePerPic} ,Qty :${e.Product7.qty} ,Hsn Code:${e.Product7.hsn_code}` : "",
                            // "Product8": e.hasOwnProperty('Product8') ? `Product Name :${e.Product8.productName === undefined ? e.Product8.P_Id : e.Product8.productName} ,Selling Price :${e.Product8.sellingPricePerPic === undefined ? e.Product8.price : e.Product8.sellingPricePerPic} ,Qty :${e.Product8.qty} ,Hsn Code:${e.Product8.hsn_code}` : "",
                            // "Product9": e.hasOwnProperty('Product9') ? `Product Name :${e.Product9.productName === undefined ? e.Product9.P_Id : e.Product9.productName} ,Selling Price :${e.Product9.sellingPricePerPic === undefined ? e.Product9.price : e.Product9.sellingPricePerPic} ,Qty :${e.Product9.qty} ,Hsn Code:${e.Product9.hsn_code}` : "",
                            // "Product10": e.hasOwnProperty('Product10') ? `Product Name :${e.Product10.productName === undefined ? e.Product10.P_Id : e.Product10.productName} ,Selling Price :${e.Product10.sellingPricePerPic === undefined ? e.Product10.price : e.Product10.sellingPricePerPic} ,Qty :${e.Product10.qty} ,Hsn Code:${e.Product10.hsn_code}` : "",
                            // "Product11": e.hasOwnProperty('Product11') ? `Product Name :${e.Product11.productName === undefined ? e.Product11.P_Id : e.Product11.productName} ,Selling Price :${e.Product11.sellingPricePerPic === undefined ? e.Product11.price : e.Product11.sellingPricePerPic} ,Qty :${e.Product11.qty} ,Hsn Code:${e.Product11.hsn_code}` : "",
                             ...pro,
                             "Plan Code": (findData !== undefined && e.additionalInfo!==undefined && e.additionalInfo[0]!==undefined && e.additionalInfo[0].code!==undefined) ? e.additionalInfo[0].code: 0,
                             "Price": (findData !== undefined && e.additionalInfo!==undefined && e.additionalInfo[0]!==undefined && e.additionalInfo[0].price!==undefined) ? e.additionalInfo[0].price: 0,
                             "Quantity":(findData !== undefined && e.additionalInfo!==undefined && e.additionalInfo[0]!==undefined && e.additionalInfo[0].quantity!==undefined) ? e.additionalInfo[0].quantity: 0,
                        })
                    

                  
                }
                setCsvData(bill)
                setGenerate("Generate New Invoice CSV")
            })
            .catch(function (error) {
                console.log(error);
                setGenerate("Failed Retry")
            })
    }

    return (
        <>
            {
                csvData.length === 0 ?
                    <Button variant='contained' sx={{ fontSize: "10px", padding: "5px", ml: 2 }} onClick={() => getSubscriptionInvoice()}>{generate}</Button>
                    : <CSVLink data={csvData}><Button variant='contained' sx={{ fontSize: "10px", padding: "5px", ml: 2 }}>Download New Csv</Button></CSVLink>
            }
            <ToastContainer />
        </>
    )
}
