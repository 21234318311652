import React from 'react'
import ConfirmationModalMui from '../../Model/ConfirmationModalMui'
import { useState } from 'react'
import { pages } from '../../ApiUrl'
import ModalUpgradeDownGrade from './ModalUpgradeDownGrade'

function UpgradeDownGradeV2({sub,setReload,reload}) {
    const [deviceDifference,setDeviceDifference] = useState(false)
    
    
  return (
    <div>
        <ConfirmationModalMui 
          btnName={sub.reeInstallation?"ReInstallation Done":(sub.grivience?"Grievance Done":"Installation Done")} 
          Sub={sub} 
          setBasicPop={setDeviceDifference} 
          page={pages.elctrician}
        />
        <ModalUpgradeDownGrade 
          Sub={sub} 
          deviceDifference={deviceDifference}
          setDeviceDifference={setDeviceDifference} 
          setReload={setReload} 
          reload={reload}
        />
    </div>
  )
}

export default UpgradeDownGradeV2