import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { addGrevianceClient } from '../ApiCalls/AddGreviance';
import { Button } from '@mui/material';

export default function RegiterGrivance({data,reload,setReload,notify}) {
    let [btn,setBtn] = useState("Grievance")
    let [grievancePop,setGrievancePop] = useState(false)
    let [deviceObject,setDeviceObject] = useState({decision:"no"})
    let loginName = useSelector((state) => state.sar.data.user.first_name);
    let handleSumit =async(event)=>{
        event.preventDefault();
        setBtn("Loading...")
        const datas = new FormData(event.currentTarget);

        let greivanceData = {
            hex:datas.get('hex'),
            date:datas.get('date'),
            comment:datas.get('comment'),
            priority:datas.get('priority'),
            problemType:datas.get('problemType'),
            sendTo:datas.get('sendTo')
        }
        if(greivanceData.problemType===""){
            notify("Select Problem Type")
            setBtn("Grievance")
            return
        }
        let dateOfToday = new Date()
       await addGrevianceClient(
            greivanceData.hex,
            deviceObject,
            greivanceData.comment,
            greivanceData.problemType,
            loginName,
            dateOfToday,
            notify,
            setReload,
            reload,
            setGrievancePop,
            data,
            greivanceData.priority,
            greivanceData.sendTo
          )
         setBtn("Grievance")
    
    }
  return (
    <>
       <button 
         className='btn btn-primary' 
         onClick={()=>setGrievancePop(true)}
         style={{display:(data.subscriptionStage==="grivience")?"none":""}}
       >{btn}</button>

       {grievancePop === true ? (
        <div style={{position:"fixed",top:0,left:0,height:"100vh",width:"100vw",overflowX:"scroll",zIndex:4,display:"flex",justifyContent:"center"}}>
        <div  style={{backgroundColor:"whitesmoke",width:"25%",padding:"10px",borderRadius:"12px",position:"relative",top:"100px",marginBottom:"30px",height:"fit-content"}}>
          <div style={{width:"100%",textAlign:"right"}}>  
          <span
            className="cross"
            onClick={() => setGrievancePop(false)}
          >
            x
          </span>
          </div>
          <form onSubmit={handleSumit}>
          <div className="input-group mb-1">
            <input
              style={{width:"100%"}}
              type="text"
            //   ref={grivance}
              name='hex'
              autoComplete='hex'
              placeholder="Enter Hex Code here..."
              defaultValue={data.generatedHex}
            />
          </div>
          {/* <div className="input-group mb-1">
            <input 
               type="date"
               style={{width:"100%"}}
            //    ref={grivanceDate} 
               name="date"
               required
               autoComplete='current-date'
            />
          </div> */}
          <div className="input-group mb-1">
            <input
              type="text"
              style={{width:"100%"}}
            //   ref={grivanceComment}
              required
              name='comment'
              autoComplete='comment'
              placeholder="Enter Comment here..."
              defaultValue=""
            />
          </div>
        
            <label>Priority</label>
            <div className="input-group mb-1">
            <select
            style={{width:"100%"}}
              defaultValue={"false"}
              name='priority'
              autoComplete='current-priority'
            //   onChange={(e)=>setGrivancePriority(e.target.value)}
            >
              <option value="false">No</option>
              <option value="true">Yes</option>
            </select>
          </div>
          
          <label>Assign To</label>
            <div className="input-group mb-1">
            <select
            style={{width:"100%"}}
              defaultValue={"false"}
              name='sendTo'
              autoComplete='current-sendTo'
            //   onChange={(e)=>setGrivancePriority(e.target.value)}
            >
              <option value="Vikas">Vikas</option>
              <option value="Heena">Heena</option>
              <option value="Sidharth">Sidharth</option>
            </select>
          </div>
          <label>Problem Type</label>
          <div className="input-group mb-1">
            
            <select
            style={{width:"100%"}}
            //   onChange={(e) => setGrivanceType(e.target.value)}
              name="problemType"
              autoComplete='current-problemType'
            //   defaultValue={GerivanceType}
            >
              <option value={""}>Select</option>  
              <option value="WiFi">WiFi</option>
              <option value="Hanging">Hanging</option>
              <option value="Power">Power</option>
              <option value="Switch">Switch</option>
              <option value="Misc">Misc</option>
            </select>
          </div>
          {/* <div className="input-group mb-2">
            <label>If you want to send devices select Yes</label>
            <select
              onChange={(e) =>
                setDeviceObject({ ...deviceObject, decision: e.target.value })
              }
              defaultValue="no"
            >
              <option value="no">No</option>
              <option value="yes">Yes</option>
            </select>
          </div>
          {deviceObject.decision == "yes" ? (
            <div className="DeviceObjectDetails">
              <div className="DeviceObjectDetails-specific">
                <label>One Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, one: e.target.value })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>Two Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, two: e.target.value })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>Three Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, three: e.target.value })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>4 + 1 Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, four: e.target.value })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>Five Switch</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, five: e.target.value })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>IR</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, ir: e.target.value })
                  }
                />
              </div>
              <div className="DeviceObjectDetails-specific">
                <label>Fan</label>
                <input
                  type="number"
                  onWheel={(event) => event.currentTarget.blur()}
                  onChange={(e) =>
                    setDeviceObject({ ...deviceObject, fan: e.target.value })
                  }
                />
              </div>
            </div>
          ) : null} */}
          <Button
            variant='contained'
            sx={{w:100}}
            type='submit'
            style={{pointerEvents:btn==="Loading..."?"none":""}}
          >
            {btn}
          </Button>
          </form>
        </div>
        </div>
      ) : null}
    </>
  )
}
