import { Box, Button, Grid, TextField } from "@mui/material";
import { Formik } from "formik";
import React, { useState, useRef } from "react";
import { CitySelect, StateSelect } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import './Smart.scss'
import { smartMeterClientRegister } from "./Api";
import { ToastContainer } from "react-toastify";
const phoneRegex = /^\+[1-9]{1}[0-9]{10,14}$/;
const passwordRegex = /^[A-Za-z\d]{8,}$/;

function SmartMeterClientRegister() {
  const [stateid, setStateid] = useState(0);
  const [stateidB, setStateidB] = useState(0);
  const formikRef = useRef(null);

  const handleExternalSubmit = () => {
    if (formikRef.current) {
      formikRef.current.submitForm();
    }
  };

  return (
    <Box>
        <ToastContainer/>
      <Formik
        innerRef={formikRef}
        initialValues={{
          password: "",
          name: "",
          username: "",
          phone: "",
          alternateEmails: "",
          numbers: "",
          gstName: "",
          gstNumber: "",
          billingAddress: "",
          shippingAddress: "",
          state: "",
          city: "",
          stateB:'',
          cityB: "",
        }}
        validate={(values) => {
          const errors = {};

          if (!values.name) {
            errors.name = "Required";
          }

          if (!values.username) {
            errors.username = "Required";
          } 
        

        //   if (!values.phone) {
        //     errors.phone = "Required";
        //   } else if (!phoneRegex.test(values.phone)) {
        //     errors.phone = "Invalid phone number.";
        //   }

          if (!values.alternateEmails) {
            errors.alternateEmails = "Required";
          }

          if (!values.numbers) {
            errors.numbers = "Required";
          }

          if (!values.gstName) {
            errors.gstName = "Required";
          }

          if (!values.gstNumber) {
            errors.gstNumber = "Required";
          }

          if (!values.billingAddress) {
            errors.billingAddress = "Required";
          }

          if (!values.shippingAddress) {
            errors.shippingAddress = "Required";
          }

          if (!values.state) {
            errors.state = "Required";
          }

          if (!values.city) {
            errors.city = "Required";
          }
          if (!values.stateB) {
            errors.stateB = "Required";
          }

          if (!values.cityB) {
            errors.cityB = "Required";
          }

          if (!values.password) {
            errors.password = "Required";
          } else if (!passwordRegex.test(values.password)) {
            errors.password =
              "Password must be at least 8 characters long and contain at least one letter and one number.";
          }

          return errors;
        }}
        onSubmit={async(values, { setSubmitting }) => {
          console.log("Submitted values: ", values);
          let numberss = values.numbers.split(',');

    // Iterate over each number
    for (let i = 0; i < numberss.length; i++) {
        // Check if the number doesn't start with "+91"
        if (!numberss[i].startsWith("+91")) {
            // If it doesn't, prepend "+91" to it
            numberss[i] = "+91" + numberss[i];
        }
    }
          let data = {
            "name": values.name,
            "legalName": values.gstName,
            "email": values.username,  // Required
            "alternateEmails": values.alternateEmails.split(','),
            "numbers": numberss,  // Required
            "billingAddress": {
                           "gstName":values.gstName,
                           "gstNumber":values.gstNumber,
                           "billingAddress":values.billingAddress,
                           "city":values.cityB.toUpperCase(),
                           "state":values.stateB.toUpperCase()
                     },
            "shippingAddress":  {
                           "gstName":values.gstName,
                           "gstNumber":values.gstNumber,
                           "shippingAddress":values.billingAddress,
                           "city":values.city.toUpperCase(),
                           "state":values.state.toUpperCase()
                     },
            "password":values.password // required
        }
       await smartMeterClientRegister(data)
       
          setTimeout(() => {
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit}>
            <Grid
              container
              spacing={2}
              sx={{
                width: "80vw",
                margin: "auto",
              }}
              autoComplete="off"
            >
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <TextField
                  error={Boolean(errors.name && touched.name)}
                  label="Name"
                  placeholder="Name"
                  name="name"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.name}
                  autoComplete="new-name"
                  helperText={errors.name && touched.name && errors.name}
                />
              </Grid>

              {/* <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <TextField
                  type="text"
                  name="username"
                  label="User Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  autoComplete="new-username"
                  error={Boolean(errors.username && touched.username)}
                  helperText={
                    errors.username && touched.username && errors.username
                  }
                />
              </Grid> */}

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <TextField
                  type="text"
                  name="username"
                  label="username"
                  placeholder="username"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.username}
                  autoComplete="new-username"
                  error={Boolean(errors.username && touched.username)}
                  helperText={errors.username && touched.username && errors.username}
                />
              </Grid>

        

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <TextField
                  type="text"
                  name="alternateEmails"
                  label="Add Emails Separated With ','"
                  placeholder="Add Emails Separated With ','"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.alternateEmails}
                  autoComplete="new-alternateEmails"
                  error={Boolean(
                    errors.alternateEmails && touched.alternateEmails
                  )}
                  helperText={
                    errors.alternateEmails &&
                    touched.alternateEmails &&
                    errors.alternateEmails
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <TextField
                  type="text"
                  name="numbers"
                  label="Add Numbers Separated With ','"
                  placeholder="Add Numbers Separated With ','"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.numbers}
                  autoComplete="new-numbers"
                  error={Boolean(errors.numbers && touched.numbers)}
                  helperText={errors.numbers && touched.numbers && errors.numbers}
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <TextField
                  error={Boolean(errors.gstName && touched.gstName)}
                  label="GST Name"
                  placeholder="GST Name"
                  name="gstName"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gstName}
                  autoComplete="new-gstName"
                  helperText={
                    errors.gstName && touched.gstName && errors.gstName
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <TextField
                  error={Boolean(errors.gstNumber && touched.gstNumber)}
                  label="GST Number"
                  placeholder="GST Number"
                  name="gstNumber"
                  type="text"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.gstNumber}
                  autoComplete="new-gstNumber"
                  helperText={
                    errors.gstNumber && touched.gstNumber && errors.gstNumber
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <TextField
                  type="text"
                  name="billingAddress"
                  label="Billing Address"
                  placeholder="Add Billing Address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.billingAddress}
                  autoComplete="new-billingAddress"
                  error={Boolean(
                    errors.billingAddress && touched.billingAddress
                  )}
                  helperText={
                    errors.billingAddress &&
                    touched.billingAddress &&
                    errors.billingAddress
                  }
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <div className="autocomplete-new-state">
                  <StateSelect
                    name="stateB"
                    countryid={101}
                    onChange={(e) => {
                      setStateidB(e.id);
                      setFieldValue("stateB", e.name.toUpperCase());
                    }}
                    placeHolder="Select Billing State"
                  />
                  {errors.stateB && touched.stateB && (
                    <div className="error-input">
                      {errors.stateB}
                    </div>
                  )}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <div className="autocomplete-new-state">
                  <CitySelect
                    countryid={101}
                    stateid={stateidB}
                    name="cityB"
                    onChange={(e) => {
                      setFieldValue("cityB", e.name.toUpperCase());
                    }}
                    placeHolder="Select Billing City"
                  />
                  {errors.cityB && touched.cityB && (
                    <div className="error-input">
                      {errors.cityB}
                    </div>
                  )}
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <TextField
                  type="text"
                  name="shippingAddress"
                  label="Shipping Address"
                  placeholder="Add Shipping Address"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.shippingAddress}
                  autoComplete="new-shippingAddress"
                  error={Boolean(
                    errors.shippingAddress && touched.shippingAddress
                  )}
                  helperText={
                    errors.shippingAddress &&
                    touched.shippingAddress &&
                    errors.shippingAddress
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <div className="autocomplete-new-state">
                  <StateSelect
                    name="state"
                    countryid={101}
                    onChange={(e) => {
                      setStateid(e.id);
                      setFieldValue("state", e.name.toUpperCase());
                    }}
                    placeHolder="Select Shipping State"
                  />
                  {errors.state && touched.state && (
                    <div className="error-input">
                      {errors.state}
                    </div>
                  )}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <div className="autocomplete-new-state">
                  <CitySelect
                    countryid={101}
                    stateid={stateid}
                    name="city"
                    onChange={(e) => {
                      setFieldValue("city", e.name.toUpperCase());
                    }}
                    placeHolder="Select Shipping City"
                  />
                  {errors.city && touched.city && (
                    <div className="error-input">
                      {errors.city}
                    </div>
                  )}
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                md={6}
                lg={6}
                sx={{ display: "flex", flexDirection: "column" }}
              >
                <TextField
                  type="password"
                  name="password"
                  label="Password"
                  autoComplete="new-password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.password}
                  error={Boolean(errors.password && touched.password)}
                  helperText={
                    errors.password && touched.password && errors.password
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <Button type="submit" variant="contained" disabled={isSubmitting}>
                  Register
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </Box>
  );
}

export default SmartMeterClientRegister;
