import React, { useState, useEffect } from "react";
import { InvoiceGenerate } from "../Function/InvoieDownloadFun";
import { serverUrl } from "../ApiUrl";
import PDFViewer from "pdf-viewer-reactjs";
import FileSaver from "file-saver";
import JSZip from "jszip";


const InvoiceView = ({ e, ele, notify }) => {
  const [url, setUrl] = useState("");
  const [loading,setLoading] = useState(false)
  const InvoiceGenerated = async () => {
    setLoading(true)
    try {
        let res = await InvoiceGenerate(ele, e, notify);
   
        if (res[0] === "then") {
          if (res[1].status === 200) {
            console.log(e.is_UpSell, "errfd");
            if (!e.is_UpSell) {
              const zip = new JSZip();
              const zipContent = await zip.loadAsync(res[1].data);
              for (let file in zipContent?.files) {
                // Extract the PDF file
                const pdfFile = zipContent?.files[file];
                if (pdfFile) {
                  const pdfBlob = await pdfFile.async("blob");
                  FileSaver.saveAs(pdfBlob, `${e.name} - invoice.pdf`);
                } else {
                  console.error("PDF file not found in the ZIP archive.");
                }
              }
            } else {
              // Create a Blob from the response data (PDF)
              const blob = new Blob([res[1].data], { type: "application/pdf" });
    
              // Use file-saver to download the file
              FileSaver.saveAs(blob, `${e.name} - invoice.pdf`);
            }
          } else {
            notify("Failed");
          }
        }else {
          notify("Failed");
        }
        setLoading(false)
    } catch (error) {
        setLoading(false)
    }
  };

  return (
    <>
      <button className="btn btn-primary" disabled={loading} onClick={() => InvoiceGenerated()}>
       { loading?"Looading...":"Invoice View"}
      </button>
    
      {
        url!=="" && (
            <div style= {{height:'100vh',position:'fixed',top:0,left:0,width:'100vw',display:'flex',justifyContent:'center',alignItems:'center',zIndex:123,backdropFilter: "brightness(0.5)"}}>
           
            <div style={{height:'80%',width:'80%',overflow:'scroll',position:'relative'}}>
            <div style={{position:'absolute',right:'10%',cursor:'pointer',backgroundColor:'white',borderRadius:'100%',width:'30px',display:'flex',alignItems:'center',justifyContent:'center'}}  onClick={()=>setUrl("")}><span style={{fontSize:'22px'}}>X</span></div>
           <PDFViewer
            document={{
                url,
            }}
        />
        </div>
           </div>
        )
      }
          
      
    </>
  );
};

export default InvoiceView;
