import React, { useState,useRef } from 'react'
import axios from 'axios';
import { pages, serverUrl } from '../ApiUrl';
import { deleteAndDropCategory, grevianceCategrory } from '../Data';
import '../Function/Deleteanddrop.css'
import { subHistory } from '../Function/SubscriptionHistory';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { removeInstallationData, updateInstallationData } from '../Redux/installationActions';
import Multiselect from 'multiselect-react-dropdown';

export default function AlreadyDroped({e,data,setReload,reloadW,notify,page}) {
    let [deleteAndDropPop,setDeleteAndDropPop] = useState("false")
    let [btntext,setBtnText] = useState("Reson")
    let loginName = useSelector((state) => state.sar.data.user.first_name)
    let [categorys,setCategory] = useState()
    let [pickup,setPickUP] = useState(false)
    let Select = useRef()
    let dispatch=useDispatch()
    const handleSubmit = async(event) => {
      if (data.zone === "" || data.zone === null || data.zone === undefined || data.zone === "empty") {
        notify("Plz Select Subscription zone Bofore Sending")
        return
      }
      event.preventDefault();
      setBtnText("Processing....")
      const datas = new FormData(event.currentTarget);
      let userData = {
          reson: datas.get("reson"),
          category: datas.get("category"),
          pickup:datas.get("pickup"),
          uninstallDate:datas.get("uninstallDate"),
          other:datas.get('other')
        }
        
     deleteAndDrop(userData)
      
    };
    const deleteAndDrop = async(userData)=>{
   
        await axios.post(`${serverUrl.u}/app/tele/setUninstallationDate`,{
          generatedHex:data.generatedHex,
          InstallationDate:userData.pickup==="true"?userData.uninstallDate:new Date()
          
        }).then(function (res) {
          
          if(res.status===200){
            notify(res.data.message)
            setDeleteAndDropPop(false)
            setBtnText("Reson")
            if(page===pages.grivianceDeartment){
                 for (let i = 0; i < Select.current.state.selectedValues.length; i++) {
                   subHistory(`grievance convert to unistallation`, e, `${"Grivance Department"}`,`${Select.current.state.selectedValues[i].value}`,userData.reson,userData.pickup==="true"?userData.uninstallDate:new Date())
                 }
                 setReload(!reloadW)
           }
            else{
              setReload(!reloadW)
            }
          
          }else{
            notify(res.data.message)
            setBtnText("Try Again")
          }
        }).catch(function (er) {
          console.log(er);
          notify(er.message)
          setBtnText("Try Again")
        })
      }
    
  return (
    <>
   
          <button className="btn btn-primary width" 
                  style={{backgroundColor: "#dc3545",
                          borderColor: "#dc3545"}}  
           onClick={()=>setDeleteAndDropPop(true)}>
                Drop
          </button>
      {
        deleteAndDropPop === true ?
          <div className="reson-container" style={{zIndex:12}}>
            <div className="reson-cross" onClick={() => setDeleteAndDropPop(!deleteAndDropPop)}>x</div>
            <form onSubmit={handleSubmit} validate={true.toString()}>
            <label>Reson For Drop</label>
            <div className="input-group mb-1 ">
              <input type="text" class="form-control" required id="outlined" label="reson" name='reson' autoComplete="reson" defaultValue={data.comments}  aria-describedby="basic-addon1"/>
            </div>
            <label>Category</label>
            {
              page===pages.grivianceDeartment?
              <div className="input-group">
                  <Multiselect
                    displayValue="value"
                    onKeyPressFn={function noRefCheck() { }}
                    onRemove={function noRefCheck() { }}
                    onSearch={function noRefCheck() { }}
                    onSelect={function noRefCheck() { }}
                    options={grevianceCategrory}
                    ref={Select}

                    style={{
                      width: "100px !important",
                      multiselectContainer: {
                        marginTop: "3px",
                        backgroundColor: "#ffffff"
                      },
                    }}
                  />
                </div>:
                <>
                <div class="input-group mb-3">
                            <select class="custom-select height-select" id="inputGroupSelect01"
                                    name="category"
                                    label="category"
                                    autoComplete="current-category"
                                    required
                                    onChange={(e)=>setCategory(e.target.value)}
                                    >
                                    {deleteAndDropCategory?.map((option) => (
                                    <option value={option.value}>{option.value}</option>
                                    ))}
                            </select>
                        </div>
             {
              categorys==="Other"?
              <>
                <label>Other Category</label>
                <div class="input-group mb-3">
                            <input
                             type="text" 
                             class="form-control" 
                             required
                             name='other'
                             autoComplete='current-other'
                            />
                        </div> 
              </>:null
             }  
                </>
            }
                     
                        
            <label>Uninstall Device</label>
            <div class="input-group mb-3">
                            <select class="custom-select height-select" id="inputGroupSelect01"
                                    name="pickup"
                                    label="pickup"
                                    autoComplete="current-pickup"
                                    onChange={(ele)=>setPickUP(ele.target.value)}
                                    required
                                    >
                                    <option value={"false"}>No</option>
                                    <option value={"true"}>Yes</option>
                            </select>
                        </div> 
            {
              pickup==="true"?
              <>
              <label>Uninstall Date</label>
              <div class="input-group mb-3">
              <input type="datetime-local" class="form-control" required id="outlined" label="uninstallDate" name='uninstallDate' autoComplete="uninstallDate"   aria-describedby="basic-addon1"/>
              </div>
              </>:null
            }                       
                <button className="btn btn-primary" type='submit' style={{pointerEvents:btntext==="Processing...."?"none":""}}>{btntext}</button>
                </form>
           </div> : null
      }
    
    </>
  )
}
