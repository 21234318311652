import React from "react";
import { InvoiceGenerate } from "../Function/InvoieDownloadFun";
import FileSaver from "file-saver";
import JSZip from "jszip";

export default function InvoiceDownloadLink({ e, ele, notify }) {
  const InvoiceGenerated = async () => {
    let res = await InvoiceGenerate(ele, e, notify);
    if (res[0] === "then") {
      if (res[1].status === 200) {
        console.log(e.is_UpSell, "errfd");
        if (!e.is_UpSell) {
          const zip = new JSZip();
          const zipContent = await zip.loadAsync(res[1].data);
          for (let file in zipContent?.files) {
            // Extract the PDF file
            const pdfFile = zipContent?.files[file];
            if (pdfFile) {
              const pdfBlob = await pdfFile.async("blob");
              FileSaver.saveAs(pdfBlob, `${e.name} - invoice.pdf`);
            } else {
              console.error("PDF file not found in the ZIP archive.");
            }
          }
        } else {
          // Create a Blob from the response data (PDF)
          const blob = new Blob([res[1].data], { type: "application/pdf" });

          // Use file-saver to download the file
          FileSaver.saveAs(blob, `${e.name} - invoice.pdf`);
        }
      } else {
        notify("Failed");
      }
    } else {
      notify("Failed");
    }
  };
  return (
    <>
      <button className="btn btn-primary" onClick={() => InvoiceGenerated()}>
        Invoice Download
      </button>
    </>
  );
}
