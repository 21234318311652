import React, { useEffect, useState } from "react";
import "./SearchResult.css";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import SearchOnSingle from "./SearchOnSingle";
import axios from "axios";
import { serverUrl } from "../ApiUrl";
import { notifyFailed } from "../Common/Notification";
const ExpandMore = styled((props) => {
   const { expand, ...other } = props;
   return <IconButton {...other} />;
})(({ theme, expand }) => ({
   transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
   marginLeft: "auto",
   transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
   }),
}));

export default function SearchResult({ e: completeData, loginName, setReload, reload,expand }) {
   const [expanded, setExpanded] = React.useState(expand);

   let [lengths, setLength] = useState({
      paidAppliance: 0,
      partial: 0,
      Overdue:"0.00",
      countActiveSub:0,
      count:0,
      grivienceNumber:0,
   })
   const handleExpandClick = () => {
      setExpanded(!expanded);
   };
 
   useEffect(() => {
      console.log("called");
      let val = 0
      let countActiveSub=0
      let countTotalGrivance=0
      
      for(let sub of completeData){
         if(sub.dropped === false && sub.partialPayment === false){
            val+=Number(sub.totalAppliances)
         }
         if(sub.dropped === false && sub.partialPayment===false){
            console.log(sub,"sub");
            countActiveSub+=1
            countTotalGrivance+=sub.grivienceNumber
         }
      
      }
      console.log(val, "dd",countActiveSub);
       
      const invoiceDetails =  (data) => {
         console.log(data, "gen");
         let Overdue = 0
         let count = 0
         return new Promise((resolve, reject)=>{
            axios.post(`${serverUrl.u}/api/tele/getAllInvoicesDetails`, {
               generatedHex: data,
            })
               .then(function (res) {
                  if (res.data.success === true) {
                 
                     for (let i of res.data.data) {
                        if (i.status === "overdue" && i.masterCancel === false) {
                           Overdue += i.actualTotalAmount
                           count +=1
                        }
                     }
                     resolve({success:true,Overdue:Overdue,count:count})
                  } else {
                     notifyFailed(res.data.message);
                     resolve({success:true})
                  }
               })
               .catch(function (err) {
                  console.log(err);
                  reject({success:false})
               });
         })
       
      };
      const apiPromises = [];
      for (let i of completeData) {
         if(i.dropped===false){
         apiPromises.push(invoiceDetails(i.generatedHex))
         }
      }
      Promise.all(apiPromises)
  .then(results => {
    // At this point, all API calls have resolved, and results will be an array of API responses
    // You can process the results or do further actions here.
    console.log('All API calls resolved:', results);
    let nw =0
    let nc = 0
    for(let i of results){
      console.log(i,"overdue");
       if(i.Overdue!==undefined){
         nw+=i.Overdue
         nc+=i.count
       }
    }
    console.log(nw,"nww");
     setLength({...lengths,Overdue:nw.toFixed(2),count:nc,paidAppliance:  val,countActiveSub:countActiveSub,grivienceNumber:countTotalGrivance})
    // Continue with the rest of your code here...
  })
  .catch(error => {
    // Handle any errors that occurred during the API calls
    console.error('Error occurred:', error);
  });

   }, [])
   useEffect(()=>{
      
   },[lengths])
  let len = completeData[0].AssignedElectritianList.length
   return (
      <>
         <Card sx={{ boxShadow: "none",borderRadius: "13px",border: "1px solid #C6C6C6", margin: "20px 0", width: "100%", padding: "30px", position: "relative" }}>
            <div style={{ display: "flex", justifyContent: "space-between", width: "95%",marginLeft:"2%" }} onClick={()=>setExpanded(true)}>
               <div className="mainContainer">
                  <div className="mainHeadingSub">{completeData[0].name}</div>
                  <div className="mainSubHeadingSub">{completeData[0].city}</div>
               </div>
               <div className="mainContainer">
                  <div className="mainSubHeadingSub">Total Paid Active Sub</div>
                  <div className="mainHeadingSub">{lengths.countActiveSub}</div>
               </div>
               <div className="mainContainer">
                  <div className="mainSubHeadingSub">Total Paid Appliances</div>
                  <div className="mainHeadingSub">{lengths.paidAppliance}</div>
               </div>
               <div className="mainContainer">
                  <div className="mainSubHeadingSub">TeleCaller</div>
                  <div className="mainHeadingSub">{completeData[0].teleCallerId}</div>
               </div>
               <div className="mainContainer">
                  <div className="mainSubHeadingSub">Second TeleCaller</div>
                  <div className="mainHeadingSub">{completeData[0].secondarySalesPerson}</div>
               </div>
            </div>

            <CardActions disableSpacing sx={{ height: "25px", padding: "0 !important", position: "absolute", top: '18px', right: "10px" }}>
               <ExpandMore
                  sx={{ height: "25px", padding: "0 !important" }}
                  className="expandmore"
                  expand={expanded}
                  onClick={handleExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
               >
                  <ExpandMoreIcon />
                  
               </ExpandMore>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
               <CardContent sx={{ padding: "0px !important" }}>
                
                  <div>
                     <div style={{ display: "flex", justifyContent: "space-between", width: "95%", marginTop: "30px",marginBottom:"30px",marginLeft:"2%" }}>
                        <div className="mainContainer">
                           <div className="mainSubHeadingSub">Address</div>
                           <div className="mainHeadingSub">
                              <div className="longAddress">{completeData[0].address}</div>
                           </div>
                        </div>
                        <div className="mainContainer">
                           <div className="mainSubHeadingSub">Phone</div>
                           <div className="mainHeadingSub">{completeData[0].phone}</div>
                        </div>
                        <div className="mainContainer">
                           <div className="mainSubHeadingSub">Overdue Amount</div>
                           <div className="mainHeadingSub">{lengths.Overdue!=="0.00"?`₹${lengths.Overdue} - ${lengths.count} Invoices`:"Paid"}</div>
                        </div>
                        <div className="mainContainer">
                           <div className="mainSubHeadingSub">Total Grevances (Paid)</div>
                           <div className="mainHeadingSub">{lengths.grivienceNumber}</div>
                        </div>
                        <div className="mainContainer">
                           <div className="mainSubHeadingSub">Last Electrician Visit</div>
                           <div className="mainHeadingSub">{typeof completeData[0].AssignedElectritianList[len-1]==="object"?(completeData[0].AssignedElectritianList[len-1]).electricianName:completeData[0].AssignedElectritianList[len-1]}</div>
                        </div>
                     </div>
                     {
                        completeData.map(ele=>{
                           return <SearchOnSingle
                                     e={ele}
                                     loginName={loginName}
                                     setReload={setReload}
                                     reload={reload}
                                     completeData={completeData}
                                 />
                        })
                     }
                  </div>
               </CardContent>
            </Collapse>
         </Card>

      </>
   )
}
