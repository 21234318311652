import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import axios from 'axios';
import { serverUrl } from '../ApiUrl';
import '../AssignElectrician.css'
import { updateInstallationData } from '../Redux/installationActions';
import { useDispatch } from 'react-redux';
import Multiselect from 'multiselect-react-dropdown';
import Button from '@mui/material/Button';
import { subHistory } from '../Function/SubscriptionHistory';
import { Stack } from '@mui/material';

export default function UnAssignElectrician({ e, notify }) {
  let userRole = useSelector((state) => state.sar.data?.user?.roles)
  let loginName = useSelector((state) => state.sar.data.user.first_name)
  let employeeId = useSelector((state) => state.sar.data.user.employeeId)
  let [disable,setDisable] = useState(false)

  let [displayDropDown, setDisplayDropDown] = useState(false)
  let [confirmationDis, setConfirmationDis] = useState(false)
  let [selectedValue, setSelectedValue] = useState([])
  let dispatch = useDispatch()
  const assign = async () => {
    setDisable(true)
    let electrician = []
    for (let i of selectedValue) {
      electrician.push(i.electricianId)
    }
    
    await axios.post(`${serverUrl.u}/app/elec/unAssignElectricianOnSite`, {
      generatedHex: e.generatedHex,
      unAssignElectrician: electrician
    }).then(function (response) {
      let detail = response;
    
      if (response.data.success === true) {
        setDisplayDropDown(false)
        notify(response.data.message)
        dispatch(updateInstallationData(response.data.sub))
        subHistory("Electrician Un-Assign From Installation Page", e, "Electrician Un-Assign", JSON.stringify(electrician))
        setDisable(false)
      } else {
        notify("Error Occured")
        setDisable(false)
      }

    }).catch(function (error) {
      console.log(error);
      notify(error?.response?.data?.message)
      setDisable(false)
    })
    setDisable(false)
  }

  return (
    <>
      {confirmationDis === true ?
        <div className='confirmation-popup'>
          <div className='confirmation-popup-heading'>Confirmation to change {e.name}</div>
          <div className='confirmation-popup-Subheading'>Are your sure you want to Ready</div>
          <button className='btn btn-danger wid' onClick={() => { setConfirmationDis(false) }}>No</button>
          <button className='btn btn-primary wid ml-5' onClick={() => {

            setConfirmationDis(false)
            setDisplayDropDown(true)
          }}>Yes</button>
        </div> : null
      }

      {
        displayDropDown === false ? <td onClick={() => { if (employeeId==="1024" || employeeId==="1058" ||(!userRole.includes("Telecaller") && !userRole.includes("Sales"))) setConfirmationDis(true) }} label="Assigned Electritian *">
          {Array.isArray(e.AssignedElectritian) ?

            <ul>
              {
                e.AssignedElectritian && e.AssignedElectritian.map((name) => {
                
                  return (
                    <li>{(typeof name === 'object' && name !== null) ? name.electricianName : name}</li>
                  )
                })
              }
            </ul> : e.AssignedElectritian}</td> :
          <div class="input-group mb-3" >
            {/* <select class="custom-select" id="inputGroupSelect01"
                        onChange={(ele) => handleElecteicain(ele, e.generatedHex)}
                        defaultValue="empty"
                    >
                        <option value="empty">choose Electrion</option>
                        {userName?.map((option) => (
                            <option value={option.first_name}>{option.first_name}</option>
                        ))}
                    </select> */}
            <Multiselect
              displayValue="electricianName"
              onRemove={setSelectedValue}
              onSelect={setSelectedValue}
              options={e.AssignedElectritian}
              style={{
                width: "100px !important",
                multiselectContainer: {
                  marginTop: "5px",
                  backgroundColor: "#ffffff"
                },
              }}
            />
            {/* <select 
                  onChange={(val)=>{
                    if(val.target.value!=="default"){
                      assign(val.target.value)
                    }
                  }}
                  className='form-control'
                  style={{width:'120px'}}
                >
                <option value={"default"}>Select Electrician</option>
                {
                  e.AssignedElectritian.map((ee)=>{
                    return(
                      <option value={ee.electricianId}>{ee.electricianName}</option>
                    )
                  })
                }

                </select> */}
         
            <Stack direction="row" gap={2}>
                <Button onClick={()=>assign()} sx={{mt:2}} variant="contained">Done</Button>
                <Button onClick={()=>setDisplayDropDown(false)} sx={{mt:2}} variant="contained">Close</Button>
                </Stack>
          </div>
      }

    </>
  )
}
