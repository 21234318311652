import React, { useEffect } from "react";
import Popover from '@mui/material/Popover';
import { Formik, Field, Form, FieldArray } from "formik";
import {
  Grid,
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
  Typography,
  Box,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { planDuration } from "../../Data";
import { editCreditNote, editSequrityReceipt } from "../Api";
import { subHistory } from "../../Function/SubscriptionHistory";
import { useSelector } from "react-redux";

function EditSequrityReceipt({ data, date, refresh }) {
  let loginName = useSelector((state) => state.sar.data.user.first_name)

  const initialValues = {
    ...data,
    trnxDetails: data.trnxDetails || [],
    trnxRZP: data.trnxRZP || [],
    trnxRefund: data.trnxRefund || [],
    trnxCreditNotes: data.trnxCreditNotes || [],
  };
  const processedInitialValues = {
    ...data,
    trnxDetails: data.trnxDetails?.map(detail => ({
      ...detail,
      date: moment(detail.date).format("YYYY-MM-DD"),
    })) || [],
    trnxRZP: data.trnxRZP?.map(rzp => ({
      ...rzp,
      date: moment(rzp.date).format("YYYY-MM-DD"),
    })) || [],
    trnxRefund: data.trnxRefund?.map(refund => ({
      ...refund,
      date: moment(refund.date).format("YYYY-MM-DD"),
    })) || [],
    trnxCreditNotes: data.trnxCreditNotes?.map(creditNote => ({
      ...creditNote,
      date: moment(creditNote.date).format("YYYY-MM-DD"),
    })) || [],
  };
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const handleClick = (event) => {
    console.log(data);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleSubmit = async (values) => {
    setLoading(true);
    await editSequrityReceipt({ ...values, receiptId: data._id }).then(
      async (res) => {
        subHistory("Sequrity Receipt Edited", {...data,mewData:{...values,receiptId:data._id}}, `Sequrity Receipt Edited by ${loginName}`, "Sequrity Receipt Edited")

        setLoading(false);
        setAnchorEl(null);
        await refresh(date);
      }
    );
    console.log(values);
  };
  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Edit
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ width: "85%" }}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: "20px" }}>
          <Typography variant="h5" mb={2} mt={2}>
            Edit Sequrity Receipt
          </Typography>
          <Formik initialValues={processedInitialValues} onSubmit={handleSubmit}  enableReinitialize={true}>
            {({ values }) => {
         

              return (
                <Form>
                  <Grid container spacing={2}>
                    {/* Basic Information */}
                    <Grid item xs={12} sm={6}>
                      <Field name="name">
                        {({ field }) => (
                          <TextField fullWidth label="Name" {...field} />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="amount">
                        {({ field }) => (
                          <TextField
                            fullWidth
                            label="Amount"
                            type="number"
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="actualTotalAmount">
                        {({ field }) => (
                          <TextField
                            fullWidth
                            label="Actual Total Amount"
                            type="number"
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="comments">
                        {({ field }) => (
                          <TextField fullWidth label="Comments" {...field} />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                      <Field name="masterCancel">
                        {({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox checked={field.value} {...field} />
                            }
                            label="Master Cancel"
                          />
                        )}
                      </Field>
                    </Grid>

                    {/* Transaction Details Section */}
                    <Grid item xs={12}>
                      <Typography variant="h6">Transaction Details</Typography>
                      <FieldArray name="trnxDetails">
                        {({ push, remove }) => (
                          <>
                            {values.trnxDetails.map((_, index) => (
                              <Grid container spacing={2} key={index}>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`trnxDetails.${index}.payment_mode`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Payment Mode"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`trnxDetails.${index}.gateway_transaction_id`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Gateway Transaction ID"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field name={`trnxDetails.${index}.amount`}>
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Amount"
                                        type="number"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`trnxDetails.${index}.invoiceId`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Invoice ID"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`trnxDetails.${index}.zohoInvoiceNumber`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Zoho Invoice Number"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field name={`trnxDetails.${index}.date`}>
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Date"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            ))}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                push({
                                  payment_mode: "",
                                  gateway_transaction_id: "",
                                  amount: "",
                                  invoiceId: "",
                                  zohoInvoiceNumber: "",
                                  date: "",
                                })
                              }
                            >
                              Add Transaction Detail
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Grid>

                    {/* RazorPay Transactions Section */}
                    <Grid item xs={12}>
                      <Typography variant="h6" mb={1}>
                        RazorPay Transactions
                      </Typography>
                      <FieldArray name="trnxRZP">
                        {({ push, remove }) => (
                          <>
                            {values.trnxRZP.map((_, index) => (
                              <Grid container spacing={2} key={index}>
                                <Grid item xs={12} sm={4}>
                                  <Field name={`trnxRZP.${index}.payment_mode`}>
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Payment Mode"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`trnxRZP.${index}.gateway_transaction_id`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Gateway Transaction ID"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field name={`trnxRZP.${index}.amount`}>
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Amount"
                                        type="number"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field name={`trnxRZP.${index}.date`}>
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Date"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            ))}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                push({
                                  payment_mode: "",
                                  gateway_transaction_id: "",
                                  amount: "",
                                  date: "",
                                })
                              }
                            >
                              Add RazorPay Transaction
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Grid>

                    {/* Refund Transactions Section */}
                    <Grid item xs={12}>
                      <Typography variant="h6" mb={1}>
                        Refund Transactions
                      </Typography>
                      <FieldArray name="trnxRefund">
                        {({ push, remove }) => (
                          <>
                            {values.trnxRefund.map((_, index) => (
                              <Grid container spacing={2} key={index}>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`trnxRefund.${index}.payment_mode`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Payment Mode"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`trnxRefund.${index}.gateway_transaction_id`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Gateway Transaction ID"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field name={`trnxRefund.${index}.amount`}>
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Amount"
                                        type="number"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field name={`trnxRefund.${index}.date`}>
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Date"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            ))}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                push({
                                  payment_mode: "",
                                  gateway_transaction_id: "",
                                  amount: "",
                                  date: "",
                                })
                              }
                            >
                              Add Refund Transaction
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Grid>

                    {/* Credit Notes Section */}
                    <Grid item xs={12}>
                      <Typography variant="h6" mb={1}>
                        Credit Notes
                      </Typography>
                      <FieldArray name="trnxCreditNotes">
                        {({ push, remove }) => (
                          <>
                            {values.trnxCreditNotes.map((_, index) => (
                              <Grid container spacing={2} key={index}>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`trnxCreditNotes.${index}.payment_mode`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Payment Mode"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`trnxCreditNotes.${index}.gateway_transaction_id`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Credit Note Number"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`trnxCreditNotes.${index}.amount`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Amount"
                                        type="number"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field name={`trnxCreditNotes.${index}.date`}>
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Date"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            ))}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                push({
                                  payment_mode: "",
                                  gateway_transaction_id: "",
                                  amount: "",
                                  date: "",
                                })
                              }
                            >
                              Add Credit Note
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Grid>

                    <Grid item xs={12}>
                      <Button
                        variant="contained"
                        disabled={loading}
                        color="primary"
                        type="submit"
                      >
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Popover>
    </>
  );
}

export default EditSequrityReceipt;
