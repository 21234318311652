import axios from 'axios'
import React, { useState } from 'react'
import { serverUrl } from '../ApiUrl'
import { Button } from '@mui/material'
import moment from 'moment'

export default function GrivanceHistory({data,notify}) {
    let [datas,setDatas] = useState([])
    let [pop,setPop] = useState(false)
    const greivanceHistory = ()=>{
        axios.post(`${serverUrl.u}/api/grv/getGrievanceHistoryOfHex`,{
            generatedHex:data.generatedHex
        }).then(function (res) {
            console.log(res);
            if(res.data.success===true){
                setDatas(res.data.data)
                if(res.data.data.length===0){
                  notify("No History Found")
                }else{
                setPop(true)
                }
            }else{
                notify("Failed")
            }
        }).catch(function (error) {
            console.log(error);
            notify("Failed")
        })
    }
  return (
    <>
       <button className='btn btn-primary'  onClick={()=>greivanceHistory()}>Grievance History</button>

       {
        (pop===true)?
        <div style={{width:"100vw",height:"100vh",position:"fixed",top:0,left:0,overflow:"scroll",display:"flex",alignItems:"center",justifyContent:"center",zIndex:4}}>
           {
            datas.length!==0?
            <div style={{width:"80%",backgroundColor:"whitesmoke",padding:"2%",borderRadius:"12px"}}>
              <div style={{textAlign:"right"}}>
                <span style={{cursor:"pointer",fontSize:"18px"}} onClick={()=>{
                  setPop(false)
                  setDatas([])
                  }}>X</span>
              </div>
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Phone</th>
                    <th>Raised By</th>
                    <th>Register Date</th>
                    <th>Comment</th>
                    <th>Grievance Department Comment</th>
                    <th>Issues</th>
                    <th>Days Take To Reslove</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    datas.map((ele,i)=>{
                      return(
                        <tr>
                          <td>{ele.name}</td>
                          <td>{ele.phone}</td>
                          <td>{ele.raisedBy}</td>
                          <td>{moment(ele.grievanceRaiseDate).format('DD-MM-YYYY')}</td>
                          <td>{ele.raisedByComment}</td>
                          <td>{ele.grivienceDepartmentComment}</td>
                          <td>
                            <ul>
                            {
                              ele.issue.map((e)=>{
                                return(
                                  <li>{e}</li>
                                )
                              })
                            }
                            </ul>
                          </td>
                          <td>{ele.resolvedWithindays}</td>
                        </tr>
                      )
                    })
                  }
                </tbody>
            </table>
            </div>:null

           }
        </div>:null
       }
    </>
  )
}
