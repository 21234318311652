import axios from 'axios'
import React from 'react'
import { serverUrl } from '../ApiUrl'

export default function UpdateSingleInvoice({data,reload,setReload,notify}) {
    
    const invoiceStatus = () =>{
        axios.post(`${serverUrl.u}/api/zoho/updateInvoiceStatus`,{
            zohoInvoiceNumber:data.zohoInvoiceNumber
        }).then(function (res) {
            console.log(res);

        }).catch(function (err) {
            console.log(err);
        })
    }
  return (
    <>
      <button className='btn btn-success' onClick={()=>invoiceStatus()}>Update Payment Status</button>
    </>
  )
}
