import React, { useRef, useState } from "react";
import { apiRequest } from "../../utils";
import { serverUrl } from "../ApiUrl";
import { notify, notifyFailed } from "../Common/Notification";
import { Box, Button, Paper, Stack } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import moment from "moment";

export default function DroppedClient() {
    const [loading, setLoading] = useState(false);
    const [droppedClients, setDroppedClients] = useState([]);

    let firstDate = useRef(new Date());
    let lastDate = useRef(new Date());

    const fetchDroppedClients = async () => {
        const response = await apiRequest({
            url: `${serverUrl.u}/client/drop`,
            data: {
                firstDate: firstDate.current.value,
                lastDate: lastDate.current.value,
            },
            method: "POST",
        });

        console.log(response, " DROPPED RESPONSE");

        if (!response.success) {
            notifyFailed(response.message);
            return;
        }

        setDroppedClients(response.data?.data?.subscription);
        notify(response.message);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log("SUBMIT pressed");

        fetchDroppedClients();
    };
    const columns = [
        {
            field: "name",
            headerName: "Name",
            width: 250,
        },
        {
            field: "phone",
            headerName: "PhoneNumber",
        },
        {
            field: "email",
            headerName: "Email",
        },
        {
            field: "dropped",
            headerName: "Dropped",
        },
        {
            field: "dropDate",
            headerName: "Dropped Date",
            width: 200,
        },
        {
            field: "reasonForDrop",
            headerName: "Reason",
            width: 300,
        },
        {
            field: "comments",
            headerName: "Comments",
            width: 300,
        },

        {
            field: "generatedHex",
            headerName: "Hex",
        },
        {
            field: "teleCallerId",
            headerName: "Telecaller",
        },

        {
            field: "secondarySalesPerson",
            headerName: "Second Sales Person",
        },
        {
            field: "invoiceStatus",
            headerName: "Invoice Status",
        },

        {
            field: "overdueAmount",
            headerName: "OverDue",
        },
    ];

    return (
        <div style={{ padding: "0 20px" }}>
            <h2 className="mt-4">Dropped Clients</h2>
            <Paper elevation={1} sx={{ p: 4, marginBottom: "20px" }}>
                <form onSubmit={handleSubmit}>
                    <Stack
                        direction={"row"}
                        spacing={2}
                        sx={{ alignItems: "flex-end" }}
                    >
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <label>First Date:</label>
                            <input type="date" ref={firstDate} required />
                        </div>
                        <div
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <label>Last Date:</label>
                            <input type="date" ref={lastDate} required />
                        </div>

                        <Button variant="contained" size="small" type="submit">
                            Fetch Clients
                        </Button>
                    </Stack>
                </form>
            </Paper>

            <div>
                {droppedClients.length > 0 && (
                    <DataGrid
                        columns={columns}
                        rows={droppedClients.map((dd, index) => ({
                            ...dd,
                            dropDate: moment(new Date(dd.dropDate)).format(
                                "DD/MM/YYYY"
                            ),
                            id: index,
                        }))}
                        initialState={{
                            pagination: {
                                paginationModel: {
                                    pageSize: 10,
                                },
                            },
                        }}
                        slots={{
                            toolbar: GridToolbar,
                        }}
                        slotProps={{
                            toolbar: {
                                csvOptions: {
                                    fileName: `DroppedClients-${moment(
                                        firstDate.current.value
                                    ).format("DD-MM-YYYY")}-${moment(
                                        lastDate.current.value
                                    ).format("DD-MM-YYYY")}`,
                                },
                            },
                        }}
                        pageSizeOptions={[10, 20, 30, 40]}
                    />
                )}
            </div>
        </div>
    );
}
