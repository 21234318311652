import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "../ConvertedLead.css";
import "../DispatchData.css";
import "../PaymentLinkGen.css";
import Qutoes from "../Qutoes";
import { serverUrl } from "../ApiUrl";
import { CSVLink } from "react-csv";
import "./Marketing.css";

import { notifyFailed } from "../Common/Notification";

import { Tooltip } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";

export default function MarkingAddStatus() {
  let [details, setDetails] = useState([]);
  let [displayData, setDisplayData] = useState({});
  let [csvs, setCsvs] = useState([]);
  let [cityCsv, setCityCsv] = useState([]);
  let [leadsQualityCsv, setLeadsQualityCsv] = useState([]);
  let [btn, setBtn] = useState("Get Data");
  let [q, setq] = useState("");
  let [leadsIntervalData, setLeadsIntervalData] = useState([]);

  const [interval, setInterval] = useState(10);
  const [firstContactStartDate, setFirstContactStartDate] = useState(
    new Date()
  );
  const [firstContactEndDate, setFirstContactEndDate] = useState(new Date());

  let startDate = useRef(new Date());
  let endDate = useRef(new Date());
  let [headers, setHeaders] = useState([]);
  let [sourceHeaders, setSourceHeaders] = useState([]);

  let [total, setTotal] = useState([]);
  const [totalCity, setTotalCity] = useState([]);
  let [leadsQualityState, setLeadsQualityState] = useState({});
  let [leadsCurrentStatusData, setLeadsCurrentStatusData] = useState([]);
  let [leadsCityData, setLeadsCityData] = useState([]);
  let [leadsQualityData, setLeadsQualityData] = useState([]);
  let [otherStatus, setOtherStatus] = useState([]);
  let [maxStatusLength, setMaxStatusLength] = useState(0);
  const [createdTimeLeadData, setCreatedTimeLeadData] = useState([]);
  const [createdTimeWithFirstContactData, setCreatedTimeWithFirstContactData] = useState([]);

  const [adnameData, setadnameData] = useState({});
  const [adnames, setAdnames] = useState([]);

  const [adnameDataGrid, setAdnameDataGrid] = useState([]);

  const leadsTypes = {
    good: [
      "Converted",
      "Site Visit Booked",
      "Link Send",
      "Quote Sent Intendful",
      "Address Layout FollowUp",
      "Site Visit Done Follow",
      "Installation Done Follow up Due",
      "Free Installation Done Follow Up Due",
      "Installation Done Follow Up Done",
      "Free Installation Done Follow Up Done",
      "Overdue Client",
      "Paid Now Was Overdue",
      "site visit done followup",
      "Address Layout FollowUp",
      "drop",
      "Installation Done",
    ],
    // converted: [],
    average: [
      "Details Send",
      "Quote Send",
      "Quote Sent Not Intendful",
      "Details Send",
      "Educated Call Back Later",
      "Educated but Not Interested",
      "Educated but Not Intrested",
    ],
    notConnected: [
      "Call Back Later",
      "Call not connected",
      "Client will call back",
      "Repeatedly Call Not Connected",
    ],
    bad: [
      "Area Unserviceable",
      "Area Unservisable",
      "Free Promo Not Interested",
      "Language Issue",
      "Not Inquired",
      "Not Interested",
      "Spam Lead",
      "Wrong Number",
      "Un-educated but Not Interested",
      "Un-educated but Not Intrested",
      "Not Intrested",
    ],
    newLead: ["New Lead", "WhatsApp New Lead"],
    underConstruction: ["Under Construction"],
  };

  const getData = async () => {
    setBtn("processing...");
    setCsvs([]);
    await axios
      .post(`${serverUrl.amazon}/marketing/adsetSummary`, {
        startDate: new Date(
          new Date(startDate.current.value).setHours(0, 0, 0, 0)
        ).toString(),
        endDate: new Date(
          new Date(endDate.current.value).setHours(0, 0, 0, 0)
        ).toString(),
      })
      .then(function (res) {
        let Sources = [];
        let Status = [];

        let adnames = [];
        let adnameWiseData = {};

        let status = [];
        let city = [];
        let SourceWiseData = {};

        let leadQuality = {};
        let leadsByCreatedTime = {
          0: [],
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
          6: [],
          7: [],
        };

        if (res.data.success) {
          setTotal(res.data.data);
          const respTotalData = res.data.data;
          for (let lead of res.data.data) {
            if (!Sources.includes(lead.source)) {
              Sources.push(lead.source);
            }
            if (!Status.includes(lead.status)) {
              Status.push(lead.status);
            }
            if (!city.includes(lead.city)) {
              city.push(lead.city);
            }

            if (!adnames.includes(lead.adname)) {
              adnames.push(lead.adname);
            }

            // Created TIme
            const createdTime = Math.floor(
              new Date(lead.createdTime).getHours() / 3
            );
            if (!leadsByCreatedTime[createdTime]) {
              leadsByCreatedTime[createdTime] = [];
            }

            leadsByCreatedTime[createdTime].push(lead);
          }

          //Sort headers
          Status = Status.sort((a, b) => (a > b ? 1 : -1));

          // Site Visit Booked
          if (Status.includes("Site Visit Booked")) {
            Status = ["Site Visit Booked", ...Status];
            Status.splice(
              Status.lastIndexOf((s) => s === "Site Visit Booked"),
              1
            );
          }

          // Remove Site Visit Booked
          Status.splice(
            Status.findIndex((s) => s === "Site Visit Booked"),
            1
          );
          // Converted`
          if (Status.includes("Converted")) {
            Status = Status.filter((s) => {
              if (s === "Converted") return false;
              return true;
            });
            Status = ["Converted", ...Status];
            // Status.splice(Status.lastIndexOf((s) => s === "Converted"), 1);
          }

          // Installation Done
          if (Status.includes("Installation Done Follow Up Done")) {
            Status = ["Installation Done Follow Up Done", ...Status];
            Status.splice(
              Status.lastIndexOf(
                (s) => s === "Installation Done Follow Up Done"
              ),
              1
            );
          }

          if (Status.includes("Installation Done Follow up Due")) {
            Status = ["Installation Done Follow up Due", ...Status];
            Status.splice(
              Status.lastIndexOf(
                (s) => s === "Installation Done Follow up Due"
              ),
              1
            );
          }

          //  Remove BB Data & BB BLR

          // delete Sources[Sources.indexOf((s) => s === 'BB BLR')]
          // delete Sources[Sources.indexOf((s) => s === "BB Data")];

          Sources = Sources.filter((source) => {
            if (
              source === "BB Data" ||
              source === "BB BLR" ||
              source === "Lock Leads"
            ) {
              return false;
            }

            return true;
          });
          setTotalCity(city);
          setHeaders(Status);
          setSourceHeaders(Sources);
          setAdnames(adnames);

          for (let source of Sources) {
            if (SourceWiseData[source] === undefined) {
              SourceWiseData[source] = [];
              if (leadQuality[source] === undefined) {
                leadQuality[source] = {
                  good: [],
                  // converted: [],
                  average: [],
                  bad: [],
                  newLead: [],
                  notConnected: [],
                  other: [],
                  underConstruction: [],
                };
              }

              for (let lead of res.data.data) {
                if (lead.source === source) {
                  SourceWiseData[source].push(lead);
                  if (leadsTypes.good.includes(lead.status)) {
                    leadQuality[source].good.push(lead);
                  } else if (leadsTypes.average.includes(lead.status)) {
                    leadQuality[source].average.push(lead);
                  } else if (leadsTypes.bad.includes(lead.status)) {
                    leadQuality[source].bad.push(lead);
                  } else if (
                    leadsTypes.underConstruction.includes(lead.status)
                  ) {
                    leadQuality[source].underConstruction.push(lead);
                  } else if (leadsTypes.newLead.includes(lead.status)) {
                    leadQuality[source].newLead.push(lead);
                  } else if (leadsTypes.notConnected.includes(lead.status)) {
                    leadQuality[source].notConnected.push(lead);
                  } else {
                    leadQuality[source].other.push(lead);
                  }
                }
              }
            }
          }

          for (const ad of adnames) {
            if (adnameWiseData[ad] === undefined) {
              adnameWiseData[ad] = [];
            }

            for (const lead of res.data.data) {
              if (lead.adname === ad) {
                adnameWiseData[ad].push(lead);
              }
            }
          }

          let adnameTableData = [];

          const calculateAdnameTableData = () => {
            let id = 1;
            // Loop over adnames
            for (const adname of adnames) {
              // Loop over status
              const statusObject = {};

              for (const status of Status) {
                statusObject[status] = calculate(
                  adnameWiseData[adname],
                  status
                );
                // adname.push({adname, status: calculate(adnameWiseData[adname], status)})
              }
              adnameTableData.push({
                id,
                adname,
                total: adnameWiseData[adname].length,
                ...statusObject,
              });
              id += 1;
            }

            setAdnameDataGrid(adnameTableData);
          };

          const leadSourceCurrentStatus = () => {
            let leadsSourceCurrentStatusData = [];
            let sno = 1;

            for (const key in SourceWiseData) {
              const allStatusSData = {};
              for (const status of Status) {
                allStatusSData[status] = calculate(SourceWiseData[key], status);
              }

              leadsSourceCurrentStatusData.push({
                id: sno,
                source: key,
                total: SourceWiseData[key].length,
                ...allStatusSData,
              });

              sno++;
            }

            // Add total
            const statusTotalData = {};

            for (const status of Status) {
              const tt = respTotalData.reduce(
                (a, c) => {
                  if (c.status === status) return Number(a) + 1;
                  else return a;
                },
                [0]
              );
              statusTotalData[status] = tt;
            }

            leadsSourceCurrentStatusData.push({
              id: sno,
              source: "Total",
              total: calculateTotal(leadQuality),
              ...statusTotalData,
            });

            setLeadsCurrentStatusData(leadsSourceCurrentStatusData);
          };

          const calculateLeadCityData = () => {
            let sno = 1;
            let leadsCity = [];
            for (const key in SourceWiseData) {
              const cityData = {};
              for (const c of city) {
                cityData[c] = calculateCity(SourceWiseData[key], c);
              }
              leadsCity.push({
                id: sno,
                source: key,
                total: SourceWiseData[key]?.length
                  ? SourceWiseData[key]?.length
                  : 0,
                ...cityData,
              });

              sno++;
            }

            // Add total
            const totalCityData = {};
            for (const c of city) {
              const tt = respTotalData.reduce(
                (a, current) => {
                  if (current.city === c) {
                    return Number(a) + 1;
                  } else {
                    return a;
                  }
                },
                [0]
              );

              totalCityData[c] = tt;
            }

            leadsCity.push({
              id: sno,
              source: "Total",
              total: calculateTotal(leadQuality),
              ...totalCityData,
            });

            setLeadsCityData(leadsCity);
          };

          function addToArray(arr, str) {
            const set = new Set(arr);

            if (!set.has(str)) {
              arr.push(str);

              set.add(str);
            }
          }
          const calculateLeadsQualityData = () => {
            let id = 1;
            let lq = [];
            const otherStatuses = [];
            for (const s in SourceWiseData) {
              lq.push({
                id,
                source: s,
                Good: `${
                  leadQuality[s].good?.length
                } (${calculatePerLeadQuality(s, "good", leadQuality)}%)`,
                Average: `${
                  leadQuality[s].average?.length
                } (${calculatePerLeadQuality(s, "average", leadQuality)}%)`,
                Bad: `${leadQuality[s].bad?.length} (${calculatePerLeadQuality(
                  s,
                  "bad",
                  leadQuality
                )}%)`,
                "New Lead": `${
                  leadQuality[s].newLead?.length
                } (${calculatePerLeadQuality(s, "newLead", leadQuality)}%)`,
                "Not Connected": `${
                  leadQuality[s].notConnected?.length
                } (${calculatePerLeadQuality(
                  s,
                  "notConnected",
                  leadQuality
                )}%)`,
                "Under Construction": `${
                  leadQuality[s].underConstruction?.length
                } (${calculatePerLeadQuality(
                  s,
                  "underConstruction",
                  leadQuality
                )}%)`,
                Others: `${
                  leadQuality[s].other?.length
                } (${calculatePerLeadQuality(s, "other", leadQuality)}%)`,
                Total:
                  leadQuality[s].good?.length +
                  leadQuality[s].bad?.length +
                  leadQuality[s].average?.length +
                  leadQuality[s].notConnected?.length +
                  leadQuality[s].newLead?.length +
                  leadQuality[s].underConstruction?.length +
                  leadQuality[s].other?.length,
              });

              // set other statuses

              for (const lead of leadQuality[s].other) {
                addToArray(otherStatuses, lead.status);
              }

              id++;
            }

            // Calculate the max
            const maxStatusLength = Math.max(
              leadsTypes["good"]?.length,
              leadsTypes["average"]?.length,
              leadsTypes["bad"]?.length,
              leadsTypes["newLead"]?.length,
              leadsTypes["underConstruction"]?.length,
              otherStatuses?.length
            );

            setMaxStatusLength(maxStatusLength);
            setOtherStatus(otherStatuses);
            lq.push({
              id,
              source: "Total",
              Good: calculateTotalLeadQuality(
                "good",
                leadQuality,
                respTotalData.length
              ),
              Average: calculateTotalLeadQuality(
                "average",
                leadQuality,
                respTotalData.length
              ),
              Bad: calculateTotalLeadQuality(
                "bad",
                leadQuality,
                respTotalData.length
              ),
              "Not Connected": calculateTotalLeadQuality(
                "notConnected",
                leadQuality,
                respTotalData.length
              ),
              "New Lead": calculateTotalLeadQuality(
                "newLead",
                leadQuality,
                respTotalData.length
              ),
              "Under Construction": calculateTotalLeadQuality(
                "underConstruction",
                leadQuality,
                respTotalData.length
              ),
              Others: calculateTotalLeadQuality(
                "other",
                leadQuality,
                respTotalData.length
              ),
              Total: calculateTotal(leadQuality),
            });

            setLeadsQualityData(lq);
          };

          // Created Time for leads
          const finalDataCreatedTime = [];
          const data = {};
          const statusTotal = {};

          console.log("LEADS j- ", leadsByCreatedTime);

          for (const interval of Object.keys(leadsByCreatedTime)) {
            // console.log("INTEVAL - ", interval, typeof interval, Number(interval), typeof Number(interval), (Number(interval) +1)*3)
            const intrvl = `${String(interval * 3).padStart(
              2,
              "0"
            )}:00 - ${String((Number(interval) + 1) * 3).padStart(2, "0")}:00`;

            if (!data[interval]) {
              data[interval] = {};
            }

            data[interval]["Interval"] = intrvl;

            data[interval]["total"] = leadsByCreatedTime[interval].length;

            for (const status of Status) {
              const total = leadsByCreatedTime[interval].filter(
                (l) => l.status === status
              ).length;

              data[interval][status] = total;

              statusTotal["Interval"] = "Total";
              if (!statusTotal[status]) {
                statusTotal[status] = 0;
              }

              statusTotal[status] += total;
            }
          }

          // finalDataCreatedTime["Total"] = total

          for (const key of Object.keys(data)) {
            finalDataCreatedTime.push(data[key]);
          }

          finalDataCreatedTime.push(statusTotal);

          finalDataCreatedTime.push(status);
          console.log("FINAL - ", finalDataCreatedTime);
          setCreatedTimeLeadData(finalDataCreatedTime);

          calculateLeadsQualityData();
          leadSourceCurrentStatus();
          calculateLeadCityData();
          calculateAdnameTableData();
          setLeadsQualityState(leadQuality);
          setDisplayData(SourceWiseData);
          setadnameData(adnameWiseData);
        }
      })
      .catch(function (err) {
        alert(err.message);
        console.log(err);
        if (err?.response?.data?.message === "An unexpected error occured") {
          notifyFailed("Enter Short Duration");
        } else {
          notifyFailed(err?.response?.data?.message);
        }
      });
    setBtn("Get Data");
  };

  const getFirstContactedData = async () => {
    // if (new Date(startDate).getTime() >= new Date(endDate).getTime()) {
    //   notifyFailed("Start Time is greater than End Time");
    //   return;
    // }

    await axios
      .post(`${serverUrl.amazon}/leads/analysis/firstContact`, {
        startDate: startDate.current.value,
        endDate: endDate.current.value,
      })
      .then((resp) => {
        if (!resp.data.success) {
          notifyFailed("Failed to get the data");
        }

        const leads = resp.data.data.leads;

        let firstContactLeadsByCreatedTime = {
          0: [],
          1: [],
          2: [],
          3: [],
          4: [],
          5: [],
          6: [],
          7:[],
        };

        let finalIntervals = [];

        let finalData = {};
        let tableData = [];

        let intervalTimeWiseData = {};
        let allStatuses = [];

        let allIntervals = [];

        for (const l of leads) {
          let time = Number(
            calculateTime(Math.abs(l.firstContactTime)).minutes
          );

          let timeIndex = Math.floor(Number(time) / interval);
          if (!intervalTimeWiseData[timeIndex]) {
            intervalTimeWiseData[timeIndex] = [];
            finalIntervals.push(timeIndex);
            
          }

          if (!allIntervals.includes(timeIndex)) {
            allIntervals.push(timeIndex);
          }

          intervalTimeWiseData[timeIndex].push(l);

          // Created Time
          const createdTime = Math.floor(new Date(l.createdAt).getHours() / 3);

          if (!firstContactLeadsByCreatedTime[createdTime]) {
            firstContactLeadsByCreatedTime[createdTime] = [];
          }

          firstContactLeadsByCreatedTime[createdTime].push(l);
        }

        console.log("FINALS INTERVALS - ", finalIntervals, allIntervals)

        for (const timeInterval in intervalTimeWiseData) {
          if (!finalData[timeInterval]) {
            finalData[timeInterval] = {};
          }
          for (const lead of intervalTimeWiseData[timeInterval]) {
            if (!allStatuses.includes(lead.firstContactDetails.status)) {
              allStatuses.push(lead.firstContactDetails.status);
            }

            if (!finalData[timeInterval][lead.firstContactDetails.status]) {
              finalData[timeInterval][lead.firstContactDetails.status] = [];
            }

            finalData[timeInterval][lead.firstContactDetails.status].push(lead);
          }
        }

        const statusData = {};
        let id = 1;

        for (const timeInterval in finalData) {
          for (const status of allStatuses) {
            statusData[status] = finalData[timeInterval][status]
              ? finalData[timeInterval][status].length
              : 0;
          }
          tableData.push({
            id,
            "Time Interval":
              timeInterval === 10
                ? `${timeInterval * 10} - ${(Number(timeInterval) + 1) * 10}`
                : `${Number(timeInterval) * interval} - ${
                    (Number(timeInterval) + 1) * interval
                  }`,
            Total: intervalTimeWiseData[timeInterval].length,
            ...statusData,
          });
          id++;
        }
        // if (!statusWiseData[l.firstContactDetails.status]) {
        //   statusWiseData[l.firstContactDetails.status] = [];
        // }

        // statusWiseData[l.firstContactDetails.status].push(l);

        // ///////////////

        const data = {};
        const intervalTotal = {};
        const finalIntervalData = [];

        for (const intvl of Object.keys(firstContactLeadsByCreatedTime)) {
          const intrvl = `${String(intvl * 3).padStart(
            2,
            "0"
          )}:00 - ${String((Number(intvl) + 1) * 3).padStart(2, "0")}:00`;

          if (!data[intvl]) {
            data[intvl] = {};
          }

          data[intvl]["Interval"] = intrvl;
          data[intvl]["total"] = firstContactLeadsByCreatedTime[intvl].length;

          for (const firstContactInterval of Object.keys(
            intervalTimeWiseData
          )) {
            let total =
              firstContactLeadsByCreatedTime[intvl].filter(
                (l) =>{
                  
                  const time = Number(calculateTime(Math.abs(l.firstContactTime)).minutes);
                  const timeIndex = Math.floor(Number(time) / interval);

                  return Number(firstContactInterval) ===
                  timeIndex
                }
              ).length;

              data[intvl][firstContactInterval]  = total;

              intervalTotal["Interval"] = "Total";

              if (!intervalTotal[firstContactInterval]) {
                intervalTotal[firstContactInterval] = 0;
              }

              intervalTotal[firstContactInterval] += total;
          }
        }

        for (const key of Object.keys(data)) {
          finalIntervalData.push(data[key])
        }

        finalIntervalData.push(intervalTotal)

        setCreatedTimeWithFirstContactData(finalIntervalData)
        setLeadsIntervalData(tableData);
      })
      .catch((error) => {
        notifyFailed(error.message);
      });
  };

  const calculateTime = (date) => {
    let milliseconds = date;
    const seconds = Math.floor((milliseconds / 1000) % 60);

    const minutes = Math.floor((milliseconds / 1000 / 60) % 60);

    const hours = Math.floor((milliseconds / 1000 / 60 / 60) % 24);

    const formattedTime = {
      hours: hours.toString().padStart(2, "0"),
      minutes: minutes.toString().padStart(2, "0"),
      seconds: seconds.toString().padStart(2, "0"),
    };

    // return `${hours.toString().padStart(2, "0")}h ${minutes.toString().padStart(2, '0')}m `;

    return formattedTime;
  };

  const generateCsvData = async () => {
    let csvData = [];
    let cityData = [];
    let leadsQualityData = [];

    setCsvs([]);
    setCityCsv([]);
    setLeadsQualityCsv([]);

    if (Object.keys(displayData).length !== 0) {
      Object.keys(displayData).map((sourceName) => {
        let obj = {
          source: sourceName,
          total: displayData[sourceName]?.length,
        };
        let cityObj = {
          source: sourceName,
          total: displayData[sourceName]?.length,
        };

        let leadsQualityObj = {
          source: sourceName,
          good: `${
            leadsQualityState[sourceName].good?.length
          } - (${calculatePerLeadQuality(
            sourceName,
            "good",
            leadsQualityState
          )}%)`,
          Average: `${
            leadsQualityState[sourceName].average?.length
          } - (${calculatePerLeadQuality(
            sourceName,
            "average",
            leadsQualityState
          )}%)`,
          Bad: `${
            leadsQualityState[sourceName].bad?.length
          } - (${calculatePerLeadQuality(
            sourceName,
            "bad",
            leadsQualityState
          )}%)`,
          "Under Construction": `${
            leadsQualityState[sourceName].underConstruction?.length
          } - (${calculatePerLeadQuality(
            sourceName,
            "underConstruction",
            leadsQualityState
          )}%)`,
          Others: `${
            leadsQualityState[sourceName].other?.length
          } - (${calculatePerLeadQuality(
            sourceName,
            "other",
            leadsQualityState
          )}%) `,
        };
        headers.map((head) => {
          let val = calculate(displayData[sourceName], head);
          obj = { ...obj, [head]: val };
        });

        totalCity.map((cityHead) => {
          let val = calculateCity(displayData[sourceName], cityHead);
          cityObj = { ...cityObj, [cityHead]: val };
        });

        csvData.push(obj);
        cityData.push(cityObj);
        leadsQualityData.push(leadsQualityObj);
      });
    }
    setCsvs(csvData);
    setCityCsv(cityData);
    setLeadsQualityCsv(leadsQualityData);
  };

  const handleSarch = async (name) => {
    setq(name);
    await axios
      .post(`${serverUrl.u}/api/tele/getAllTemporarySubscriptionsSearch`, {
        name: name,
      })
      .then(function (response) {
        setDetails(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const calculate = (data, hStatus) => {
    let total = data.reduce(
      (p, c) => {
        if (c.status === hStatus) {
          return (p = Number(p) + 1);
        } else {
          return p;
        }
      },
      [0]
    );
    let per = ((total / data.length) * 100).toFixed(2);
    if (Number(total) !== 0) {
      return `${total} (${per}%)`;
    } else {
      return "";
    }
  };

  const phoneNumberForStatus = (data, hStatus) => {
    return data.filter((d) => d.status === hStatus);
  };
  const calculateCity = (data, hStatus) => {
    let total = data.reduce(
      (p, c) => {
        if (c.city === hStatus) {
          return (p = Number(p) + 1);
        } else {
          return p;
        }
      },
      [0]
    );

    let per = ((total / data.length) * 100).toFixed(2);
    if (Number(total) !== 0) {
      return `${total}`;
    } else {
      return "";
    }
  };

  const calculateTotal = (leadQuality) => {
    const total = sourceHeaders.reduce((prev, source) => {
      if (!leadQuality[source]) return prev + 0;
      return (
        prev +
        leadQuality[source].good.length +
        leadQuality[source].bad.length +
        leadQuality[source].average.length +
        leadQuality[source].newLead.length +
        leadQuality[source].notConnected.length +
        leadQuality[source].underConstruction.length +
        leadQuality[source].other.length
      );
    }, 0);
    return total;
  };

  const calculateTotalLeadQuality = (type, leadsQuality, totalLeads) => {
    const total = sourceHeaders.reduce((p, source) => {
      if (!leadsQuality[source]) return p + 0;
      return p + leadsQuality[source][type].length;
    }, 0);
    return `${total} (${((total / calculateTotal(leadsQuality)) * 100).toFixed(
      2
    )})%`;
  };

  const calculatePerLeadQuality = (source, type, leadsQuality) => {
    let totalLeads =
      leadsQuality[source].good?.length +
      leadsQuality[source].bad?.length +
      leadsQuality[source].newLead?.length +
      leadsQuality[source].average?.length +
      leadsQuality[source].notConnected?.length +
      leadsQuality[source].underConstruction?.length +
      leadsQuality[source].other?.length;

    return ((leadsQuality[source][type].length / totalLeads) * 100).toFixed(2);
  };

  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      <div className="input-field">
        <input
          type="text"
          value={q}
          placeholder="Search here...."
          onChange={(e) => handleSarch(e.target.value)}
        />
      </div>

      <div className="row">
        <div className="col-2">
          <label className="create-label1">Start Date</label>
          <div class="input-group ">
            <input type="date" ref={startDate} />
          </div>
        </div>
        <div className="col-2">
          <label className="create-label1">End Date</label>
          <div class="input-group ">
            <input type="date" ref={endDate} />
          </div>
        </div>

        <div className="col-1">
          <button
            className="btn btn-primary mt-3"
            disabled={btn === "processing..." ? true : false}
            onClick={() => {
              getData();
              getFirstContactedData();
            }}
          >
            {btn}
          </button>
        </div>
        <div className="col-1">
          <button
            className="btn btn-primary mt-3"
            disabled={btn === "processing..." ? true : false}
            onClick={() => {
              generateCsvData();
            }}
          >
            Generate CSV
          </button>
        </div>

        {/* <div className="col-1">
          <button
            className="btn btn-primary mt-3"
            disabled={btn === "processing..." ? true : false}
            onClick={() => {
            }}
          >
            Get Interval Data
          </button>
        </div> */}

        {csvs.length !== 0 ? (
          <div className="col-1">
            <CSVLink
              className="btn btn-primary mt-3"
              data={csvs}
              filename={`Lead_${startDate.current.value}_to_${endDate.current.value}.csv`}
            >
              Download CSV
            </CSVLink>
          </div>
        ) : null}

        {csvs.length !== 0 ? (
          <div className="col-1">
            <CSVLink
              className="btn btn-primary mt-3"
              data={cityCsv}
              filename={`Lead_${startDate.current.value}_to_${endDate.current.value}_city.csv`}
            >
              Download City CSV
            </CSVLink>
          </div>
        ) : null}

        {csvs.length !== 0 ? (
          <div className="col-1">
            <CSVLink
              className="btn btn-primary mt-3 ml-3"
              data={leadsQualityCsv}
              filename={`Lead_${startDate.current.value}_to_${endDate.current.value}_leadsQuality.csv`}
            >
              Download Leads Quality
            </CSVLink>
          </div>
        ) : null}
      </div>

      <div
        style={{
          display: "Flex",
          alignItems: "center",
          margin: "30px 0 10px 0",
          gap: "20px",
        }}
      >
        <div
          style={{
            color: "#0069d9",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Lead Source - Current Status
        </div>

        <button
          className="btn btn-primary"
          disabled={btn === "processing..." ? true : false}
          onClick={() => {
            generateCsvData();
          }}
        >
          Generate CSV
        </button>

        {csvs.length !== 0 ? (
          <CSVLink
            className="btn btn-primary"
            data={csvs}
            filename={`Lead_${startDate.current.value}_to_${endDate.current.value}.csv`}
          >
            Download CSV
          </CSVLink>
        ) : null}
      </div>
      <div className="table-container1 table-fixed">
        <DataGrid
          columns={
            leadsCurrentStatusData.length > 0
              ? Object.keys(leadsCurrentStatusData[0]).map((h) => ({
                  field: h,
                  headerName: h,
                  width: 150,
                }))
              : []
          }
          rows={leadsCurrentStatusData}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              csvOptions: { fileName: `LeadQuality_referrals` },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30]}
        />
      </div>

      <div
        style={{
          display: "Flex",
          alignItems: "center",
          margin: "30px 0 10px 0",
          gap: "20px",
        }}
      >
        <div
          style={{
            color: "#0069d9",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Lead Source - city
        </div>

        <button
          className="btn btn-primary"
          disabled={btn === "processing..." ? true : false}
          onClick={() => {
            generateCsvData();
          }}
        >
          Generate CSV
        </button>

        {csvs.length !== 0 ? (
          <CSVLink
            className="btn btn-primary"
            data={cityCsv}
            filename={`Lead_${startDate.current.value}_to_${endDate.current.value}_city.csv`}
          >
            Download City CSV
          </CSVLink>
        ) : null}
      </div>
      <div className="table-container1 table-fixed">
        <DataGrid
          columns={
            leadsCityData.length > 0
              ? Object.keys(leadsCityData[0]).map((h) => ({
                  field: h,
                  headerName: h,
                  width: 150,
                }))
              : []
          }
          rows={leadsCityData}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              csvOptions: { fileName: `LeadQuality_referrals` },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30]}
        />
      </div>

      <div
        style={{
          display: "Flex",
          alignItems: "center",
          margin: "30px 0 10px 0",
          gap: "20px",
        }}
      >
        <div
          style={{
            color: "#0069d9",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Lead Source - Lead Quality
        </div>

        <button
          type="button"
          class="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModalCenter"
        >
          Lead Quality Statuses
        </button>
      </div>
      <div className="table-container1 table-fixed">
        <div
          class="modal fade"
          id="exampleModalCenter"
          tabindex="-1"
          role="dialog"
          aria-labelledby="exampleModalCenterTitle"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content" style={{ width: "fit-content" }}>
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">
                  Modal title
                </h5>
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <table className="table-border-marketing">
                  <thead>
                    <tr>
                      <th className="table-border-marketing">Good</th>
                      <th className="table-border-marketing">Average</th>
                      <th className="table-border-marketing">Bad</th>
                      <th className="table-border-marketing">New Lead</th>
                      <th className="table-border-marketing">
                        Under Construction
                      </th>
                      <th className="table-border-marketing">Others</th>
                    </tr>
                  </thead>
                  <tbody>
                    {maxStatusLength > 0 &&
                      Array.from({ length: maxStatusLength }).map((a, i) => {
                        return (
                          <tr key={i}>
                            <td className="table-border-marketing">
                              {leadsTypes["good"][i]}
                            </td>
                            <td className="table-border-marketing">
                              {leadsTypes["average"][i]}
                            </td>
                            <td className="table-border-marketing">
                              {leadsTypes["bad"][i]}
                            </td>
                            <td className="table-border-marketing">
                              {leadsTypes["newLead"][i]}
                            </td>
                            <td className="table-border-marketing">
                              {leadsTypes["underConstruction"][i]}
                            </td>
                            <td className="table-border-marketing">
                              {otherStatus[i]}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {/* <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div> */}
            </div>
          </div>
        </div>
        <DataGrid
          columns={
            leadsQualityData?.length > 0
              ? Object.keys(leadsQualityData[0]).map((h) => ({
                  field: h,
                  headerName: h,
                  width: 150,
                }))
              : []
          }
          rows={leadsQualityData}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              csvOptions: { fileName: `LeadQuality_referrals` },
            },
          }}
          pageSizeOptions={[5, 10, 20, 30]}
        />
      </div>

      {/* <TableHeading>Adname - Current Status</TableHeading> */}
      <div style={{ height: "500px", width: "100%" }}>
        <DataGrid
          columns={
            adnameDataGrid.length > 0
              ? Object.keys(adnameDataGrid[0]).map((h) => {
                  return { field: h, headerName: h, width: 150 };
                })
              : []
          }
          rows={adnameDataGrid}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            toolbar: GridToolbar,
          }}
          slotProps={{
            toolbar: {
              csvOptions: { fileName: `LeadQuality_referrals` },
            },
          }}
          pageSizeOptions={[5, 10]}
          // checkboxSelection
          // disableRowSelectionOnClick
        />
      </div>

      <div
        style={{
          display: "Flex",
          alignItems: "center",
          margin: "30px 0 10px 0",
          gap: "20px",
        }}
      >
        <div
          style={{
            color: "#0069d9",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Lead Intervals
        </div>

        <div className="col-1">
          <button
            className="btn btn-primary mt-3"
            disabled={btn === "processing..." ? true : false}
            onClick={() => {
              getData();
              getFirstContactedData();
            }}
          >
            {btn}
          </button>
        </div>
      </div>
      <div style={{ marginTop: "20px" }}>
        <div className="row" style={{ marginBottom: "20px" }}>
          <div className="col-2">
            <label className="create-label1">Minutes</label>
            <div className="input-group">
              <input
                type="number"
                value={interval}
                onChange={(e) => setInterval(e.target.value)}
                onBlur={() => getFirstContactedData()}
              />
            </div>
          </div>
        </div>
        <div>
          <DataGrid
            columns={
              leadsIntervalData?.length > 0
                ? Object.keys(leadsIntervalData[0]).map((h) => ({
                    field: h,
                    headerName: h,
                    width: 150,
                  }))
                : []
            }
            rows={leadsIntervalData}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: { fileName: `LeadQuality_referrals` },
              },
            }}
            pageSizeOptions={[5, 10, 20, 30]}
          />
        </div>
      </div>
      <div className="mt-4 mb-4">
        <div
          style={{
            color: "#0069d9",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Leads With Created At
        </div>

        {/* <TableHeading>Adname - Current Status</TableHeading> */}
        <div style={{ height: "500px", width: "100%" }}>
          <DataGrid
            columns={
              createdTimeLeadData.length > 0
                ? Object.keys(createdTimeLeadData[0]).map((h) => {
                    return { field: h, headerName: h, width: 150 };
                  })
                : []
            }
            rows={createdTimeLeadData.map((d, i) => ({ id: i, ...d }))}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: { fileName: `Created_time` },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            // checkboxSelection
            // disableRowSelectionOnClick
          />
        </div>
      </div>

      <div className="mt-4 mb-4">
        <div
          style={{
            color: "#0069d9",
            fontWeight: "bold",
            fontSize: "1.2rem",
          }}
        >
          Leads Created At with First Contact
        </div>

        {/* <TableHeading>Adname - Current Status</TableHeading> */}
        <div style={{ height: "500px", width: "100%" }}>
          <DataGrid
            columns={


              createdTimeWithFirstContactData.length > 0
                ? [{field: "Interval", headerName: "Interval", width: "150"}, {field: "total", headerName: "Total", width: "150"},...Object.keys(createdTimeWithFirstContactData[0]).filter(h => {
                 if (h === "Interval") return false;
                 if (h === "total") return false;
                 return true;
                }).map((h) => {
                  
                  const headerName = h === 10
                  ? `${h * 10} - ${(Number(h) + 1) * 10}`
                  : `${Number(h) * interval} - ${
                      (Number(h) + 1) * interval
                    }`
                    return { field: h, headerName, width: 150 };
                  })
                ]
                : []
            }
            rows={createdTimeWithFirstContactData.map((d, i) => ({ id: i, ...d }))}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              toolbar: {
                csvOptions: { fileName: `Created_time` },
              },
            }}
            pageSizeOptions={[10, 20, 30]}
            // checkboxSelection
            // disableRowSelectionOnClick
          />
        </div>
      </div>
      <Qutoes />
    </div>
  );
}
