export const ci=[
    {
        "value": "MUMBAI"
    },
    {
        "value": "NOIDA"
    },
    {
       "value":'KALABURAGI'
    },
    {
        "value" :'Karnataka'
    },
    {
        "value" : "Andhra Pradesh"
    },
    {
        "value":"GHAZIABAD"
    },
    {
        "value": "PUNE"
    },
    {
        "value":"GREATER NOIDA"
    },
    {
        "value": "NAGPUR"
    },
    {
        "value": "THANE"
    },
    {
        "value": "NASHIK"
    },
    {
        "value": "KALYAN-DOMBIVALI"
    },
    {
        "value": "VASAI-VIRAR"
    },
    {
        "value": "SOLAPUR"
    },
    {
        "value": "MIRA-BHAYANDAR"
    },
    {
        "value": "BHIWANDI"
    },
    {
        "value": "AMRAVATI"
    },
    {
        "value": "NANDED-WAGHALA"
    },
    {
        "value": "SANGLI"
    },
    {
        "value": "MALEGAON"
    },
    {
        "value": "AKOLA"
    },
    {
        "value": "LATUR"
    },
    {
        "value": "DHULE"
    },
    {
        "value": "AHMEDNAGAR"
    },
    {
        "value": "ICHALKARANJI"
    },
    {
        "value": "PARBHANI"
    },
    {
        "value": "PANVEL"
    },
    {
        "value": "YAVATMAL"
    },
    {
        "value": "ACHALPUR"
    },
    {
        "value": "OSMANABAD"
    },
    {
        "value": "NANDURBAR"
    },
    {
        "value": "SATARA"
    },
    {
        "value": "WARDHA"
    },
    {
        "value": "UDGIR"
    },
    {
        "value": "AURANGABAD"
    },
    {
        "value": "AMALNER"
    },
    {
        "value": "AKOT"
    },
    {
        "value": "PANDHARPUR"
    },
    {
        "value": "SHRIRAMPUR"
    },
    {
        "value": "PARLI"
    },
    {
        "value": "WASHIM"
    },
    {
        "value": "AMBEJOGAI"
    },
    {
        "value": "MANMAD"
    },
    {
        "value": "RATNAGIRI"
    },
    {
        "value": "URAN ISLAMPUR"
    },
    {
        "value": "PUSAD"
    },
    {
        "value": "SANGAMNER"
    },
    {
        "value": "SHIRPUR-WARWADE"
    },
    {
        "value": "MALKAPUR"
    },
    {
        "value": "WANI"
    },
    {
        "value": "LONAVLA"
    },
    {
        "value": "TALEGAON DABHADE"
    },
    {
        "value": "ANJANGAON"
    },
    {
        "value": "UMRED"
    },
    {
        "value": "PALGHAR"
    },
    {
        "value": "SHEGAON"
    },
    {
        "value": "OZAR"
    },
    {
        "value": "PHALTAN"
    },
    {
        "value": "YEVLA"
    },
    {
        "value": "SHAHADE"
    },
    {
        "value": "VITA"
    },
    {
        "value": "UMARKHED"
    },
    {
        "value": "WARORA"
    },
    {
        "value": "PACHORA"
    },
    {
        "value": "TUMSAR"
    },
    {
        "value": "MANJLEGAON"
    },
    {
        "value": "SILLOD"
    },
    {
        "value": "ARVI"
    },
    {
        "value": "NANDURA"
    },
    {
        "value": "VAIJAPUR"
    },
    {
        "value": "WADGAON ROAD"
    },
    {
        "value": "SAILU"
    },
    {
        "value": "MURTIJAPUR"
    },
    {
        "value": "TASGAON"
    },
    {
        "value": "MEHKAR"
    },
    {
        "value": "YAWAL"
    },
    {
        "value": "PULGAON"
    },
    {
        "value": "NILANGA"
    },
    {
        "value": "WAI"
    },
    {
        "value": "UMARGA"
    },
    {
        "value": "PAITHAN"
    },
    {
        "value": "RAHURI"
    },
    {
        "value": "NAWAPUR"
    },
    {
        "value": "TULJAPUR"
    },
    {
        "value": "MORSHI"
    },
    {
        "value": "PURNA"
    },
    {
        "value": "SATANA"
    },
    {
        "value": "PATHRI"
    },
    {
        "value": "SINNAR"
    },
    {
        "value": "UCHGAON"
    },
    {
        "value": "URAN"
    },
    {
        "value": "PEN"
    },
    {
        "value": "KARJAT"
    },
    {
        "value": "MANWATH"
    },
    {
        "value": "PARTUR"
    },
    {
        "value": "SANGOLE"
    },
    {
        "value": "MANGRULPIR"
    },
    {
        "value": "RISOD"
    },
    {
        "value": "SHIRUR"
    },
    {
        "value": "SAVNER"
    },
    {
        "value": "SASVAD"
    },
    {
        "value": "PANDHARKAODA"
    },
    {
        "value": "TALODE"
    },
    {
        "value": "SHRIGONDA"
    },
    {
        "value": "SHIRDI"
    },
    {
        "value": "RAVER"
    },
    {
        "value": "MUKHED"
    },
    {
        "value": "RAJURA"
    },
    {
        "value": "VADGAON KASBA"
    },
    {
        "value": "TIRORA"
    },
    {
        "value": "MAHAD"
    },
    {
        "value": "LONAR"
    },
    {
        "value": "SAWANTWADI"
    },
    {
        "value": "PATHARDI"
    },
    {
        "value": "PAUNI"
    },
    {
        "value": "RAMTEK"
    },
    {
        "value": "MUL"
    },
    {
        "value": "SOYAGAON"
    },
    {
        "value": "MANGALVEDHE"
    },
    {
        "value": "NARKHED"
    },
    {
        "value": "SHENDURJANA"
    },
    {
        "value": "PATUR"
    },
    {
        "value": "MHASWAD"
    },
    {
        "value": "LOHA"
    },
    {
        "value": "NANDGAON"
    },
    {
        "value": "WARUD"
    },
    {
        "value": "DELHI"
    },
    {
        "value": "BENGALURU"
    },
    {
        "value": "HUBLI-DHARWAD"
    },
    {
        "value": "BELAGAVI"
    },
    {
        "value": "MANGALURU"
    },
    {
        "value": "DAVANAGERE"
    },
    {
        "value": "BALLARI"
    },
    {
        "value": "TUMKUR"
    },
    {
        "value": "SHIVAMOGGA"
    },
    {
        "value": "RAAYACHURU"
    },
    {
        "value": "ROBERTSON PET"
    },
    {
        "value": "KOLAR"
    },
    {
        "value": "MANDYA"
    },
    {
        "value": "UDUPI"
    },
    {
        "value": "CHIKKAMAGALURU"
    },
    {
        "value": "KARWAR"
    },
    {
        "value": "RANEBENNURU"
    },
    {
        "value": "RANIBENNUR"
    },
    {
        "value": "RAMANAGARAM"
    },
    {
        "value": "GOKAK"
    },
    {
        "value": "YADGIR"
    },
    {
        "value": "RABKAVI BANHATTI"
    },
    {
        "value": "SHAHABAD"
    },
    {
        "value": "SIRSI"
    },
    {
        "value": "SINDHNUR"
    },
    {
        "value": "TIPTUR"
    },
    {
        "value": "ARSIKERE"
    },
    {
        "value": "NANJANGUD"
    },
    {
        "value": "SAGARA"
    },
    {
        "value": "SIRA"
    },
    {
        "value": "PUTTUR"
    },
    {
        "value": "ATHNI"
    },
    {
        "value": "MULBAGAL"
    },
    {
        "value": "SURAPURA"
    },
    {
        "value": "SIRUGUPPA"
    },
    {
        "value": "MUDHOL"
    },
    {
        "value": "SIDLAGHATTA"
    },
    {
        "value": "SHAHPUR"
    },
    {
        "value": "SAUNDATTI-YELLAMMA"
    },
    {
        "value": "WADI"
    },
    {
        "value": "MANVI"
    },
    {
        "value": "NELAMANGALA"
    },
    {
        "value": "LAKSHMESHWAR"
    },
    {
        "value": "RAMDURG"
    },
    {
        "value": "NARGUND"
    },
    {
        "value": "TARIKERE"
    },
    {
        "value": "MALAVALLI"
    },
    {
        "value": "SAVANUR"
    },
    {
        "value": "LINGSUGUR"
    },
    {
        "value": "VIJAYAPURA"
    },
    {
        "value": "SANKESHWARA"
    },
    {
        "value": "MADIKERI"
    },
    {
        "value": "TALIKOTA"
    },
    {
        "value": "SEDAM"
    },
    {
        "value": "SHIKARIPUR"
    },
    {
        "value": "MAHALINGAPURA"
    },
    {
        "value": "MUDALAGI"
    },
    {
        "value": "MUDDEBIHAL"
    },
    {
        "value": "PAVAGADA"
    },
    {
        "value": "MALUR"
    },
    {
        "value": "SINDHAGI"
    },
    {
        "value": "SANDURU"
    },
    {
        "value": "AFZALPUR"
    },
    {
        "value": "MADDUR"
    },
    {
        "value": "MADHUGIRI"
    },
    {
        "value": "TEKKALAKOTE"
    },
    {
        "value": "TERDAL"
    },
    {
        "value": "MUDABIDRI"
    },
    {
        "value": "MAGADI"
    },
    {
        "value": "NAVALGUND"
    },
    {
        "value": "SHIGGAON"
    },
    {
        "value": "SHRIRANGAPATTANA"
    },
    {
        "value": "SINDAGI"
    },
    {
        "value": "SAKALESHAPURA"
    },
    {
        "value": "SRINIVASPUR"
    },
    {
        "value": "RON"
    },
    {
        "value": "MUNDARGI"
    },
    {
        "value": "SADALAGI"
    },
    {
        "value": "PIRIYAPATNA"
    },
    {
        "value": "ADYAR"
    },
    {
        "value": "AHMEDABAD"
    },
    {
        "value": "SURAT"
    },
    {
        "value": "VADODARA"
    },
    {
        "value": "RAJKOT"
    },
    {
        "value": "BHAVNAGAR"
    },
    {
        "value": "JAMNAGAR"
    },
    {
        "value": "NADIAD"
    },
    {
        "value": "PORBANDAR"
    },
    {
        "value": "ANAND"
    },
    {
        "value": "MORVI"
    },
    {
        "value": "MAHESANA"
    },
    {
        "value": "BHARUCH"
    },
    {
        "value": "VAPI"
    },
    {
        "value": "NAVSARI"
    },
    {
        "value": "VERAVAL"
    },
    {
        "value": "BHUJ"
    },
    {
        "value": "GODHRA"
    },
    {
        "value": "PALANPUR"
    },
    {
        "value": "VALSAD"
    },
    {
        "value": "PATAN"
    },
    {
        "value": "DEESA"
    },
    {
        "value": "AMRELI"
    },
    {
        "value": "ANJAR"
    },
    {
        "value": "DHORAJI"
    },
    {
        "value": "KHAMBHAT"
    },
    {
        "value": "MAHUVA"
    },
    {
        "value": "KESHOD"
    },
    {
        "value": "WADHWAN"
    },
    {
        "value": "ANKLESHWAR"
    },
    {
        "value": "SAVARKUNDLA"
    },
    {
        "value": "KADI"
    },
    {
        "value": "VISNAGAR"
    },
    {
        "value": "UPLETA"
    },
    {
        "value": "UNA"
    },
    {
        "value": "SIDHPUR"
    },
    {
        "value": "UNJHA"
    },
    {
        "value": "MANGROL"
    },
    {
        "value": "VIRAMGAM"
    },
    {
        "value": "MODASA"
    },
    {
        "value": "PALITANA"
    },
    {
        "value": "PETLAD"
    },
    {
        "value": "KAPADVANJ"
    },
    {
        "value": "SIHOR"
    },
    {
        "value": "WANKANER"
    },
    {
        "value": "LIMBDI"
    },
    {
        "value": "MANDVI"
    },
    {
        "value": "THANGADH"
    },
    {
        "value": "VYARA"
    },
    {
        "value": "PADRA"
    },
    {
        "value": "LUNAWADA"
    },
    {
        "value": "RAJPIPLA"
    },
    {
        "value": "VAPI"
    },
    {
        "value": "UMRETH"
    },
    {
        "value": "SANAND"
    },
    {
        "value": "RAJULA"
    },
    {
        "value": "RADHANPUR"
    },
    {
        "value": "MAHEMDABAD"
    },
    {
        "value": "RANAVAV"
    },
    {
        "value": "THARAD"
    },
    {
        "value": "MANSA"
    },
    {
        "value": "UMBERGAON"
    },
    {
        "value": "TALAJA"
    },
    {
        "value": "VADNAGAR"
    },
    {
        "value": "MANAVADAR"
    },
    {
        "value": "SALAYA"
    },
    {
        "value": "VIJAPUR"
    },
    {
        "value": "PARDI"
    },
    {
        "value": "RAPAR"
    },
    {
        "value": "SONGADH"
    },
    {
        "value": "LATHI"
    },
    {
        "value": "ADALAJ"
    },
    {
        "value": "CHHAPRA"
    },
    {
        "value": "HYDERABAD"
    },
    {
        "value": "WARANGAL"
    },
    {
        "value": "NIZAMABAD"
    },
    {
        "value": "KARIMNAGAR"
    },
    {
        "value": "RAMAGUNDAM"
    },
    {
        "value": "KHAMMAM"
    },
    {
        "value": "MAHBUBNAGAR"
    },
    {
        "value": "MANCHERIAL"
    },
    {
        "value": "ADILABAD"
    },
    {
        "value": "SURYAPET"
    },
    {
        "value": "JAGTIAL"
    },
    {
        "value": "MIRYALAGUDA"
    },
    {
        "value": "NIRMAL"
    },
    {
        "value": "KAMAREDDY"
    },
    {
        "value": "KOTHAGUDEM"
    },
    {
        "value": "BODHAN"
    },
    {
        "value": "PALWANCHA"
    },
    {
        "value": "MANDAMARRI"
    },
    {
        "value": "KORATLA"
    },
    {
        "value": "SIRCILLA"
    },
    {
        "value": "TANDUR"
    },
    {
        "value": "SIDDIPET"
    },
    {
        "value": "WANAPARTHY"
    },
    {
        "value": "KAGAZNAGAR"
    },
    {
        "value": "GADWAL"
    },
    {
        "value": "SANGAREDDY"
    },
    {
        "value": "BELLAMPALLE"
    },
    {
        "value": "BHONGIR"
    },
    {
        "value": "VIKARABAD"
    },
    {
        "value": "JANGAON"
    },
    {
        "value": "BHADRACHALAM"
    },
    {
        "value": "BHAINSA"
    },
    {
        "value": "FAROOQNAGAR"
    },
    {
        "value": "MEDAK"
    },
    {
        "value": "NARAYANPET"
    },
    {
        "value": "SADASIVPET"
    },
    {
        "value": "YELLANDU"
    },
    {
        "value": "MANUGURU"
    },
    {
        "value": "KYATHAMPALLE"
    },
    {
        "value": "NAGARKURNOOL"
    },
    {
        "value": "CHENNAI"
    },
    {
        "value": "COIMBATORE"
    },
    {
        "value": "MADURAI"
    },
    {
        "value": "TIRUCHIRAPPALLI"
    },
    {
        "value": "SALEM"
    },
    {
        "value": "TIRUNELVELI"
    },
    {
        "value": "TIRUPPUR"
    },
    {
        "value": "RANIPET"
    },
    {
        "value": "NAGERCOIL"
    },
    {
        "value": "THANJAVUR"
    },
    {
        "value": "VELLORE"
    },
    {
        "value": "KANCHEEPURAM"
    },
    {
        "value": "ERODE"
    },
    {
        "value": "TIRUVANNAMALAI"
    },
    {
        "value": "POLLACHI"
    },
    {
        "value": "RAJAPALAYAM"
    },
    {
        "value": "SIVAKASI"
    },
    {
        "value": "PUDUKKOTTAI"
    },
    {
        "value": "NEYVELI (TS)"
    },
    {
        "value": "NAGAPATTINAM"
    },
    {
        "value": "VILUPPURAM"
    },
    {
        "value": "TIRUCHENGODE"
    },
    {
        "value": "VANIYAMBADI"
    },
    {
        "value": "THENI ALLINAGARAM"
    },
    {
        "value": "UDHAGAMANDALAM"
    },
    {
        "value": "ARUPPUKKOTTAI"
    },
    {
        "value": "PARAMAKUDI"
    },
    {
        "value": "ARAKKONAM"
    },
    {
        "value": "VIRUDHACHALAM"
    },
    {
        "value": "SRIVILLIPUTHUR"
    },
    {
        "value": "TINDIVANAM"
    },
    {
        "value": "VIRUDHUNAGAR"
    },
    {
        "value": "KARUR"
    },
    {
        "value": "VALPARAI"
    },
    {
        "value": "SANKARANKOVIL"
    },
    {
        "value": "TENKASI"
    },
    {
        "value": "PALANI"
    },
    {
        "value": "PATTUKKOTTAI"
    },
    {
        "value": "TIRUPATHUR"
    },
    {
        "value": "RAMANATHAPURAM"
    },
    {
        "value": "UDUMALAIPETTAI"
    },
    {
        "value": "GOBICHETTIPALAYAM"
    },
    {
        "value": "THIRUVARUR"
    },
    {
        "value": "THIRUVALLUR"
    },
    {
        "value": "PANRUTI"
    },
    {
        "value": "NAMAKKAL"
    },
    {
        "value": "THIRUMANGALAM"
    },
    {
        "value": "VIKRAMASINGAPURAM"
    },
    {
        "value": "NELLIKUPPAM"
    },
    {
        "value": "RASIPURAM"
    },
    {
        "value": "TIRUTTANI"
    },
    {
        "value": "NANDIVARAM-GUDUVANCHERI"
    },
    {
        "value": "PERIYAKULAM"
    },
    {
        "value": "PERNAMPATTU"
    },
    {
        "value": "VELLAKOIL"
    },
    {
        "value": "SIVAGANGA"
    },
    {
        "value": "VADALUR"
    },
    {
        "value": "RAMESHWARAM"
    },
    {
        "value": "TIRUVETHIPURAM"
    },
    {
        "value": "PERAMBALUR"
    },
    {
        "value": "USILAMPATTI"
    },
    {
        "value": "VEDARANYAM"
    },
    {
        "value": "SATHYAMANGALAM"
    },
    {
        "value": "PULIYANKUDI"
    },
    {
        "value": "NANJIKOTTAI"
    },
    {
        "value": "THURAIYUR"
    },
    {
        "value": "SIRKALI"
    },
    {
        "value": "TIRUCHENDUR"
    },
    {
        "value": "PERIYASEMUR"
    },
    {
        "value": "SATTUR"
    },
    {
        "value": "VANDAVASI"
    },
    {
        "value": "THARAMANGALAM"
    },
    {
        "value": "TIRUKKOYILUR"
    },
    {
        "value": "ODDANCHATRAM"
    },
    {
        "value": "PALLADAM"
    },
    {
        "value": "VADAKKUVALLIYUR"
    },
    {
        "value": "TIRUKALUKUNDRAM"
    },
    {
        "value": "UTHAMAPALAYAM"
    },
    {
        "value": "SURANDAI"
    },
    {
        "value": "SANKARI"
    },
    {
        "value": "SHENKOTTAI"
    },
    {
        "value": "VADIPATTI"
    },
    {
        "value": "SHOLINGUR"
    },
    {
        "value": "TIRUPATHUR"
    },
    {
        "value": "MANACHANALLUR"
    },
    {
        "value": "VISWANATHAM"
    },
    {
        "value": "POLUR"
    },
    {
        "value": "PANAGUDI"
    },
    {
        "value": "UTHIRAMERUR"
    },
    {
        "value": "THIRUTHURAIPOONDI"
    },
    {
        "value": "PALLAPATTI"
    },
    {
        "value": "PONNERI"
    },
    {
        "value": "LALGUDI"
    },
    {
        "value": "NATHAM"
    },
    {
        "value": "UNNAMALAIKADAI"
    },
    {
        "value": "P.N.PATTI"
    },
    {
        "value": "THARANGAMBADI"
    },
    {
        "value": "TITTAKUDI"
    },
    {
        "value": "PACODE"
    },
    {
        "value": "O' VALLEY"
    },
    {
        "value": "SURIYAMPALAYAM"
    },
    {
        "value": "SHOLAVANDAN"
    },
    {
        "value": "THAMMAMPATTI"
    },
    {
        "value": "NAMAGIRIPETTAI"
    },
    {
        "value": "PERAVURANI"
    },
    {
        "value": "PARANGIPETTAI"
    },
    {
        "value": "PUDUPATTINAM"
    },
    {
        "value": "PALLIKONDA"
    },
    {
        "value": "SIVAGIRI"
    },
    {
        "value": "PUNJAIPUGALUR"
    },
    {
        "value": "PADMANABHAPURAM"
    },
    {
        "value": "THIRUPUVANAM"
    },
    {
        "value": "KOLKATA"
    },
    {
        "value": "SILIGURI"
    },
    {
        "value": "ASANSOL"
    },
    {
        "value": "RAGHUNATHGANJ"
    },
    {
        "value": "KHARAGPUR"
    },
    {
        "value": "NAIHATI"
    },
    {
        "value": "ENGLISH BAZAR"
    },
    {
        "value": "BAHARAMPUR"
    },
    {
        "value": "HUGLI-CHINSURAH"
    },
    {
        "value": "RAIGANJ"
    },
    {
        "value": "JALPAIGURI"
    },
    {
        "value": "SANTIPUR"
    },
    {
        "value": "BALURGHAT"
    },
    {
        "value": "MEDINIPUR"
    },
    {
        "value": "HABRA"
    },
    {
        "value": "RANAGHAT"
    },
    {
        "value": "BANKURA"
    },
    {
        "value": "NABADWIP"
    },
    {
        "value": "DARJILING"
    },
    {
        "value": "PURULIA"
    },
    {
        "value": "ARAMBAGH"
    },
    {
        "value": "TAMLUK"
    },
    {
        "value": "ALIPURDURBAN AGGLOMERATIONR"
    },
    {
        "value": "SURI"
    },
    {
        "value": "JHARGRAM"
    },
    {
        "value": "GANGARAMPUR"
    },
    {
        "value": "RAMPURHAT"
    },
    {
        "value": "KALIMPONG"
    },
    {
        "value": "SAINTHIA"
    },
    {
        "value": "TAKI"
    },
    {
        "value": "MURSHIDABAD"
    },
    {
        "value": "MEMARI"
    },
    {
        "value": "PASCHIM PUNROPARA"
    },
    {
        "value": "TARAKESWAR"
    },
    {
        "value": "SONAMUKHI"
    },
    {
        "value": "PANDURBAN AGGLOMERATION"
    },
    {
        "value": "MAINAGURI"
    },
    {
        "value": "MALDA"
    },
    {
        "value": "PANCHLA"
    },
    {
        "value": "RAGHUNATHPUR"
    },
    {
        "value": "MATHABHANGA"
    },
    {
        "value": "MONOHARPUR"
    },
    {
        "value": "SRIRAMPORE"
    },
    {
        "value": "ADRA"
    },
    {
        "value": "JAIPUR"
    },
    {
        "value": "JODHPUR"
    },
    {
        "value": "BIKANER"
    },
    {
        "value": "UDAIPUR"
    },
    {
        "value": "AJMER"
    },
    {
        "value": "BHILWARA"
    },
    {
        "value": "ALWAR"
    },
    {
        "value": "BHARATPUR"
    },
    {
        "value": "PALI"
    },
    {
        "value": "BARMER"
    },
    {
        "value": "SIKAR"
    },
    {
        "value": "TONK"
    },
    {
        "value": "SADULPUR"
    },
    {
        "value": "SAWAI MADHOPUR"
    },
    {
        "value": "NAGAUR"
    },
    {
        "value": "MAKRANA"
    },
    {
        "value": "SUJANGARH"
    },
    {
        "value": "SARDARSHAHAR"
    },
    {
        "value": "LADNU"
    },
    {
        "value": "RATANGARH"
    },
    {
        "value": "NOKHA"
    },
    {
        "value": "NIMBAHERA"
    },
    {
        "value": "SURATGARH"
    },
    {
        "value": "RAJSAMAND"
    },
    {
        "value": "LACHHMANGARH"
    },
    {
        "value": "RAJGARH (CHURU)"
    },
    {
        "value": "NASIRABAD"
    },
    {
        "value": "NOHAR"
    },
    {
        "value": "PHALODI"
    },
    {
        "value": "NATHDWARA"
    },
    {
        "value": "PILANI"
    },
    {
        "value": "MERTA CITY"
    },
    {
        "value": "SOJAT"
    },
    {
        "value": "NEEM-KA-THANA"
    },
    {
        "value": "SIROHI"
    },
    {
        "value": "PRATAPGARH"
    },
    {
        "value": "RAWATBHATA"
    },
    {
        "value": "SANGARIA"
    },
    {
        "value": "LALSOT"
    },
    {
        "value": "PILIBANGA"
    },
    {
        "value": "PIPAR CITY"
    },
    {
        "value": "TARANAGAR"
    },
    {
        "value": "VIJAINAGAR, AJMER"
    },
    {
        "value": "SUMERPUR"
    },
    {
        "value": "SAGWARA"
    },
    {
        "value": "RAMGANJ MANDI"
    },
    {
        "value": "LAKHERI"
    },
    {
        "value": "UDAIPURWATI"
    },
    {
        "value": "LOSAL"
    },
    {
        "value": "SRI MADHOPUR"
    },
    {
        "value": "RAMNGARH"
    },
    {
        "value": "RAWATSAR"
    },
    {
        "value": "RAJAKHERA"
    },
    {
        "value": "SHAHPURA"
    },
    {
        "value": "SHAHPURA"
    },
    {
        "value": "RAISINGHNAGAR"
    },
    {
        "value": "MALPURA"
    },
    {
        "value": "NADBAI"
    },
    {
        "value": "SANCHORE"
    },
    {
        "value": "NAGAR"
    },
    {
        "value": "RAJGARH (ALWAR)"
    },
    {
        "value": "SHEOGANJ"
    },
    {
        "value": "SADRI"
    },
    {
        "value": "TODARAISINGH"
    },
    {
        "value": "TODABHIM"
    },
    {
        "value": "REENGUS"
    },
    {
        "value": "RAJALDESAR"
    },
    {
        "value": "SADULSHAHAR"
    },
    {
        "value": "SAMBHAR"
    },
    {
        "value": "PRANTIJ"
    },
    {
        "value": "MOUNT ABU"
    },
    {
        "value": "MANGROL"
    },
    {
        "value": "PHULERA"
    },
    {
        "value": "MANDAWA"
    },
    {
        "value": "PINDWARA"
    },
    {
        "value": "MANDALGARH"
    },
    {
        "value": "TAKHATGARH"
    },
    {
        "value": "LUCKNOW"
    },
    {
        "value": "KANPUR"
    },
    {
        "value": "FIROZABAD"
    },
    {
        "value": "AGRA"
    },
    {
        "value": "MEERUT"
    },
    {
        "value": "VARANASI"
    },
    {
        "value": "ALLAHABAD"
    },
    {
        "value": "AMROHA"
    },
    {
        "value": "MORADABAD"
    },
    {
        "value": "ALIGARH"
    },
    {
        "value": "SAHARANPUR"
    },
    
    {
        "value": "LONI"
    },
    {
        "value": "JHANSI"
    },
    {
        "value": "SHAHJAHANPUR"
    },
    {
        "value": "RAMPUR"
    },
    {
        "value": "MODINAGAR"
    },
    {
        "value": "HAPUR"
    },
    {
        "value": "ETAWAH"
    },
    {
        "value": "SAMBHAL"
    },
    {
        "value": "ORAI"
    },
    {
        "value": "BAHRAICH"
    },
    {
        "value": "UNNAO"
    },
    {
        "value": "RAE BARELI"
    },
    {
        "value": "LAKHIMPUR"
    },
    {
        "value": "SITAPUR"
    },
    {
        "value": "LALITPUR"
    },
    {
        "value": "PILIBHIT"
    },
    {
        "value": "CHANDAUSI"
    },
    {
        "value": "HARDOI "
    },
    {
        "value": "AZAMGARH"
    },
    {
        "value": "KHAIR"
    },
    {
        "value": "SULTANPUR"
    },
    {
        "value": "TANDA"
    },
    {
        "value": "NAGINA"
    },
    {
        "value": "SHAMLI"
    },
    {
        "value": "NAJIBABAD"
    },
    {
        "value": "SHIKOHABAD"
    },
    {
        "value": "SIKANDRABAD"
    },
    {
        "value": "SHAHABAD, HARDOI"
    },
    {
        "value": "PILKHUWA"
    },
    {
        "value": "RENUKOOT"
    },
    {
        "value": "VRINDAVAN"
    },
    {
        "value": "UJHANI"
    },
    {
        "value": "LAHARPUR"
    },
    {
        "value": "TILHAR"
    },
    {
        "value": "SAHASWAN"
    },
    {
        "value": "RATH"
    },
    {
        "value": "SHERKOT"
    },
    {
        "value": "KALPI"
    },
    {
        "value": "TUNDLA"
    },
    {
        "value": "SANDILA"
    },
    {
        "value": "NANPARA"
    },
    {
        "value": "SARDHANA"
    },
    {
        "value": "NEHTAUR"
    },
    {
        "value": "SEOHARA"
    },
    {
        "value": "PADRAUNA"
    },
    {
        "value": "MATHURA"
    },
    {
        "value": "THAKURDWARA"
    },
    {
        "value": "NAWABGANJ"
    },
    {
        "value": "SIANA"
    },
    {
        "value": "NOORPUR"
    },
    {
        "value": "SIKANDRA RAO"
    },
    {
        "value": "PURANPUR"
    },
    {
        "value": "RUDAULI"
    },
    {
        "value": "THANA BHAWAN"
    },
    {
        "value": "PALIA KALAN"
    },
    {
        "value": "ZAIDPUR"
    },
    {
        "value": "NAUTANWA"
    },
    {
        "value": "ZAMANIA"
    },
    {
        "value": "SHIKARPUR, BULANDSHAHR"
    },
    {
        "value": "NAUGAWAN SADAT"
    },
    {
        "value": "FATEHPUR SIKRI"
    },
    {
        "value": "SHAHABAD, RAMPUR"
    },
    {
        "value": "ROBERTSGANJ"
    },
    {
        "value": "UTRAULA"
    },
    {
        "value": "SADABAD"
    },
    {
        "value": "RASRA"
    },
    {
        "value": "LAR"
    },
    {
        "value": "LAL GOPALGANJ NINDAURA"
    },
    {
        "value": "SIRSAGANJ"
    },
    {
        "value": "PIHANI"
    },
    {
        "value": "SHAMSABAD, AGRA"
    },
    {
        "value": "RUDRAPUR"
    },
    {
        "value": "SORON"
    },
    {
        "value": "SURBAN AGGLOMERATIONR"
    },
    {
        "value": "SAMDHAN"
    },
    {
        "value": "SAHJANWA"
    },
    {
        "value": "RAMPUR MANIHARAN"
    },
    {
        "value": "SUMERPUR"
    },
    {
        "value": "SHAHGANJ"
    },
    {
        "value": "TULSIPUR"
    },
    {
        "value": "TIRWAGANJ"
    },
    {
        "value": "PURQURBAN AGGLOMERATIONZI"
    },
    {
        "value": "SHAMSABAD, FARRUKHABAD"
    },
    {
        "value": "WARHAPUR"
    },
    {
        "value": "POWAYAN"
    },
    {
        "value": "SANDI"
    },
    {
        "value": "ACHHNERA"
    },
    {
        "value": "NARAURA"
    },
    {
        "value": "NAKUR"
    },
    {
        "value": "SAHASPUR"
    },
    {
        "value": "SAFIPUR"
    },
    {
        "value": "REOTI"
    },
    {
        "value": "SIKANDERPUR"
    },
    {
        "value": "SAIDPUR"
    },
    {
        "value": "SIRSI"
    },
    {
        "value": "PURWA"
    },
    {
        "value": "PARASI"
    },
    {
        "value": "LALGANJ"
    },
    {
        "value": "PHULPUR"
    },
    {
        "value": "SHISHGARH"
    },
    {
        "value": "SAHAWAR"
    },
    {
        "value": "SAMTHAR"
    },
    {
        "value": "PUKHRAYAN"
    },
    {
        "value": "OBRA"
    },
    {
        "value": "NIWAI"
    },
    {
        "value": "PATNA"
    },
    {
        "value": "GAYA"
    },
    {
        "value": "BHAGALPUR"
    },
    {
        "value": "MUZAFFARPUR"
    },
    {
        "value": "DARBHANGA"
    },
    {
        "value": "ARRAH"
    },
    {
        "value": "BEGUSARAI"
    },
    {
        "value": "CHHAPRA"
    },
    {
        "value": "KATIHAR"
    },
    {
        "value": "MUNGER"
    },
    {
        "value": "PURNIA"
    },
    {
        "value": "SAHARSA"
    },
    {
        "value": "SASARAM"
    },
    {
        "value": "HAJIPUR"
    },
    {
        "value": "DEHRI-ON-SONE"
    },
    {
        "value": "BETTIAH"
    },
    {
        "value": "MOTIHARI"
    },
    {
        "value": "BAGAHA"
    },
    {
        "value": "SIWAN"
    },
    {
        "value": "KISHANGANJ"
    },
    {
        "value": "JAMALPUR"
    },
    {
        "value": "BUXAR"
    },
    {
        "value": "JEHANABAD"
    },
    {
        "value": "AURANGABAD"
    },
    {
        "value": "LAKHISARAI"
    },
    {
        "value": "NAWADA"
    },
    {
        "value": "JAMUI"
    },
    {
        "value": "SITAMARHI"
    },
    {
        "value": "ARARIA"
    },
    {
        "value": "GOPALGANJ"
    },
    {
        "value": "MADHUBANI"
    },
    {
        "value": "MASAURHI"
    },
    {
        "value": "SAMASTIPUR"
    },
    {
        "value": "MOKAMEH"
    },
    {
        "value": "SUPAUL"
    },
    {
        "value": "DUMRAON"
    },
    {
        "value": "ARWAL"
    },
    {
        "value": "FORBESGANJ"
    },
    {
        "value": "BHABURBAN AGGLOMERATION"
    },
    {
        "value": "NARKATIAGANJ"
    },
    {
        "value": "NAUGACHHIA"
    },
    {
        "value": "MADHEPURA"
    },
    {
        "value": "SHEIKHPURA"
    },
    {
        "value": "SULTANGANJ"
    },
    {
        "value": "RAXAUL BAZAR"
    },
    {
        "value": "RAMNAGAR"
    },
    {
        "value": "MAHNAR BAZAR"
    },
    {
        "value": "WARISALIGANJ"
    },
    {
        "value": "REVELGANJ"
    },
    {
        "value": "RAJGIR"
    },
    {
        "value": "SONEPUR"
    },
    {
        "value": "SHERGHATI"
    },
    {
        "value": "SUGAULI"
    },
    {
        "value": "MAKHDUMPUR"
    },
    {
        "value": "MANER"
    },
    {
        "value": "ROSERA"
    },
    {
        "value": "NOKHA"
    },
    {
        "value": "PIRO"
    },
    {
        "value": "RAFIGANJ"
    },
    {
        "value": "MARHAURA"
    },
    {
        "value": "MIRGANJ"
    },
    {
        "value": "LALGANJ"
    },
    {
        "value": "MURLIGANJ"
    },
    {
        "value": "MOTIPUR"
    },
    {
        "value": "MANIHARI"
    },
    {
        "value": "SHEOHAR"
    },
    {
        "value": "MAHARAJGANJ"
    },
    {
        "value": "SILAO"
    },
    {
        "value": "BARH"
    },
    {
        "value": "ASARGANJ"
    },
    {
        "value": "INDORE"
    },
    {
        "value": "BHOPAL"
    },
    {
        "value": "JABALPUR"
    },
    {
        "value": "GWALIOR"
    },
    {
        "value": "UJJAIN"
    },
    {
        "value": "SAGAR"
    },
    {
        "value": "RATLAM"
    },
    {
        "value": "SATNA"
    },
    {
        "value": "MURWARA (KATNI)"
    },
    {
        "value": "MORENA"
    },
    {
        "value": "SINGRAULI"
    },
    {
        "value": "REWA"
    },
    {
        "value": "VIDISHA"
    },
    {
        "value": "GANJBASODA"
    },
    {
        "value": "SHIVPURI"
    },
    {
        "value": "MANDSAUR"
    },
    {
        "value": "NEEMUCH"
    },
    {
        "value": "NAGDA"
    },
    {
        "value": "ITARSI"
    },
    {
        "value": "SARNI"
    },
    {
        "value": "SEHORE"
    },
    {
        "value": "MHOW CANTONMENT"
    },
    {
        "value": "SEONI"
    },
    {
        "value": "BALAGHAT"
    },
    {
        "value": "ASHOK NAGAR"
    },
    {
        "value": "TIKAMGARH"
    },
    {
        "value": "SHAHDOL"
    },
    {
        "value": "PITHAMPUR"
    },
    {
        "value": "ALIRAJPUR"
    },
    {
        "value": "MANDLA"
    },
    {
        "value": "SHEOPUR"
    },
    {
        "value": "SHAJAPUR"
    },
    {
        "value": "PANNA"
    },
    {
        "value": "RAGHOGARH-VIJAYPUR"
    },
    {
        "value": "SENDHWA"
    },
    {
        "value": "SIDHI"
    },
    {
        "value": "PIPARIYA"
    },
    {
        "value": "SHUJALPUR"
    },
    {
        "value": "SIRONJ"
    },
    {
        "value": "PANDHURNA"
    },
    {
        "value": "NOWGONG"
    },
    {
        "value": "MANDIDEEP"
    },
    {
        "value": "SIHORA"
    },
    {
        "value": "RAISEN"
    },
    {
        "value": "LAHAR"
    },
    {
        "value": "MAIHAR"
    },
    {
        "value": "SANAWAD"
    },
    {
        "value": "SABALGARH"
    },
    {
        "value": "UMARIA"
    },
    {
        "value": "PORSA"
    },
    {
        "value": "NARSINGHGARH"
    },
    {
        "value": "MALAJ KHAND"
    },
    {
        "value": "SARANGPUR"
    },
    {
        "value": "MUNDI"
    },
    {
        "value": "NEPANAGAR"
    },
    {
        "value": "PASAN"
    },
    {
        "value": "MAHIDPUR"
    },
    {
        "value": "SEONI-MALWA"
    },
    {
        "value": "REHLI"
    },
    {
        "value": "MANAWAR"
    },
    {
        "value": "RAHATGARH"
    },
    {
        "value": "PANAGAR"
    },
    {
        "value": "WARA SEONI"
    },
    {
        "value": "TARANA"
    },
    {
        "value": "SAUSAR"
    },
    {
        "value": "RAJGARH"
    },
    {
        "value": "NIWARI"
    },
    {
        "value": "MAUGANJ"
    },
    {
        "value": "MANASA"
    },
    {
        "value": "NAINPUR"
    },
    {
        "value": "PRITHVIPUR"
    },
    {
        "value": "SOHAGPUR"
    },
    {
        "value": "NOWROZABAD (KHODARGAMA)"
    },
    {
        "value": "SHAMGARH"
    },
    {
        "value": "MAHARAJPUR"
    },
    {
        "value": "MULTAI"
    },
    {
        "value": "PALI"
    },
    {
        "value": "PACHORE"
    },
    {
        "value": "RAU"
    },
    {
        "value": "MHOWGAON"
    },
    {
        "value": "VIJAYPUR"
    },
    {
        "value": "NARSINGHGARH"
    },
    {
        "value": "VISAKHAPATNAM"
    },
    {
        "value": "VIJAYAWADA"
    },
    {
        "value": "GUNTUR"
    },
    {
        "value": "NELLORE"
    },
    {
        "value": "KURNOOL"
    },
    {
        "value": "RAJAHMUNDRY"
    },
    {
        "value": "KAKINADA"
    },
    {
        "value": "TIRUPATI"
    },
    {
        "value": "ANANTAPUR"
    },
    {
        "value": "KADAPA"
    },
    {
        "value": "VIZIANAGARAM"
    },
    {
        "value": "ELURU"
    },
    {
        "value": "ONGOLE"
    },
    {
        "value": "NANDYAL"
    },
    {
        "value": "MACHILIPATNAM"
    },
    {
        "value": "ADONI"
    },
    {
        "value": "TENALI"
    },
    {
        "value": "CHITTOOR"
    },
    {
        "value": "HINDUPUR"
    },
    {
        "value": "PRODDATUR"
    },
    {
        "value": "BHIMAVARAM"
    },
    {
        "value": "MADANAPALLE"
    },
    {
        "value": "GUNTAKAL"
    },
    {
        "value": "DHARMAVARAM"
    },
    {
        "value": "GUDIVADA"
    },
    {
        "value": "SRIKAKULAM"
    },
    {
        "value": "NARASARAOPET"
    },
    {
        "value": "RAJAMPET"
    },
    {
        "value": "TADPATRI"
    },
    {
        "value": "TADEPALLIGUDEM"
    },
    {
        "value": "CHILAKALURIPET"
    },
    {
        "value": "YEMMIGANUR"
    },
    {
        "value": "KADIRI"
    },
    {
        "value": "CHIRALA"
    },
    {
        "value": "ANAKAPALLE"
    },
    {
        "value": "KAVALI"
    },
    {
        "value": "PALACOLE"
    },
    {
        "value": "SULLURPETA"
    },
    {
        "value": "TANUKU"
    },
    {
        "value": "RAYACHOTI"
    },
    {
        "value": "SRIKALAHASTI"
    },
    {
        "value": "BAPATLA"
    },
    {
        "value": "NAIDUPET"
    },
    {
        "value": "NAGARI"
    },
    {
        "value": "GUDUR"
    },
    {
        "value": "VINUKONDA"
    },
    {
        "value": "NARASAPURAM"
    },
    {
        "value": "NUZVID"
    },
    {
        "value": "MARKAPUR"
    },
    {
        "value": "PONNUR"
    },
    {
        "value": "KANDUKUR"
    },
    {
        "value": "BOBBILI"
    },
    {
        "value": "RAYADURG"
    },
    {
        "value": "SAMALKOT"
    },
    {
        "value": "JAGGAIAHPET"
    },
    {
        "value": "TUNI"
    },
    {
        "value": "AMALAPURAM"
    },
    {
        "value": "BHEEMUNIPATNAM"
    },
    {
        "value": "VENKATAGIRI"
    },
    {
        "value": "SATTENAPALLE"
    },
    {
        "value": "PITHAPURAM"
    },
    {
        "value": "PALASA KASIBUGGA"
    },
    {
        "value": "PARVATHIPURAM"
    },
    {
        "value": "MACHERLA"
    },
    {
        "value": "GOOTY"
    },
    {
        "value": "SALUR"
    },
    {
        "value": "MANDAPETA"
    },
    {
        "value": "JAMMALAMADUGU"
    },
    {
        "value": "PEDDAPURAM"
    },
    {
        "value": "PUNGANUR"
    },
    {
        "value": "NIDADAVOLE"
    },
    {
        "value": "REPALLE"
    },
    {
        "value": "RAMACHANDRAPURAM"
    },
    {
        "value": "KOVVUR"
    },
    {
        "value": "TIRUVURU"
    },
    {
        "value": "URAVAKONDA"
    },
    {
        "value": "NARSIPATNAM"
    },
    {
        "value": "YERRAGUNTLA"
    },
    {
        "value": "PEDANA"
    },
    {
        "value": "PUTTUR"
    },
    {
        "value": "RENIGUNTA"
    },
    {
        "value": "RAJAM"
    },
    {
        "value": "SRISAILAM PROJECT (RIGHT FLANK COLONY) TOWNSHIP"
    },
    {
        "value": "LUDHIANA"
    },
    {
        "value": "PATIALA"
    },
    {
        "value": "AMRITSAR"
    },
    {
        "value": "JALANDHAR"
    },
    {
        "value": "BATHINDA"
    },
    {
        "value": "PATHANKOT"
    },
    {
        "value": "HOSHIARPUR"
    },
    {
        "value": "BATALA"
    },
    {
        "value": "MOGA"
    },
    {
        "value": "MALERKOTLA"
    },
    {
        "value": "KHANNA"
    },
    {
        "value": "MOHALI"
    },
    {
        "value": "BARNALA"
    },
    {
        "value": "FIROZPUR"
    },
    {
        "value": "PHAGWARA"
    },
    {
        "value": "KAPURTHALA"
    },
    {
        "value": "ZIRAKPUR"
    },
    {
        "value": "KOT KAPURA"
    },
    {
        "value": "FARIDKOT"
    },
    {
        "value": "MUKTSAR"
    },
    {
        "value": "RAJPURA"
    },
    {
        "value": "SANGRUR"
    },
    {
        "value": "FAZILKA"
    },
    {
        "value": "GURDASPUR"
    },
    {
        "value": "KHARAR"
    },
    {
        "value": "GOBINDGARH"
    },
    {
        "value": "MANSA"
    },
    {
        "value": "MALOUT"
    },
    {
        "value": "NABHA"
    },
    {
        "value": "TARN TARAN"
    },
    {
        "value": "JAGRAON"
    },
    {
        "value": "SUNAM"
    },
    {
        "value": "DHURI"
    },
    {
        "value": "FIROZPUR CANTT."
    },
    {
        "value": "SIRHIND FATEHGARH SAHIB"
    },
    {
        "value": "RUPNAGAR"
    },
    {
        "value": "JALANDHAR CANTT."
    },
    {
        "value": "SAMANA"
    },
    {
        "value": "NAWANSHAHR"
    },
    {
        "value": "RAMPURA PHUL"
    },
    {
        "value": "NANGAL"
    },
    {
        "value": "NAKODAR"
    },
    {
        "value": "ZIRA"
    },
    {
        "value": "PATTI"
    },
    {
        "value": "RAIKOT"
    },
    {
        "value": "LONGOWAL"
    },
    {
        "value": "URMAR TANDA"
    },
    {
        "value": "MORINDA, INDIA"
    },
    {
        "value": "PHILLAUR"
    },
    {
        "value": "PATTRAN"
    },
    {
        "value": "QADIAN"
    },
    {
        "value": "SUJANPUR"
    },
    {
        "value": "MUKERIAN"
    },
    {
        "value": "TALWARA"
    },
    {
        "value": "FARIDABAD"
    },
    {
        "value": "GURGAON"
    },
    {
        "value": "HISAR"
    },
    {
        "value": "ROHTAK"
    },
    {
        "value": "PANIPAT"
    },
    {
        "value": "KARNAL"
    },
    {
        "value": "SONIPAT"
    },
    {
        "value": "YAMUNANAGAR"
    },
    {
        "value": "PANCHKULA"
    },
    {
        "value": "BHIWANI"
    },
    {
        "value": "BAHADURGARH"
    },
    {
        "value": "JIND"
    },
    {
        "value": "SIRSA"
    },
    {
        "value": "THANESAR"
    },
    {
        "value": "KAITHAL"
    },
    {
        "value": "PALWAL"
    },
    {
        "value": "REWARI"
    },
    {
        "value": "HANSI"
    },
    {
        "value": "NARNAUL"
    },
    {
        "value": "FATEHABAD"
    },
    {
        "value": "GOHANA"
    },
    {
        "value": "TOHANA"
    },
    {
        "value": "NARWANA"
    },
    {
        "value": "MANDI DABWALI"
    },
    {
        "value": "CHARKHI DADRI"
    },
    {
        "value": "SHAHBAD"
    },
    {
        "value": "PEHOWA"
    },
    {
        "value": "SAMALKHA"
    },
    {
        "value": "PINJORE"
    },
    {
        "value": "LADWA"
    },
    {
        "value": "SOHNA"
    },
    {
        "value": "SAFIDON"
    },
    {
        "value": "TARAORI"
    },
    {
        "value": "MAHENDRAGARH"
    },
    {
        "value": "RATIA"
    },
    {
        "value": "RANIA"
    },
    {
        "value": "SARSOD"
    },
    {
        "value": "SRINAGAR"
    },
    {
        "value": "JAMMU"
    },
    {
        "value": "BARAMULA"
    },
    {
        "value": "ANANTNAG"
    },
    {
        "value": "SOPORE"
    },
    {
        "value": "KATHURBAN AGGLOMERATION"
    },
    {
        "value": "RAJAURI"
    },
    {
        "value": "PUNCH"
    },
    {
        "value": "UDHAMPUR"
    },
    {
        "value": "DHANBAD"
    },
    {
        "value": "RANCHI"
    },
    {
        "value": "JAMSHEDPUR"
    },
    {
        "value": "BOKARO STEEL CITY"
    },
    {
        "value": "DEOGHAR"
    },
    {
        "value": "PHUSRO"
    },
    {
        "value": "ADITYAPUR"
    },
    {
        "value": "HAZARIBAG"
    },
    {
        "value": "GIRIDIH"
    },
    {
        "value": "RAMGARH"
    },
    {
        "value": "JHUMRI TILAIYA"
    },
    {
        "value": "SAUNDA"
    },
    {
        "value": "SAHIBGANJ"
    },
    {
        "value": "MEDININAGAR (DALTONGANJ)"
    },
    {
        "value": "CHAIBASA"
    },
    {
        "value": "CHATRA"
    },
    {
        "value": "GUMIA"
    },
    {
        "value": "DUMKA"
    },
    {
        "value": "MADHUPUR"
    },
    {
        "value": "CHIRKUNDA"
    },
    {
        "value": "PAKAUR"
    },
    {
        "value": "SIMDEGA"
    },
    {
        "value": "MUSABANI"
    },
    {
        "value": "MIHIJAM"
    },
    {
        "value": "PATRATU"
    },
    {
        "value": "LOHARDAGA"
    },
    {
        "value": "TENU DAM-CUM-KATHHARA"
    },
    {
        "value": "RAIPUR"
    },
    {
        "value": "BHILAI NAGAR"
    },
    {
        "value": "KORBA"
    },
    {
        "value": "BILASPUR"
    },
    {
        "value": "DURG"
    },
    {
        "value": "RAJNANDGAON"
    },
    {
        "value": "JAGDALPUR"
    },
    {
        "value": "RAIGARH"
    },
    {
        "value": "AMBIKAPUR"
    },
    {
        "value": "MAHASAMUND"
    },
    {
        "value": "DHAMTARI"
    },
    {
        "value": "CHIRMIRI"
    },
    {
        "value": "BHATAPARA"
    },
    {
        "value": "DALLI-RAJHARA"
    },
    {
        "value": "NAILA JANJGIR"
    },
    {
        "value": "TILDA NEWRA"
    },
    {
        "value": "MUNGELI"
    },
    {
        "value": "MANENDRAGARH"
    },
    {
        "value": "SAKTI"
    },
    {
        "value": "GUWAHATI"
    },
    {
        "value": "SILCHAR"
    },
    {
        "value": "DIBRUGARH"
    },
    {
        "value": "NAGAON"
    },
    {
        "value": "TINSUKIA"
    },
    {
        "value": "JORHAT"
    },
    {
        "value": "BONGAIGAON CITY"
    },
    {
        "value": "DHUBRI"
    },
    {
        "value": "DIPHU"
    },
    {
        "value": "NORTH LAKHIMPUR"
    },
    {
        "value": "TEZPUR"
    },
    {
        "value": "KARIMGANJ"
    },
    {
        "value": "SIBSAGAR"
    },
    {
        "value": "GOALPARA"
    },
    {
        "value": "BARPETA"
    },
    {
        "value": "LANKA"
    },
    {
        "value": "LUMDING"
    },
    {
        "value": "MANKACHAR"
    },
    {
        "value": "NALBARI"
    },
    {
        "value": "RANGIA"
    },
    {
        "value": "MARGHERITA"
    },
    {
        "value": "MANGALDOI"
    },
    {
        "value": "SILAPATHAR"
    },
    {
        "value": "MARIANI"
    },
    {
        "value": "MARIGAON"
    },
    {
        "value": "CHANDIGARH"
    },
    {
        "value": "BHUBANESWAR"
    },
    {
        "value": "CUTTACK"
    },
    {
        "value": "RAURKELA"
    },
    {
        "value": "BRAHMAPUR"
    },
    {
        "value": "SAMBALPUR"
    },
    {
        "value": "PURI"
    },
    {
        "value": "BALESHWAR TOWN"
    },
    {
        "value": "BARIPADA TOWN"
    },
    {
        "value": "BHADRAK"
    },
    {
        "value": "BALANGIR"
    },
    {
        "value": "JHARSUGUDA"
    },
    {
        "value": "BARGARH"
    },
    {
        "value": "PARADIP"
    },
    {
        "value": "BHAWANIPATNA"
    },
    {
        "value": "DHENKANAL"
    },
    {
        "value": "BARBIL"
    },
    {
        "value": "KENDUJHAR"
    },
    {
        "value": "SUNABEDA"
    },
    {
        "value": "RAYAGADA"
    },
    {
        "value": "JATANI"
    },
    {
        "value": "BYASANAGAR"
    },
    {
        "value": "KENDRAPARA"
    },
    {
        "value": "RAJAGANGAPUR"
    },
    {
        "value": "PARLAKHEMUNDI"
    },
    {
        "value": "TALCHER"
    },
    {
        "value": "SUNDARGARH"
    },
    {
        "value": "PHULABANI"
    },
    {
        "value": "PATTAMUNDAI"
    },
    {
        "value": "TITLAGARH"
    },
    {
        "value": "NABARANGAPUR"
    },
    {
        "value": "SORO"
    },
    {
        "value": "MALKANGIRI"
    },
    {
        "value": "RAIRANGPUR"
    },
    {
        "value": "TARBHA"
    },
    {
        "value": "THIRUVANANTHAPURAM"
    },
    {
        "value": "KOCHI"
    },
    {
        "value": "KOZHIKODE"
    },
    {
        "value": "KOLLAM"
    },
    {
        "value": "THRISSUR"
    },
    {
        "value": "PALAKKAD"
    },
    {
        "value": "ALAPPUZHA"
    },
    {
        "value": "MALAPPURAM"
    },
    {
        "value": "PONNANI"
    },
    {
        "value": "VATAKARA"
    },
    {
        "value": "KANHANGAD"
    },
    {
        "value": "TALIPARAMBA"
    },
    {
        "value": "KOYILANDY"
    },
    {
        "value": "NEYYATTINKARA"
    },
    {
        "value": "KAYAMKULAM"
    },
    {
        "value": "NEDUMANGAD"
    },
    {
        "value": "KANNUR"
    },
    {
        "value": "TIRUR"
    },
    {
        "value": "KOTTAYAM"
    },
    {
        "value": "KASARAGOD"
    },
    {
        "value": "KUNNAMKULAM"
    },
    {
        "value": "OTTAPPALAM"
    },
    {
        "value": "THIRUVALLA"
    },
    {
        "value": "THODUPUZHA"
    },
    {
        "value": "CHALAKUDY"
    },
    {
        "value": "CHANGANASSERY"
    },
    {
        "value": "PUNALUR"
    },
    {
        "value": "NILAMBUR"
    },
    {
        "value": "CHERTHALA"
    },
    {
        "value": "PERINTHALMANNA"
    },
    {
        "value": "MATTANNUR"
    },
    {
        "value": "SHORANUR"
    },
    {
        "value": "VARKALA"
    },
    {
        "value": "PARAVOOR"
    },
    {
        "value": "PATHANAMTHITTA"
    },
    {
        "value": "PERINGATHUR"
    },
    {
        "value": "ATTINGAL"
    },
    {
        "value": "KODUNGALLUR"
    },
    {
        "value": "PAPPINISSERI"
    },
    {
        "value": "CHITTUR-THATHAMANGALAM"
    },
    {
        "value": "MUVATTUPUZHA"
    },
    {
        "value": "ADOOR"
    },
    {
        "value": "MAVELIKKARA"
    },
    {
        "value": "MAVOOR"
    },
    {
        "value": "PERUMBAVOOR"
    },
    {
        "value": "VAIKOM"
    },
    {
        "value": "PALAI"
    },
    {
        "value": "PANNIYANNUR"
    },
    {
        "value": "GURUVAYOOR"
    },
    {
        "value": "PUTHUPPALLY"
    },
    {
        "value": "PANAMATTOM"
    },
    {
        "value": "DEHRADUN"
    },
    {
        "value": "HARDWAR"
    },
    {
        "value": "HALDWANI-CUM-KATHGODAM"
    },
    {
        "value": "SRINAGAR"
    },
    {
        "value": "KASHIPUR"
    },
    {
        "value": "ROORKEE"
    },
    {
        "value": "RUDRAPUR"
    },
    {
        "value": "RISHIKESH"
    },
    {
        "value": "RAMNAGAR"
    },
    {
        "value": "PITHORAGARH"
    },
    {
        "value": "MANGLAUR"
    },
    {
        "value": "NAINITAL"
    },
    {
        "value": "MUSSOORIE"
    },
    {
        "value": "TEHRI"
    },
    {
        "value": "PAURI"
    },
    {
        "value": "NAGLA"
    },
    {
        "value": "SITARGANJ"
    },
    {
        "value": "BAGESHWAR"
    },
    {
        "value": "PONDICHERRY"
    },
    {
        "value": "KARAIKAL"
    },
    {
        "value": "YANAM"
    },
    {
        "value": "MAHE"
    },
    {
        "value": "AGARTALA"
    },
    {
        "value": "UDAIPUR"
    },
    {
        "value": "DHARMANAGAR"
    },
    {
        "value": "PRATAPGARH"
    },
    {
        "value": "KAILASAHAR"
    },
    {
        "value": "BELONIA"
    },
    {
        "value": "KHOWAI"
    },
    {
        "value": "MYSORE"
    },
    {
        "value": "AIZAWL"
    },
    {
        "value": "LUNGLEI"
    },
    {
        "value": "SAIHA"
    },
    {
        "value": "SHILLONG"
    },
    {
        "value": "TURA"
    },
    {
        "value": "NONGSTOIN"
    },
    {
        "value": "IMPHAL"
    },
    {
        "value": "THOUBAL"
    },
    {
        "value": "LILONG"
    },
    {
        "value": "MAYANG IMPHAL"
    },
    {
        "value": "SHIMLA"
    },
    {
        "value": "MANDI"
    },
    {
        "value": "SOLAN"
    },
    {
        "value": "NAHAN"
    },
    {
        "value": "SUNDARNAGAR"
    },
    {
        "value": "PALAMPUR"
    },
    {
        "value": "DIMAPUR"
    },
    {
        "value": "KOHIMA"
    },
    {
        "value": "ZUNHEBOTO"
    },
    {
        "value": "TUENSANG"
    },
    {
        "value": "WOKHA"
    },
    {
        "value": "MOKOKCHUNG"
    },
    {
        "value": "MARMAGAO"
    },
    {
        "value": "PANAJI"
    },
    {
        "value": "MARGAO"
    },
    {
        "value": "MAPUSA"
    },
    {
        "value": "PORT BLAIR"
    },
    {
        "value": "NAHARLAGUN"
    },
    {
        "value": "PASIGHAT"
    },
    {
        "value": "SILVASSA"
    }
]