import { Box, Button, Modal, Stack } from '@mui/material'
import React, { useState } from 'react'
import { planDuration, typeOFClient } from '../Data';
import { siteVistToOther } from './api';
import { notify, notifyFailed } from '../Common/Notification';
import { subHistory } from '../Function/SubscriptionHistory';
import { pages } from '../ApiUrl';
import { siteVistcreateds } from '../Address&Layout/SiteVisitCreated';
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  maxWidth: "90%",
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: "12px"
};
function ConvertSiteVisitToOther({ sub, reload, setReload,page="" }) {
  const [selectedButton, setSelectedButton] = useState("")
  const [open, setOpen] = React.useState(false);
  const [data, setData] = useState({
    app: 0,
    installation: 0,
    sequrity: 0
  })
  const [capexData, setCapexData] = useState({
    totalAppliances: 0,
    perApplianceCost: 0,
    currentInstallation: 0
  })
  const handleClose = () => {
    setOpen(false)
  };
  const handleAppliances = (event) => {
    let app = Number(event.target.value)
    let seq = app * 100
    let installation = 0
    if (app <= 10) {
      installation = 1000
    } else {
      let cal = app - 10;
      installation = 1000 + (cal * 50);
    }
    setData({
      app: app,
      sequrity: seq,
      installation: installation
    })
  }
  const handleSubscription = async (event) => {
    event.preventDefault();
    const datas = new FormData(event.currentTarget);

    let detail = {
      "totalAppliances": data.app,
      "planFrequency": datas.get('planduration'),
      "currentSecurity": data.sequrity,
      "currentInstallation": data.installation
    }
    console.log(detail);
    let res = await siteVistToOther(selectedButton, sub, detail)
    if (res.res !== undefined) {
      if (res.res.data.success) {
        notify(res.res.data.message)
        setData({
          app: 0,
          installation: 0,
          sequrity: 0
        })
        subHistory('site visit converted to subscription',sub,'Site visit converted',sub.InstallationDate)
        if(sub.siteVisiteByElectrician){
          let res = await siteVistcreateds(data)
          if(res.res!==undefined){
            if(res.res.data.success){
              subHistory(`${sub.siteVisiteByElectrician?"Site visite revoke":"Site visite set"}`,sub,"Site visite",sub.InstallationDate)
            }
          }
        }
        setReload(!reload)
        handleClose()
        if(page===pages.elctrician){
          subHistory("Site Visit Done By Elecetrician",{...sub},"Site Visit Completed",res.data.message)
          window.location.reload();
        }
      } else {
        notifyFailed(res.res.data.message)
      }

    } else if (res.err !== undefined) {
      notifyFailed(res.err.response.data.message)
    }
  }
  const handleCapex = async (event) => {
    event.preventDefault();
    const datas = new FormData(event.currentTarget);


    console.log(capexData);
    let res = await siteVistToOther(typeOFClient[1].value, sub, capexData)
    if (res.res !== undefined) {
      if (res.res.data.success) {
        notify(res.res.data.message)
        setCapexData({
          totalAppliances: 0,
          perApplianceCost: 0,
          currentInstallation: 0
        })
        
        subHistory('site visit converted to capex',sub,'Site visit converted',sub.InstallationDate)
        if(sub.siteVisiteByElectrician){
          let res = await siteVistcreateds(data)
          if(res.res!==undefined){
            if(res.res.data.success){
              subHistory(`${sub.siteVisiteByElectrician?"Site visite revoke":"Site visite set"}`,sub,"Site visite",sub.InstallationDate)
            }
          }
        }
        setReload(!reload)
        handleClose()
        if(page===pages.elctrician){
          subHistory("Site Visit Done By Elecetrician",sub,"Site Visit Completed",res.res.data.message)
          window.location.reload();
        }
      } else {
        notifyFailed(res.res.data.message)
      }

    } else if (res.err !== undefined) {
      notifyFailed(res.err.response.data.message)
    }
  }

  return (
    <>
      <Button
        variant='contained'
        size='small'
        onClick={() => setOpen(true)}
      >Change to Subscription/Capex</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Stack>
            <div className='row'>
              <div className='col-md-12 col-sm-12'>
                 <label>Type of Order</label>
                 <select
                   className='form-control'
                   onChange={(e)=>{
                       setSelectedButton(e.target.value)
                   }}
                 >
                  <option >Select Order Type</option>
                  <option value={"subscription"}>Subscription</option>
                  <option value={'FreeClient'}>Free Client</option>
                  <option value={"Capex"}>Capex</option>
                 </select>
              </div>
            </div>
            {/* <Button
              variant="contained"
              onClick={() => setSelectedButton("Subscription")}
            >Subscription
            </Button>
            <Button
              variant="contained"
              color='primary'
              onClick={() => setSelectedButton("Capex")}>Capex</Button> */}
          </Stack>
          {
            (selectedButton === "subscription" || selectedButton === "FreeClient") && (
              <Stack mt={3}>
                <form onSubmit={handleSubscription}>


                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <div>
                        <label>Total Appliance</label>
                        <input
                          className='form-control'
                          name='appliances'
                          type='number'
                          value={data.app}
                          onChange={handleAppliances}
                        />
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <div>
                        <label>Plan Duration</label>
                        <select
                          className='form-control'
                          name='planduration'
                          autoComplete='current-planduration'
                        >
                          {
                            planDuration.map(plan => (
                              <option value={plan.value}>{plan.value}</option>
                            ))
                          }

                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <div>
                        <label>Installation</label>
                        <input
                          className='form-control'
                          name='installation'
                          type='number'
                          value={data.installation}
                          onChange={(e) => {
                            setData({
                              ...data,
                              installation: Number(e.target.value),
                              
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <div>
                        <label>Security</label>
                        <input
                          className='form-control'
                          name='installation'
                          type='number'
                          value={data.sequrity}

                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-3 mt-2'>
                      <Button
                        type='submit'
                        variant='contained'
                      >Submit</Button>
                    </div>
                  </div>
                </form>
              </Stack>
            )
          }
          {
            selectedButton === "Capex" && (
              <Stack mt={3}>
                <form onSubmit={handleCapex}>


                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <div>
                        <label>Total Appliance</label>
                        <input
                          className='form-control'
                          name='appliances'
                          type='number'
                          value={capexData.totalAppliances}
                          onChange={(e) => {
                            setCapexData({
                              ...capexData,
                              totalAppliances: Number(e.target.value)
                            })
                          }}
                        />
                      </div>
                    </div>
                    <div className='col-md-6 col-sm-12'>
                      <div>
                        <label>Cost Per Appliance</label>
                        <input
                          type='number'
                          className='form-control'
                          value={capexData.perApplianceCost}
                          onChange={(e) => {
                            setCapexData({
                              ...capexData,
                              perApplianceCost: Number(e.target.value)
                            })
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6 col-sm-12'>
                      <div>
                        <label>Installation</label>
                        <input
                          className='form-control'
                          name='installation'
                          type='number'
                          value={capexData.currentInstallation}
                          onChange={(e) => {
                            setCapexData({
                              ...capexData,
                              currentInstallation: Number(e.target.value)
                            })
                          }}
                        />
                      </div>
                    </div>

                  </div>
                  <div className='row'>
                    <div className='col-3 mt-2'>
                      <Button
                        type='submit'
                        variant='contained'
                      >Submit</Button>
                    </div>
                  </div>
                </form>
              </Stack>
            )
          }
        </Box>
      </Modal>
    </>
  )
}

export default ConvertSiteVisitToOther