import React, { useEffect, useState, useRef } from 'react'
import axios from 'axios'
import '../../ConvertedLead.css'
import '../../DispatchData.css'
import '../../PaymentLinkGen.css'
import '../../PartialPayment.css'
import Qutoes from '../../Qutoes'
import { serverUrl } from '../../ApiUrl'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import { useSelector } from 'react-redux'
import DonwloadInvoiceCsv from '../../Invoice/DonwloadInvoiceCsv'
import { CSVLink, CSVDownload } from "react-csv";
import {updateAllInvoiceStatus,getSubscriptionInvoice, salesUserData} from '../../Function/AxiosControl'
import InvoiceCommentUpdate from '../../Function/InvoiceCommentUpdate'
import { ToastContainer, toast } from 'react-toastify';
import { updateStartMakingPayment, getInvoiceDateWise } from '../../Function/AxiosControl'
import { state,InvoiceStatus, user_roles } from '../../Data'
import { InvoiceGenerate } from '../../Function/InvoieDownloadFun'
import SubscriptionPause from '../../Function/SubscriptionPause'
import MaterAuditCancel from '../../SmallComponent/MaterAuditCancel'
import ChangeInstallationDate from '../../Function/ChangeInstallationDate'
import Spinner from '../../Function/Spinner'
import UpdateSingleInvoice from '../../Invoice/UpdateSingleInvoice'
import InvoiceDelete from '../../Function/InvoiceDelete'
import FileSaver from 'file-saver'
import { async } from 'q'
import { Button } from '@mui/material'
import {notify} from '../../Common/Notification'

import { subHistory } from '../../Function/SubscriptionHistory'
import InvoiceSend from '../../Invoice/InvoiceSend'
import JSZip from 'jszip'

export default function Invoice() {
    const [searchParam] = useState(["name"]);
    const searchValue = useRef(null)
    let [q, setq] = useState("");
    let employeeId = useSelector((state) => state.sar.data.user.employeeId)
    let firstName = useSelector(state=>state.sar.data.user.first_name)
    let userRoles = useSelector((state) => state.sar.data.user.roles)
    const [invoices,setInvoices] = useState([])
    let [reloadW, setReload] = useState(false);

    const fetchInvoices = ()=>{
        axios.post(`${serverUrl.u}/app/tele/getIspInvoice`,{
          teleCallerId: userRoles.includes(user_roles.Isp)?employeeId:firstName
        }).then(res=>{
            console.log(res);
            if(res.data.success===true){
                setInvoices(res.data.data);
            }else{
                setInvoices([])
            }
        }).catch(function (err) {
            console.log(err);
            setInvoices([])
        })
    }
    useEffect(() =>{
        fetchInvoices()
    },[])

    const InvoucdcolorDecide = (text) => {
        if (text === "overdue") {
          return "rgb(251, 37, 118)"
        }
        if (text === "paid") {
          return "green"
        }
        if (text === "sent") {
          return "rgb(51, 47, 208)"
        }
      }

      const ActivateHouse = async(id,e)=>{
        await axios.post(`${serverUrl.u}/v2/house/activate`,{
          houseId:id,
        }).then(function (res) {
          console.log(res);
          notify(res.data.message)
          let newDate = new Date()
          subHistory("House Activated Invoice",e,"House Active/Deactive","House Activated Invoice Page")
          setReload(!reloadW)
        }).catch(function (err) {
          console.log(err);
          notify("Error")
        })
     }

     const DectivateHouse = async(id,e)=>{
        await axios.post(`${serverUrl.u}/v2/house/deactivate`,{
          houseId:id,
        }).then(function (res) {
          console.log("Deactate",res);
          notify(res.data.message)
          let newDate = new Date()
          subHistory("House Deactivated Invoice",e,"House Active/Deactive","House Deactivated Invoice Page")
          setReload(!reloadW)
        }).catch(function (err) {
          console.log(err);
          notify("Error")
        })
      }
      const InvoiceGenerated = async(e)=>{
        let res = await InvoiceGenerate(e,e,notify)
        console.log(res,"invoice");
        if (res[0] === "then") {
          if (res[1].status === 200) {
            console.log(e.is_UpSell, "errfd");
            if (!e.is_UpSell) {
              const zip = new JSZip();
              const zipContent = await zip.loadAsync(res[1].data);
              for (let file in zipContent?.files) {
                // Extract the PDF file
                const pdfFile = zipContent?.files[file];
                if (pdfFile) {
                  const pdfBlob = await pdfFile.async("blob");
                  FileSaver.saveAs(pdfBlob, `${e.name} - invoice.pdf`);
                } else {
                  console.error("PDF file not found in the ZIP archive.");
                }
              }
            } else {
              // Create a Blob from the response data (PDF)
              const blob = new Blob([res[1].data], { type: "application/pdf" });
    
              // Use file-saver to download the file
              FileSaver.saveAs(blob, `${e.name} - invoice.pdf`);
            }
          } else {
            notify("Failed");
          }
        }else{
         notify("Failed")
       }
    
    
    }
    function search(items) {
        console.log(items,searchParam,q,"dd");
        if(searchValue?.current?.value==="") return items
        let f= items.filter((item) => {
            if(item.name.toLowerCase().indexOf(q.toLowerCase()) > -1){
              return item
            }
        });
       
        return f
      }

  return (
    <div  style={{ padding: "0 4%", marginTop: "4vh" }}>
        <div className='table-container1' style={{marginTop:"4px"}}>
        <table className='table table-sm table-bordered'>
          <thead>
            <tr >
              <th>Sr. No.</th>
              <th scope="col">Invoice No</th>
              <th scope="col" >Name</th>
              <th>Drop Status</th>
              <th>Phone</th>
              <th>TeleCaller</th>
              <th>City</th>
              <th>Status</th>
              
            
              
              <th>Comments<span style={{ color: "red" }}>*</span></th>
              <th>Download</th>
              <th style={{width:"140px"}}>Invoice</th>
              <th style={{width:"130px"}}>Invoice Url</th>
              
              <th>Is First Invoice</th>
              <th>UpSell</th>
              <th>Amount</th>
            
              <th>Zoho Invoice No</th>
              
              <th>Create Time</th>
              <th>Mandate Date</th>
              <th>CreatedAt</th>
              <th style={{width:"120px"}}>Installation Date<span style={{ color: "red" }}>*</span></th>
              <th>Hex Code</th>
              <th style={{width:"180px"}}>houseId</th>
              <th style={{width:"160px"}}>Activate/Deactivate Status</th>
              <th style={{width:"150px"}}>Activate/Deactivate</th>
              <th style={{width:"160px"}}>Subscription Id</th>
              
            </tr>
          </thead>
          <tbody>
            {
              invoices.length  !== 0 ?
                invoices.map((e, i) => {
                  return (

                    <tr key={i} style={{backgroundColor:e.problem?"rgb(235 85 82 / 30%)":""}}>
                      <td>{(i+1)}</td>
                      <td><div>{e.invoiceNumber>7975?e.invoiceNumber-7975:(e.invoiceNumber>1200?e.invoiceNumber-1200:e.invoiceNumber)}</div></td>
                      <td className="pr-15 pl-55" style={{fontWeight:"bold"}}>{e.name} {e.B2BRoom_No}</td>
                      <td>{e.Dropped_Client}</td>
                      <td>{e.phone}</td>
                      <td>{e.teleCallerId}</td>
                      <td>{e.city}</td>
                      <td style={{color:InvoucdcolorDecide(e.status)}}>{e.status}</td>
                      
                      <InvoiceCommentUpdate e={e} reload={reloadW} setReload={setReload} notify={notify}/>
                      <td><button className='btn btn-primary' onClick={()=>InvoiceGenerated(e)}>Invoice</button></td>
                      {/* <td>{(e.hasOwnProperty("email")===true && e.email!=="")?<InvoiceSend inv={e} sub={e} notify={notify}/>:"E-Mail Not Found/Register" }</td> */}
                      <td><button className='btn btn-primary' onClick={()=>navigator.clipboard.writeText(e.invoiceUrl)}>Copy</button></td>
                      
                      <td>{e.isFirstInvoice === true ? "Yes" : "No"}</td>
                      <td>{e.is_UpSell===true?"Yes UP Sell":"NO"}</td>
                      <td>{e.amount}</td>
                      <td>{e.zohoInvoiceNumber}</td>
                      <td>{moment(e.createdTime).format('MMM Do YYYY')}</td>
                      <td>{moment(e.mandateDate).format('MMM Do YYYY')}</td>
                      <td>{moment(e.createdAt).format("MMM Do YYYY")}</td>
                      <ChangeInstallationDate e={e} reload={reloadW} setReload={setReload} notify={notify} decision={"td"}/>
                  
                     
                      <td>{e.generatedHex}</td>
                      <td>{e.houseId}</td>
                      <td>{e.active?"Yes":"No"}</td>
                      {
                      e.houseId!==""?
                      <>
                      { 
                      e.active===false ?
                      <td><button className="btn btn-primary" onClick={()=>ActivateHouse(e.houseId,e)}>Activate</button></td>:
                      <td><button className="btn btn-primary" onClick={()=>DectivateHouse(e.houseId,e)}>Deactivate</button></td>
                      }
                      </>:<td></td>
                    }
                    <td>{e.subscriptionId}</td>
                    </tr>
                  )
                }) : null
            }
          </tbody>
        </table>
      </div>
    </div>
  )
}
