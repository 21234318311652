import React from "react";
import AddressUpdate from "../Function/AddressUpdate";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { pages, serverUrl } from "../ApiUrl";
import GreivanceResolveDep from "./GreivanceResolveDep";
import GrevianceCommentDep from "./GrevianceCommentDep";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import ShowDevices from "./ShowDevices";
import { useSelector } from "react-redux";
import ChangeGrivanceHandler from "./ChangeGrivanceHandler";
import GrivanceHistory from "./GrivanceHistory";
import LastGrivanceElectrician from "./LastGrivanceElectrician";
import Deleteanddrop from "../Function/Deleteanddrop";
import AlreadyDroped from "./AlreadyDroped";
import SubscriptionZoneUpdate from "../SpecificElectricianUpGradeDownGrade.js/SubscriptionZoneUpdate";
import { Button } from "@mui/material";
import LastTenActionOnGrievence from "./LastTenActionOnGrievence";
import UninstallDoneBtn from "../SpecificElectricianUpGradeDownGrade.js/UninstallDoneBtn";
import { GrievanceHandler } from "../Data";
import { CSVLink } from "react-csv";
export default function GrivanceDepartment() {
  let [displayData, setDisplayData] = useState([]);
  let [daysGrivanceData, setDaysGrivanceData] = useState([]);
  let [csvData, setCsvData] = useState([]);
  const [searchParam] = useState(["phone"]);
  let [reloadW, setReload] = useState(false);
  let [q, setq] = useState("");
  let loginName = useSelector((state) => state.sar.data.user.first_name);
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
  let [months, setMonths] = useState([]);

  const notify = (text) => toast(text);
  useEffect(() => {
    if (
      userRole?.includes("Super Admin") ||
      loginName === GrievanceHandler[1] ||
      loginName === GrievanceHandler[2]
    ) {
      axios
        .post(`${serverUrl.u}/api/tele/getAllGrievanceForHod`)
        .then(function (res) {
          if (res.data.success === true) {
            let sorted = res.data.data.sort(
              (a, b) =>
                new Date(b.InstallationDate) - new Date(a.InstallationDate)
            );
            let month = [];
            for (let i of sorted) {
              if (
                !month.includes(
                  moment.utc(i.InstallationDate).format("MMM-YYYY")
                )
              ) {
                month.push(moment.utc(i.InstallationDate).format("MMM-YYYY"));
              }
            }
            setMonths(month);
            setDisplayData(sorted);
            setCsvData(
              sorted
                .map((e) => {
                  if (
                    loginName === GrievanceHandler[1] ||
                    loginName === GrievanceHandler[2]
                  ) {
                    if (e.grivienceHandler !== GrievanceHandler[0])
                      return {
                        houseId: e.houseId,
                        generatedHex: e.generatedHex,
                        teleCallerId: e.teleCallerId,
                        phone: `"" ${e.phone}`,
                        name: e.name,
                        city: e.city,
                        address: e.address,
                        totalAppliances: JSON.stringify(e.totalAppliances),
                        secondarySalesPerson: e.secondarySalesPerson,
                        planFrequency: e.planFrequency,
                        InstallationDate: moment(e.InstallationDate).format(
                          "DD-MM-YYYY, LT"
                        ),
                      };
                  } else if (e.grivienceHandler === loginName) {
                    return {
                      houseId: e.houseId,
                      generatedHex: e.generatedHex,
                      teleCallerId: e.teleCallerId,
                      phone: `"" ${e.phone}`,
                      name: e.name,
                      city: e.city,
                      address: e.address,
                      totalAppliances: e.totalAppliances,
                      secondarySalesPerson: e.secondarySalesPerson,
                      planFrequency: e.planFrequency,
                      InstallationDate: moment(e.InstallationDate).format(
                        "DD-MM-YYYY, LT"
                      ),
                    };
                  } else {
                    return {
                      houseId: e.houseId,
                      generatedHex: e.generatedHex,
                      teleCallerId: e.teleCallerId,
                      phone: `"" ${e.phone}`,
                      name: e.name,
                      city: e.city,
                      address: e.address,
                      totalAppliances: e.totalAppliances,
                      secondarySalesPerson: e.secondarySalesPerson,
                      planFrequency: e.planFrequency,
                      InstallationDate: moment(e.InstallationDate).format(
                        "DD-MM-YYYY, LT"
                      ),
                    };
                  }
                })
                .filter((item) => item !== undefined)
                .map((e) => {
                  if (
                    loginName === GrievanceHandler[1] ||
                    loginName === GrievanceHandler[2]
                  ) {
                    if (e.grivienceHandler !== GrievanceHandler[0])
                      return {
                        houseId: e.houseId,
                        generatedHex: e.generatedHex,
                        teleCallerId: e.teleCallerId,
                        phone: `"" ${e.phone}`,
                        name: e.name,
                        city: e.city,
                        address: e.address,
                        totalAppliances: JSON.stringify(e.totalAppliances),
                        secondarySalesPerson: e.secondarySalesPerson,
                        planFrequency: e.planFrequency,
                        InstallationDate: moment(e.InstallationDate).format(
                          "DD-MM-YYYY, LT"
                        ),
                      };
                  } else if (e.grivienceHandler === loginName) {
                    return {
                      houseId: e.houseId,
                      generatedHex: e.generatedHex,
                      teleCallerId: e.teleCallerId,
                      phone: `"" ${e.phone}`,
                      name: e.name,
                      city: e.city,
                      address: e.address,
                      totalAppliances: e.totalAppliances,
                      secondarySalesPerson: e.secondarySalesPerson,
                      planFrequency: e.planFrequency,
                      InstallationDate: moment(e.InstallationDate).format(
                        "DD-MM-YYYY, LT"
                      ),
                    };
                  } else {
                    return {
                      houseId: e.houseId,
                      generatedHex: e.generatedHex,
                      teleCallerId: e.teleCallerId,
                      phone: `"" ${e.phone}`,
                      name: e.name,
                      city: e.city,
                      address: e.address,
                      totalAppliances: e.totalAppliances,
                      secondarySalesPerson: e.secondarySalesPerson,
                      planFrequency: e.planFrequency,
                      InstallationDate: moment(e.InstallationDate).format(
                        "DD-MM-YYYY, LT"
                      ),
                    };
                  }
                })
                .filter((item) => item !== undefined)
            );
          } else {
            notify("Failed");
          }
        })
        .catch(function (err) {
          console.log(err);
          notify("Failed");
        });
      axios
        .get(`${serverUrl.u}/api/grv/getMaxGrievanceClient`)
        .then(function (res) {
          if (res.data.success === true) {
            setDaysGrivanceData(res.data.data);
          }
        })
        .catch(function (err) {
          console.log(err);
        });
    } else {
      axios
        .post(`${serverUrl.u}/api/grv/showGrivienceSiteHandler`, {
          handler: loginName,
        })
        .then(function (res) {
          if (res.data.success === true) {
            let sorted = res.data.data.sort(
              (a, b) =>
                new Date(b.InstallationDate) - new Date(a.InstallationDate)
            );
            setDisplayData(sorted);
            let month = [];
            for (let i of sorted) {
              if (
                !month.includes(
                  moment.utc(i.InstallationDate).format("MMM-YYYY")
                )
              ) {
                month.push(moment.utc(i.InstallationDate).format("MMM-YYYY"));
              }
            }
            setMonths(month);
            setCsvData(sorted.map((e) => {
              if (
                loginName === GrievanceHandler[1] ||
                loginName === GrievanceHandler[2]
              ) {
                if (e.grivienceHandler !== GrievanceHandler[0]) return {houseId:e.houseId,generatedHex:e.generatedHex,teleCallerId:e.teleCallerId,phone:`"" ${e.phone}`,name:e.name,city:e.city,address:e.address,totalAppliances:JSON.stringify(e.totalAppliances),secondarySalesPerson:e.secondarySalesPerson,planFrequency:e.planFrequency,InstallationDate:moment(e.InstallationDate).format('DD-MM-YYYY, LT')};
              } else if (e.grivienceHandler === loginName) {
                return {houseId:e.houseId,generatedHex:e.generatedHex,teleCallerId:e.teleCallerId,phone:`"" ${e.phone}`,name:e.name,city:e.city,address:e.address,totalAppliances:e.totalAppliances,secondarySalesPerson:e.secondarySalesPerson,planFrequency:e.planFrequency,InstallationDate:moment(e.InstallationDate).format('DD-MM-YYYY, LT')};
              } else {
                return {houseId:e.houseId,generatedHex:e.generatedHex,teleCallerId:e.teleCallerId,phone:`"" ${e.phone}`,name:e.name,city:e.city,address:e.address,totalAppliances:e.totalAppliances,secondarySalesPerson:e.secondarySalesPerson,planFrequency:e.planFrequency,InstallationDate:moment(e.InstallationDate).format('DD-MM-YYYY, LT')};
              }
            }).filter((item) => item !== undefined));
          } else {
            notify("Failed");
          }
        })
        .catch(function (err) {
          console.log(err);
          notify("Failed");
        });
    }
  }, [reloadW]);

  function search(items) {
    return items.filter((item) => {
      return searchParam.some((newItem) => {
        return item[newItem].indexOf(q.toLowerCase()) > -1;
      });
    });
  }
  const handleReview = (hex) => {
    axios
      .post(`${serverUrl.u}/api/grv/reviewGrievance`, {
        generatedHex: hex,
      })
      .then(function (res) {
        if (res.data.success === true) {
          setReload(!reloadW);
        } else {
          notify("Failed");
        }
      })
      .catch(function (err) {
        console.log(err);
        notify("Failed");
      });
  };
  const styles = {
    background: "#FFFFFF",
    border: "none",
    borderRadius: "2px",
    boxShadow: "0 2px 10px 0 rgba(70, 76, 79, .2)",
  };
  const csvDa = async () => {
    let male = await csvData
      .map((e) => {
        if (
          loginName === GrievanceHandler[1] ||
          loginName === GrievanceHandler[2]
        ) {
          if (e.grivienceHandler !== GrievanceHandler[0])
            return {
              houseId: e.houseId,
              generatedHex: e.generatedHex,
              teleCallerId: e.teleCallerId,
              phone: `"" ${e.phone}`,
              name: e.name,
              city: e.city,
              address: e.address,
              totalAppliances: JSON.stringify(e.totalAppliances),
              secondarySalesPerson: e.secondarySalesPerson,
              planFrequency: e.planFrequency,
              InstallationDate: moment(e.InstallationDate).format(
                "DD-MM-YYYY, LT"
              ),
            };
        } else if (e.grivienceHandler === loginName) {
          return {
            houseId: e.houseId,
            generatedHex: e.generatedHex,
            teleCallerId: e.teleCallerId,
            phone: `"" ${e.phone}`,
            name: e.name,
            city: e.city,
            address: e.address,
            totalAppliances: e.totalAppliances,
            secondarySalesPerson: e.secondarySalesPerson,
            planFrequency: e.planFrequency,
            InstallationDate: moment(e.InstallationDate).format(
              "DD-MM-YYYY, LT"
            ),
          };
        } else {
          return {
            houseId: e.houseId,
            generatedHex: e.generatedHex,
            teleCallerId: e.teleCallerId,
            phone: `"" ${e.phone}`,
            name: e.name,
            city: e.city,
            address: e.address,
            totalAppliances: e.totalAppliances,
            secondarySalesPerson: e.secondarySalesPerson,
            planFrequency: e.planFrequency,
            InstallationDate: moment(e.InstallationDate).format(
              "DD-MM-YYYY, LT"
            ),
          };
        }
      })
      .filter((item) => item !== undefined);
    console.log("====================================");
    console.log(male);
    console.log("====================================");
    return male;
  };

  return (
    <div style={{ width: "100%", padding: "0 4%", marginTop: "5vh" }}>
      <ToastContainer />
      <div className="input-field mb-3">
        <input
          type="text"
          value={q}
          placeholder="Search here phone number...."
          onChange={(e) => setq(e.target.value)}
        />
      </div>
      <div className="d-flex justify-content-between">
        <div style={{ marginBottom: "20px" }}>{`Total Number of Greivance :- ${
          displayData.filter((e) => {
            if (
              loginName === GrievanceHandler[1] ||
              loginName === GrievanceHandler[2]
            ) {
              if (e.grivienceHandler !== GrievanceHandler[0]) return e;
            } else if (e.grivienceHandler === loginName) {
              return e;
            } else {
              return e;
            }
          }).length
        }`}</div>
        <CSVLink
          filename="grivence.csv"
          className="btn btn-primary"
          data={csvData}
        >
          Download
        </CSVLink>
      </div>
      {months.map((month) => {
        return (
          <>
            <div>{month}</div>
            <div className="table-container1" style={{ margin: 0 }}>
              <table className="table table-sm table-bordered">
                <thead>
                  <tr>
                    <th>Sr. No.</th>
                    <th scope="col">
                      Name <span style={{ color: "red" }}>*</span>
                    </th>
                    {userRole?.includes("Super Admin") ? (
                      <th>Grivience Handler</th>
                    ) : null}
                    {/* <th>Dropped</th> */}
                    <th>
                      Grivience Handler <span style={{ color: "red" }}>*</span>
                    </th>
                    {/* <th>Priority</th> */}
                    <th>Raised By</th>
                    <th>Pending Days</th>
                    <th>Electrician Comment</th>
                    <th>Total Grivience</th>
                    <th scope="col">Telecaller Name</th>
                    <th scope="col">City</th>
                    <th>
                      Address<span style={{ color: "red" }}>*</span>
                    </th>
                    <th scope="col">Phone</th>
                    <th>Electrician</th>
                    {loginName === "Heena" ||
                    userRole?.includes("Super Admin") ? (
                      <>
                        <th>Uninstall Comment</th>
                        <th style={{ width: "190px" }}>UnInstall Action</th>
                        <th style={{ width: "170px" }}>Action</th>
                      </>
                    ) : null}
                    <th>Telecaller Comment</th>
                    <th>
                      Comment<span style={{ color: "red" }}>*</span>
                    </th>
                    <th style={{ width: "150px" }}>Greivance Type</th>
                    <th>Greivance Date</th>
                    <th>Payment</th>
                    <th scope="col">Appliances</th>
                    <th scope="col">Selected Plan</th>
                    <th>Last Grievance Electrician</th>
                    <th style={{ width: "150px" }}>Devices</th>
                    <th>Zone</th>
                    <th style={{ width: "150px" }}>History</th>
                    <th style={{ width: "150px" }}>Last Actions</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {displayData !== 0
                    ? search(displayData)
                        .filter(
                          (e) =>
                            moment
                              .utc(e.InstallationDate)
                              .format("MMM-YYYY") === month
                        )
                        .filter((e) => {
                          if (
                            loginName === GrievanceHandler[1] ||
                            loginName === GrievanceHandler[2]
                          ) {
                            if (e.grivienceHandler !== GrievanceHandler[0])
                              return e;
                          } else if (e.grivienceHandler === loginName) {
                            return e;
                          } else {
                            return e;
                          }
                        })
                        .map((e, i) => {
                          return (
                            <tr
                              key={i}
                              style={{
                                backgroundColor:
                                  e.grievancePriority === true
                                    ? "#ff000082"
                                    : "",
                              }}
                            >
                              <td>{i + 1}</td>

                              <td
                                className="pr-15 pl-55"
                                style={{
                                  fontWeight: "bold",
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(e.generatedHex);
                                }}
                              >
                                {e.name}
                              </td>
                              {userRole?.includes("Super Admin") ? (
                                <td>{e.grivienceHandler}</td>
                              ) : null}
                              {/* <td>{e.dropped===true?"Yes":"No"}</td> */}
                              <ChangeGrivanceHandler
                                e={e}
                                notify={notify}
                                reload={reloadW}
                                setReload={setReload}
                              />
                              {/* <ChangeGrivancePriorty e={e}/> */}
                              <td>{e.raisedBy}</td>
                              <td>{Math.round(e.unresolvedDays)}</td>
                              <td>{e.installerComments}</td>
                              <td>{e.grivienceNumber}</td>
                              <td>{e.teleCallerId}</td>
                              <td>{e.city}</td>
                              <AddressUpdate
                                e={e}
                                setReload={setReload}
                                reloadW={reloadW}
                                loginName={loginName}
                              />
                              <td>
                                <a href={`tel:${e.phone}`}>{e.phone}</a>
                              </td>
                              <td>
                                <div className="hover-wide">
                                  {/* {JSON.stringify(
                                                  e.AssignedElectritianList
                                                )} */}
                                  <ul
                                    style={{
                                      paddingLeft: "10px",
                                      marginBottom: "4px",
                                    }}
                                  >
                                    {e?.AssignedElectritianList?.map(
                                      (ele, electric) => {
                                        return (
                                          <li>
                                            {typeof ele === "string"
                                              ? ele
                                              : ele?.electricianName}
                                          </li>
                                        );
                                        // return <li key={el}
                                        // return <li key={electric+"d"}>{ele?.electricianName?ele?.electricianName:ele}</li>})
                                      }
                                    )}
                                  </ul>
                                </div>
                              </td>
                              {loginName === "Heena" ||
                              loginName === "Akhil" ? (
                                <>
                                  <td>{e.comments}</td>

                                  <td>
                                    {e.dropped === false ? (
                                      <Deleteanddrop
                                        e={e._id}
                                        data={e}
                                        setReload={setReload}
                                        reloadW={reloadW}
                                        notify={notify}
                                        page={pages.grivianceDeartment}
                                      />
                                    ) : (
                                      <AlreadyDroped
                                        e={e._id}
                                        data={e}
                                        setReload={setReload}
                                        reloadW={reloadW}
                                        notify={notify}
                                        page={pages.grivianceDeartment}
                                      />
                                    )}
                                  </td>
                                  <td>
                                    <UninstallDoneBtn
                                      data={e}
                                      notify={notify}
                                      setReload={setReload}
                                      reload={reloadW}
                                      page={pages.grivianceDeartment}
                                    />
                                  </td>
                                </>
                              ) : null}
                              <td>
                                {e.dropped === true
                                  ? e.reasonForDrop
                                  : e.extra2}
                              </td>
                              <GrevianceCommentDep
                                dataObject={e}
                                reloadW={reloadW}
                                setReload={setReload}
                                notify={notify}
                              />
                              <td>
                                <ul>
                                  {e.grivienceCategory !== undefined &&
                                  e.grivienceCategory?.length !== 0
                                    ? e.grivienceCategory.map((type, index) => {
                                        return <li>{type.value}</li>;
                                      })
                                    : null}
                                </ul>
                              </td>
                              <td>
                                {moment.utc(e.InstallationDate).format("llll")}
                              </td>
                              <td
                                style={{
                                  color: `${
                                    e.partialPayment === false
                                      ? "green"
                                      : e.partialAmount === 0
                                      ? "red"
                                      : "orange"
                                  }`,
                                }}
                              >
                                {e.partialPayment === false
                                  ? "Paid"
                                  : e.partialAmount === 0
                                  ? "Zero"
                                  : `Partial`}{" "}
                              </td>
                              <td>{e.totalAppliances}</td>
                              <td>{e.planFrequency}</td>
                              <td>
                                <LastGrivanceElectrician data={e} />
                              </td>
                              <td>
                                {e.houseId !== "" ? (
                                  <ShowDevices data={e} />
                                ) : (
                                  <div>House Id Not avialable</div>
                                )}
                              </td>
                              <td>
                                {e.zone === "" ||
                                e.zone === undefined ||
                                e.zone === "Select Zone" ? (
                                  <SubscriptionZoneUpdate
                                    subscription={e}
                                    setReload={setReload}
                                    reload={reloadW}
                                  />
                                ) : (
                                  e.zone
                                )}
                              </td>
                              <td>
                                <GrivanceHistory data={e} notify={notify} />
                              </td>
                              <td>
                                <LastTenActionOnGrievence data={e} />
                              </td>
                              <td>
                                {e.dropped === true ? (
                                  "Uninstallation Site"
                                ) : (
                                  <GreivanceResolveDep
                                    dataObject={e}
                                    reloadW={reloadW}
                                    setReload={setReload}
                                    notify={notify}
                                  />
                                )}
                              </td>
                            </tr>
                          );
                        })
                    : null}
                </tbody>
              </table>
            </div>
          </>
        );
      })}

      {userRole?.includes("Super Admin") || loginName === "Vikas" ? (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          <iframe
            style={styles}
            width="640"
            height="480"
            src="https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/charts?id=2e3601f7-78a3-4eb3-a4c0-55ee6983b48b&maxDataAge=3600&theme=light&autoRefresh=true"
          ></iframe>
          <iframe
            style={styles}
            width="640"
            height="480"
            src="https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/charts?id=b347555c-778c-4ebf-8b59-0392f3ddfcd8&maxDataAge=3600&theme=light&autoRefresh=true"
          ></iframe>
          <iframe
            style={styles}
            width="640"
            height="480"
            src="https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/charts?id=b06b358f-97d8-416e-881e-546583c1ace4&maxDataAge=3600&theme=light&autoRefresh=true"
          ></iframe>
          <iframe
            style={styles}
            width="640"
            height="480"
            src="https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/charts?id=a91e2e2c-f5d4-404f-945e-cc18d9d49ecd&maxDataAge=3600&theme=light&autoRefresh=true"
          ></iframe>
        </div>
      ) : null}
      {userRole?.includes("Super Admin") || loginName === "Heena" ? (
        <div
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "space-between",
            flexWrap: "wrap",
            gap: 20,
          }}
        >
          <iframe
            style={styles}
            width="640"
            height="480"
            src="https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/charts?id=cf3f3972-4f31-4da1-93ad-f28ed0d265bd&maxDataAge=3600&theme=light&autoRefresh=true"
          ></iframe>
          <iframe
            style={styles}
            width="640"
            height="480"
            src="https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/charts?id=b347555c-778c-4ebf-8b59-0392f3ddfcd8&maxDataAge=3600&theme=light&autoRefresh=true"
          ></iframe>
          <iframe
            style={styles}
            width="640"
            height="480"
            src="https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/charts?id=011781e3-44e0-4648-bb94-264513556fd7&maxDataAge=3600&theme=light&autoRefresh=true"
          ></iframe>
          <iframe
            style={styles}
            width="640"
            height="480"
            src="https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/charts?id=31292352-8bf9-4aa3-ba33-b0b3fac85bfb&maxDataAge=3600&theme=light&autoRefresh=true"
          ></iframe>
        </div>
      ) : null}
      {userRole?.includes("Super Admin") ? (
        <div style={{ marginTop: "20px" }}>
          <iframe
            style={styles}
            width="640"
            height="480"
            src="https://charts.mongodb.com/charts-aliste-data-pvbnv/embed/charts?id=414a6d84-9e3b-470b-b431-7e7363b9d22b&maxDataAge=3600&theme=light&autoRefresh=true"
          ></iframe>
        </div>
      ) : null}
      {daysGrivanceData.length !== 0 && (
        <div className="table-container1" style={{ margin: 0 }}>
          <table className="table table-sm table-bordered">
            <thead>
              <tr>
                <th>Sr. No.</th>
                <th>Customer Name</th>
                <th scope="col">Telecaller Name</th>
                <th>Phone</th>
                <th>City</th>
                <th>Address</th>
                <th>Total Grivience</th>

                <th>Review</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {daysGrivanceData.length !== 0
                ? search(daysGrivanceData).map((e, i) => {
                    return (
                      <tr
                        key={i}
                        style={{
                          backgroundColor:
                            e.grievancePriority === true ? "#ff000082" : "",
                        }}
                      >
                        <td>{i + 1}</td>

                        <td
                          className="pr-15 pl-55"
                          style={{ fontWeight: "bold", cursor: "pointer" }}
                          onClick={() => {
                            navigator.clipboard.writeText(e.generatedHex);
                          }}
                        >
                          {e.name}
                        </td>
                        <td>{e.teleCallerId}</td>
                        <td>{e.phone}</td>
                        <td>{e.city}</td>
                        <td>{e.address}</td>
                        <td>{e.grivienceNumber}</td>
                        <td>{e.grievanceReview === true ? "Yes" : "No"}</td>
                        <td>
                          <Button
                            variant="contained"
                            onClick={() => handleReview(e.generatedHex)}
                          >
                            Review Done
                          </Button>
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}
