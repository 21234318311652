import { Button } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import { CSVLink } from "react-csv";
import { serverUrl } from '../ApiUrl';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { daDK } from '@mui/x-data-grid';
import { orderTyepes } from '../Data';

export default function DonwloadInvoiceCsv() {
  let [csvData, setCsvData] = useState([])
  let [generate, setGenerate] = useState("Generate Invoice CSV")
  let notify = (text) => toast(text)

  const outStanding = async(hex)=>{
     return await axios.post(`${serverUrl.u}/app/tele/getOutstandingAmount`,{
        generatedHex:hex
       }).then(function (res) {
          console.log(res);
          if(res.data.success){
            if(res.data.data.success){
              return res.data.data.outStandingAmount
            }else{
              return 0
            }
          }else{
            return 0
          }
       }).catch(function (err) {
          console.log(err);
          return 0
       })
  }
  const getSubscriptionInvoice = async () => {
    setGenerate("Generating...")
    let bill = [];
    await axios.post(`${serverUrl.u}/api/tele/getAllInvoices`).then(
      async function (response) {
        let detail = response.data.data;
        console.log(detail,'inv');
        detail.sort(function (a, b) {
          return (a.invoiceNumber) - (b.invoiceNumber);
        });
        let Data = [];
        await axios.post(`${serverUrl.u}/api/tele/fetchLimitedData`).then(
          function (res) {

            if (res.data.success === true) {
              Data = res.data.finalData
              console.log('====================================');
              console.log(Data,"limited");
              console.log('====================================');
            }
          }
        ).catch(function (err) {
          console.log(err);
        })
        let newData = []
        for(let d of Data){
          console.log(d.orderType,"drop");
          if(d.orderType!==orderTyepes[2].value){
          let outStandingAmount = await outStanding(d.generatedHex)
            newData.push({...d,Out_Standing_Amount:outStandingAmount})
          }else{
            newData.push({...d,Out_Standing_Amount:0})
          }
        }
        for (let e of detail) {

          let findData = newData.find(ele => ele.generatedHex === e.generatedHex)
          let pro ={}
          if (e.product.length > 0) {
            if (e.product.length > 11) {
              notify("Product is More than 11 Plz change Csv")
            }
            let obj = { ...e }
            let count = 0
            for(let i=0;i<e.product.length;i++){
              let d=e.product[i]
              pro={...pro,[`Product${i + 1}`]:`Product Name :${d.productName === undefined ? d.P_Id : d.productName} ,Selling Price :${d.sellingPricePerPic === undefined ? d.price : d.sellingPricePerPic} ,Qty :${d.qty} ,Hsn Code:${d.hsn_code}`}
          }
            for (let p of e.product) {
              obj = { ...obj, [`Product${count + 1}`]: p }
              count++;
            }
            e = { ...obj }
          
          }
          let changesInSubscriptionLastObject = {}
          if(findData!==undefined){
             changesInSubscriptionLastObject = findData.changesInSubscription[findData.changesInSubscription.length-1]
          }
        
         
          if (e.isFirstInvoice === true) {
            
            bill.push({
              "Invoice Number": e.invoiceNumber>7975?e.invoiceNumber-7975:(e.invoiceNumber>1200?e.invoiceNumber-1200:e.invoiceNumber),
              "Client Name": e.name,
              "TeleCaller Name": findData !== undefined ? (findData.teleCallerId) : "Subscription Not found",
              "Second Sales Person": findData !== undefined ? (findData.secondarySalesPerson) : "Subscription Not found",
              "City": e.is_UpSell === false ? (findData?.city ? (findData.city) : "") : ((findData?.city ? (findData.city) : "")),
              "Phone": findData !== undefined ? `' ${findData.phone}` : "subscription not found",
              "Hex Code": e.generatedHex,
              "House Id":findData !== undefined ? findData.houseId:"subscription not found",
              "Email": findData !== undefined ? findData.email : "subscription not found",
              "Created Date": moment(e.createdTime).format('MMM Do'),
              "Billing Date":moment(e.BillingDate).format('MMM Do YYYY'),
              "Plan Frequency": findData !== undefined ? (findData.planFrequency) : "Subscription Not Found",
              "GST Client": findData !== undefined ? ((findData.isGstClient === true || e.Is_Business === true) ? "Yes" : "No") : "Subscription Not Found",
              "GST Name": findData !== undefined ? ((findData.gstName === "") ? (e.BillTo) : (findData.gstName)) : "Subscription Not Found",
              "GST Number": findData !== undefined ? ((findData.gstNumber === "") ? (e.BusinessGst) : (findData.gstNumber)) : "Subscription Not Found",
              "Status": e.status,
              "UpSell": e.is_UpSell === true ? "UpSell" : "",
              "Order Type": findData !== undefined ? findData.orderType : "Subscription Not Found",
              "Partail Client":findData !== undefined ?(findData.partialPayment===true?"Yes":"NO"): "Subscription Not Found",
              "B2B Subscription":findData!==undefined? ((findData.gstNumber !== "" || e.BusinessGst!=="") ? "Yes B2B" : "No"):"Subscription Not Found",
              "Dropped Client": findData !== undefined ? (findData.Dropped_Client === true ? "Dropped" : "Active") : "Subscription Not Found",
              "Is First Invoice": e.isFirstInvoice === true ? "Yes" : "No",
              "Invoice Upgrade Downgrade": e.isInvoiceUpgradeDowngrade === true ? "Yes" : "No",
              "Current Appliances":findData !== undefined ?(e.isInvoiceUpgradeDowngrade === true ?(Object.keys(changesInSubscriptionLastObject).length !==0?findData.totalAppliances:findData.totalAppliances):""):"",
              "Old Appliance":findData !== undefined ?(e.isInvoiceUpgradeDowngrade === true ?(Object.keys(changesInSubscriptionLastObject).length !==0?changesInSubscriptionLastObject.oldAppliances:""):""):"",
              "Diff B/W Current-Old":findData !== undefined ?(e.isInvoiceUpgradeDowngrade === true ?(Object.keys(changesInSubscriptionLastObject).length !==0?(Number(findData.totalAppliances)-Number(changesInSubscriptionLastObject.oldAppliances)):""):""):"",
              "Reson of Converted":( findData !== undefined  ) ?e.isInvoiceUpgradeDowngrade === true?( (Object.keys(changesInSubscriptionLastObject).length !== 0 ?(`${changesInSubscriptionLastObject.changeInSubscription} - ${changesInSubscriptionLastObject.statusOfSubscription}`):"")):"" : "",
              "Master Cancel": e.masterCancel === true ? "Yes Master Cancel" : "No",
              "Invoice comments": e.comments,
              "Zoho Invoice Number": e.zohoInvoiceNumber,
              "Mandate Date": findData !== undefined ? moment(findData.mandateDate).format("MMM Do") : "No Subscription Found",
              "Overall Recursive": findData !== undefined ? (findData.partialPayment?(Number(e.additionalInfo[0].item_total)).toFixed(2):findData.recursiveAmount) : "No Subscription Found",
              "Overall Installation": findData !== undefined ? (findData.installation) : "No Subscription Found",
              "Tax": findData !== undefined ? ((Number(findData.recursiveAmount) + Number(findData.installation)) * 0.18) : "No Subscription Found",
              "Overall Total without Security": findData !== undefined ? ((Number(findData.recursiveAmount) + Number(findData.installation)) * 1.18) : "No Subscription Found",
              "Overall Scurity": findData !== undefined ? Number(findData.security) : "No Subscription Found",
              "Current Recursive": findData !== undefined ? (findData.partialPayment?(Number(e.additionalInfo[0].item_total)).toFixed(2):findData.currentRecursive) : "No Subscription Found",
              "Current Installation": findData !== undefined ? (findData.currentInstallation) : "No Subscription Found",
              "Current Tax": findData !== undefined ? (Number(findData.currentRecursive) + Number(findData.currentInstallation)) * 0.18 : "No Subscription Found",
               "Current Security": findData !== undefined ? (findData.currentSecurity) : "No Subscription Found",
              "Current Total WithOut Security": findData !== undefined ? (Number(findData.currentRecursive)+Number(findData.currentInstallation))*1.18 : "No Subscription Found",
              "Transaction mode & id": e.trnxDetails.map((ele) => ele.gateway_transaction_id !== undefined ? `${ele.payment_mode} - ${ele.amount} - ${ele.gateway_transaction_id}  ` : `${ele.payment_mode} - ${ele.amount} - ${ele.transaction_id}  `),
              "Transaction RazerPay": e.trnxRZP.map((ele) => `${ele.payment_mode} - ${ele.amount} - ${ele.gateway_transaction_id}  `),
              "Transaction Bank Transfer": e.trnxBankCash.map((ele) => `${ele.payment_mode} - ${ele.amount} -> Transaction Number ${ele.gateway_transaction_id}  `),

              "Transaction Credit Note": e.trnxCreditNotes.map((ele) => `${ele.payment_mode} -> Credit Note Number ${ele.gateway_transaction_id} -> ${ele.amount}   `),
              "Difference BW final total & tranasction amount": findData !== undefined ? (Number(e.actualTotalAmount) - (e.trnxDetails.reduce((previousValue, currentValue) => {
                return previousValue + Math.round(currentValue.amount)
              }, 0) + e.trnxRZP.reduce((previousValue, currentValue) => {
                return previousValue + Math.round(currentValue.amount)
              }, 0) + e.trnxCreditNotes.reduce((previousValue, currentValue) => {
                return previousValue + Math.round(currentValue.amount)
              }, 0) + e.trnxBankCash.reduce((previousValue, currentValue) => {
                return previousValue + Math.round(currentValue.amount)
              }, 0))) : "Subscription not found",
              "Out_Standing_Amount":findData !== undefined ?findData.Out_Standing_Amount:"No Subscription Found",
              "Total Product": e.product.length,
              // "Product1": e.hasOwnProperty('Product1') ? `Product Name :${e.Product1.productName === undefined ? e.Product1.P_Id : e.Product1.productName} ,Selling Price :${e.Product1.sellingPricePerPic === undefined ? e.Product1.price : e.Product1.sellingPricePerPic} ,Qty :${e.Product1.qty} ,Hsn Code:${e.Product1.hsn_code}` : "",
              // "Product2": e.hasOwnProperty('Product2') ? `Product Name :${e.Product2.productName === undefined ? e.Product2.P_Id : e.Product2.productName} ,Selling Price :${e.Product2.sellingPricePerPic === undefined ? e.Product2.price : e.Product2.sellingPricePerPic} ,Qty :${e.Product2.qty} ,Hsn Code:${e.Product2.hsn_code}` : "",
              // "Product3": e.hasOwnProperty('Product3') ? `Product Name :${e.Product3.productName === undefined ? e.Product3.P_Id : e.Product3.productName} ,Selling Price :${e.Product3.sellingPricePerPic === undefined ? e.Product3.price : e.Product3.sellingPricePerPic} ,Qty :${e.Product3.qty} ,Hsn Code:${e.Product3.hsn_code}` : "",
              // "Product4": e.hasOwnProperty('Product4') ? `Product Name :${e.Product4.productName === undefined ? e.Product4.P_Id : e.Product4.productName} ,Selling Price :${e.Product4.sellingPricePerPic === undefined ? e.Product4.price : e.Product4.sellingPricePerPic} ,Qty :${e.Product4.qty} ,Hsn Code:${e.Product4.hsn_code}` : "",
              // "Product5": e.hasOwnProperty('Product5') ? `Product Name :${e.Product5.productName === undefined ? e.Product5.P_Id : e.Product5.productName} ,Selling Price :${e.Product5.sellingPricePerPic === undefined ? e.Product5.price : e.Product5.sellingPricePerPic} ,Qty :${e.Product5.qty} ,Hsn Code:${e.Product5.hsn_code}` : "",
              // "Product6": e.hasOwnProperty('Product6') ? `Product Name :${e.Product6.productName === undefined ? e.Product6.P_Id : e.Product6.productName} ,Selling Price :${e.Product6.sellingPricePerPic === undefined ? e.Product6.price : e.Product6.sellingPricePerPic} ,Qty :${e.Product6.qty} ,Hsn Code:${e.Product6.hsn_code}` : "",
              // "Product7": e.hasOwnProperty('Product7') ? `Product Name :${e.Product7.productName === undefined ? e.Product7.P_Id : e.Product7.productName} ,Selling Price :${e.Product7.sellingPricePerPic === undefined ? e.Product7.price : e.Product7.sellingPricePerPic} ,Qty :${e.Product7.qty} ,Hsn Code:${e.Product7.hsn_code}` : "",
              // "Product8": e.hasOwnProperty('Product8') ? `Product Name :${e.Product8.productName === undefined ? e.Product8.P_Id : e.Product8.productName} ,Selling Price :${e.Product8.sellingPricePerPic === undefined ? e.Product8.price : e.Product8.sellingPricePerPic} ,Qty :${e.Product8.qty} ,Hsn Code:${e.Product8.hsn_code}` : "",
              // "Product9": e.hasOwnProperty('Product9') ? `Product Name :${e.Product9.productName === undefined ? e.Product9.P_Id : e.Product9.productName} ,Selling Price :${e.Product9.sellingPricePerPic === undefined ? e.Product9.price : e.Product9.sellingPricePerPic} ,Qty :${e.Product9.qty} ,Hsn Code:${e.Product9.hsn_code}` : "",
              // "Product10": e.hasOwnProperty('Product10') ? `Product Name :${e.Product10.productName === undefined ? e.Product10.P_Id : e.Product10.productName} ,Selling Price :${e.Product10.sellingPricePerPic === undefined ? e.Product10.price : e.Product10.sellingPricePerPic} ,Qty :${e.Product10.qty} ,Hsn Code:${e.Product10.hsn_code}` : "",
              // "Product11": e.hasOwnProperty('Product11') ? `Product Name :${e.Product11.productName === undefined ? e.Product11.P_Id : e.Product11.productName} ,Selling Price :${e.Product11.sellingPricePerPic === undefined ? e.Product11.price : e.Product11.sellingPricePerPic} ,Qty :${e.Product11.qty} ,Hsn Code:${e.Product11.hsn_code}` : "",
              ...pro,
              "Plan Code": (findData !== undefined && e.additionalInfo[0].code!==undefined) ? e.additionalInfo[0].code: 0,
              "Price": (findData !== undefined && e.additionalInfo[0].price!==undefined) ? e.additionalInfo[0].price: 0,
              "Quantity":(findData !== undefined && e.additionalInfo[0].quantity!==undefined) ? e.additionalInfo[0].quantity: 0,
            })
          }
          else {
            console.log("Invoice",e,"Subscription",findData);
            let city = ""
            if (e.is_UpSell === true) {
              let address =e.generalInfo?.address?( e.generalInfo?.address?.split(',')):"Not Available"
              city = address[address.length - 1]
            }
            bill.push({
              "Invoice Number": e.invoiceNumber>7975?e.invoiceNumber-7975:(e.invoiceNumber>1200?e.invoiceNumber-1200:e.invoiceNumber),
              "Client Name": e.name,
              "TeleCaller Name": findData !== undefined ? (findData.teleCallerId) : "Subscription Not found",
              "Second Sales Person": findData !== undefined ? (findData.secondarySalesPerson) : "Subscription Not found",
              "City": e.is_UpSell === false ? (findData?.city ? (findData.city) : "") : ((findData?.city ? (findData.city) : city)),
              "Phone": findData !== undefined ? `' ${findData.phone}` : "subscription not found",
              "Hex Code": e.generatedHex,
              "House Id":findData !== undefined ? findData.houseId:"subscription not found",
              "Email": findData !== undefined ? findData.email : "subscription not found",
              "Plan Frequency": findData !== undefined ? (findData.planFrequency) : "Subscription Not Found",
              "GST Client": findData !== undefined ? ((findData.isGstClient === true || e.Is_Business === true) ? "Yes" : "No") : "Subscription Not Found",
              "GST Name": findData !== undefined ? ((findData.gstName === "") ? (e.BillTo) : (findData.gstName)) : "Subscription Not Found",
              "GST Number": findData !== undefined ? ((findData.gstNumber === "") ? (e.BusinessGst) : (findData.gstNumber)) : "Subscription Not Found",
              "Status": e.status,
              "UpSell": e.is_UpSell === true ? "UpSell" : "",
              "Order Type": findData !== undefined ? findData.orderType : "Subscription Not Found",
              "Partail Client":findData !== undefined ?(findData.partialPayment===true?"Yes":"NO"): "Subscription Not Found",

              "B2B Subscription":findData!==undefined? ((findData.gstNumber !== "" || e.BusinessGst!=="") ? "Yes B2B" : "No"):"Subscription Not Found",
              "Dropped Client": findData !== undefined ? (findData.Dropped_Client === true ? "Dropped" : "Active") : "Subscription Not Found",
              "Is First Invoice": e.isFirstInvoice === true ? "Yes" : "No",
              "Invoice Upgrade Downgrade": e.isInvoiceUpgradeDowngrade === true ? "Yes" : "No",
              "Master Cancel": e.masterCancel === true ? "Yes Master Cancel" : "No",
              "Invoice comments": e.comments,
              "Current Appliances":findData !== undefined ?(e.isInvoiceUpgradeDowngrade === true ?(Object.keys(changesInSubscriptionLastObject).length !==0?findData.totalAppliances:findData.totalAppliances):""):"",
              "Old Appliance":findData !== undefined ?(e.isInvoiceUpgradeDowngrade === true ?(Object.keys(changesInSubscriptionLastObject).length !==0?changesInSubscriptionLastObject.oldAppliances:""):""):"",
              "Diff B/W Current-Old":findData !== undefined ?(e.isInvoiceUpgradeDowngrade === true ?(Object.keys(changesInSubscriptionLastObject).length !==0?(Number(findData.totalAppliances)-Number(changesInSubscriptionLastObject.oldAppliances)):""):""):"",
              "Reson of Converted":( findData !== undefined  ) ?e.isInvoiceUpgradeDowngrade === true?( (Object.keys(changesInSubscriptionLastObject).length !== 0 ?(`${changesInSubscriptionLastObject.changeInSubscription} - ${changesInSubscriptionLastObject.statusOfSubscription}`):"")):"" : "",
              "Zoho Invoice Number": e.zohoInvoiceNumber,
              "Created Date": moment(e.createdTime).format('MMM Do'),
              "Billing Date":moment(e.BillingDate).format('MMM Do YYYY'),
              "Mandate Date": findData !== undefined ? moment(findData.mandateDate).format("MMM Do") : "No Subscription Found",
              "Overall Recursive": e.is_UpSell === false ? (e.additionalInfo !== undefined ? (Number(e.additionalInfo[0].item_total)).toFixed(2) : (e.actualTotalAmount/1.18)) : (e.additionalInfo !== undefined ? `UpSell ${Number(e.additionalInfo[0].item_total).toFixed(2)}` : (e.actualTotalAmount/1.18)),
              "Overall Installation": e.isInvoiceUpgradeDowngrade === true?findData.installation:0,
              "Tax": e.is_UpSell === false ? 
              (e.isInvoiceUpgradeDowngrade === true?((findData.recursiveAmount+findData.installation)*0.18):(e.additionalInfo !== undefined ? ((Number(e.additionalInfo[0].item_total)) * 0.18).toFixed(2) : (e.actualTotalAmoun/1.18))) : (e.additionalInfo !== undefined ? `UpSell ${(Number(e.additionalInfo[0].item_total) * 0.18).toFixed(2)}` : ((e.actualTotalAmount/1.18)*0.18)),
              "Overall Total without Security": e.isInvoiceUpgradeDowngrade === true?(findData.recursiveAmount+findData.installation):e.actualTotalAmount,
               "Overall Scurity": e.isInvoiceUpgradeDowngrade === true?findData.security:0,
              "Current Recursive": e.is_UpSell === false ? (e.additionalInfo !== undefined ? (Number(e.additionalInfo[0].item_total)).toFixed(2) : (e.actualTotalAmount/1.18)) : (e.additionalInfo !== undefined ? `UpSell ${Number(e.additionalInfo[0].item_total).toFixed(2)}` : (e.actualTotalAmount/1.18)),
              "Current Installation": e.isInvoiceUpgradeDowngrade === true?findData.currentInstallation:0,
              "Current Tax": e.is_UpSell === false ? (e.additionalInfo !== undefined ? (Number(e.additionalInfo[0].item_total))*0.18 : (e.actualTotalAmount * 0.18)) : (e.additionalInfo !== undefined ? `UpSell ${Number(e.additionalInfo[0].item_total)*0.18}` : (e.actualTotalAmount * 0.18)),
              "Current Security": e.isInvoiceUpgradeDowngrade === true?findData.currentSecurity:0,
              "Current Total WithOut Security": e.actualTotalAmount,
              "Transaction mode & id": e.trnxDetails.map((ele) => ele.gateway_transaction_id !== undefined ? `${ele.payment_mode} - ${ele.amount} - ${ele.gateway_transaction_id}  ` : `${ele.payment_mode} - ${ele.amount} - ${ele.transaction_id}  `),
              "Transaction RazerPay": e.trnxRZP.map((ele) => `${ele.payment_mode} - ${ele.amount} - ${ele.gateway_transaction_id}  `),
              "Transaction Bank Transfer": e.trnxBankCash.map((ele) => `${ele.payment_mode} - ${ele.amount} -> Transaction Number ${ele.gateway_transaction_id}  `),
              "Transaction Credit Note": e.trnxCreditNotes.map((ele) => `${ele.payment_mode} -> Credit Note Number ${ele.gateway_transaction_id} - ${ele.amount} `),
              "Difference BW final total & tranasction amount": (Number(e.actualTotalAmount) - (e.trnxDetails.reduce((previousValue, currentValue) => {
                return previousValue + Math.round(currentValue.amount)
              }, 0) + e.trnxRZP.reduce((previousValue, currentValue) => {
                return previousValue + Math.round(currentValue.amount)
              }, 0) + e.trnxCreditNotes.reduce((previousValue, currentValue) => {
                return previousValue + Math.round(currentValue.amount)
              }, 0) + e.trnxBankCash.reduce((previousValue, currentValue) => {
                return previousValue + Math.round(currentValue.amount)
              }, 0))),
              "Out_Standing_Amount":findData !== undefined ?findData.Out_Standing_Amount:"No Subscription Found",
              "Total Product": e.product.length,
              // "Product1": e.hasOwnProperty('Product1') ? `Product Name :${e.Product1.productName === undefined ? e.Product1.P_Id : e.Product1.productName} ,Selling Price :${e.Product1.sellingPricePerPic === undefined ? e.Product1.price : e.Product1.sellingPricePerPic} ,Qty :${e.Product1.qty} ,Hsn Code:${e.Product1.hsn_code}` : "",
              // "Product2": e.hasOwnProperty('Product2') ? `Product Name :${e.Product2.productName === undefined ? e.Product2.P_Id : e.Product2.productName} ,Selling Price :${e.Product2.sellingPricePerPic === undefined ? e.Product2.price : e.Product2.sellingPricePerPic} ,Qty :${e.Product2.qty} ,Hsn Code:${e.Product2.hsn_code}` : "",
              // "Product3": e.hasOwnProperty('Product3') ? `Product Name :${e.Product3.productName === undefined ? e.Product3.P_Id : e.Product3.productName} ,Selling Price :${e.Product3.sellingPricePerPic === undefined ? e.Product3.price : e.Product3.sellingPricePerPic} ,Qty :${e.Product3.qty} ,Hsn Code:${e.Product3.hsn_code}` : "",
              // "Product4": e.hasOwnProperty('Product4') ? `Product Name :${e.Product4.productName === undefined ? e.Product4.P_Id : e.Product4.productName} ,Selling Price :${e.Product4.sellingPricePerPic === undefined ? e.Product4.price : e.Product4.sellingPricePerPic} ,Qty :${e.Product4.qty} ,Hsn Code:${e.Product4.hsn_code}` : "",
              // "Product5": e.hasOwnProperty('Product5') ? `Product Name :${e.Product5.productName === undefined ? e.Product5.P_Id : e.Product5.productName} ,Selling Price :${e.Product5.sellingPricePerPic === undefined ? e.Product5.price : e.Product5.sellingPricePerPic} ,Qty :${e.Product5.qty} ,Hsn Code:${e.Product5.hsn_code}` : "",
              // "Product6": e.hasOwnProperty('Product6') ? `Product Name :${e.Product6.productName === undefined ? e.Product6.P_Id : e.Product6.productName} ,Selling Price :${e.Product6.sellingPricePerPic === undefined ? e.Product6.price : e.Product6.sellingPricePerPic} ,Qty :${e.Product6.qty} ,Hsn Code:${e.Product6.hsn_code}` : "",
              // "Product7": e.hasOwnProperty('Product7') ? `Product Name :${e.Product7.productName === undefined ? e.Product7.P_Id : e.Product7.productName} ,Selling Price :${e.Product7.sellingPricePerPic === undefined ? e.Product7.price : e.Product7.sellingPricePerPic} ,Qty :${e.Product7.qty} ,Hsn Code:${e.Product7.hsn_code}` : "",
              // "Product8": e.hasOwnProperty('Product8') ? `Product Name :${e.Product8.productName === undefined ? e.Product8.P_Id : e.Product8.productName} ,Selling Price :${e.Product8.sellingPricePerPic === undefined ? e.Product8.price : e.Product8.sellingPricePerPic} ,Qty :${e.Product8.qty} ,Hsn Code:${e.Product8.hsn_code}` : "",
              // "Product9": e.hasOwnProperty('Product9') ? `Product Name :${e.Product9.productName === undefined ? e.Product9.P_Id : e.Product9.productName} ,Selling Price :${e.Product9.sellingPricePerPic === undefined ? e.Product9.price : e.Product9.sellingPricePerPic} ,Qty :${e.Product9.qty} ,Hsn Code:${e.Product9.hsn_code}` : "",
              // "Product10": e.hasOwnProperty('Product10') ? `Product Name :${e.Product10.productName === undefined ? e.Product10.P_Id : e.Product10.productName} ,Selling Price :${e.Product10.sellingPricePerPic === undefined ? e.Product10.price : e.Product10.sellingPricePerPic} ,Qty :${e.Product10.qty} ,Hsn Code:${e.Product10.hsn_code}` : "",
              // "Product11": e.hasOwnProperty('Product11') ? `Product Name :${e.Product11.productName === undefined ? e.Product11.P_Id : e.Product11.productName} ,Selling Price :${e.Product11.sellingPricePerPic === undefined ? e.Product11.price : e.Product11.sellingPricePerPic} ,Qty :${e.Product11.qty} ,Hsn Code:${e.Product11.hsn_code}` : "",
              ...pro,
              "Plan Code": (findData !== undefined && e.additionalInfo!==undefined && e.additionalInfo[0]!==undefined && e.additionalInfo[0].code!==undefined) ? e.additionalInfo[0].code: 0,
              "Price": (findData !== undefined && e.additionalInfo!==undefined && e.additionalInfo[0]!==undefined && e.additionalInfo[0].price!==undefined) ? e.additionalInfo[0].price: 0,
              "Quantity":(findData !== undefined && e.additionalInfo!==undefined && e.additionalInfo[0]!==undefined && e.additionalInfo[0].quantity!==undefined) ? e.additionalInfo[0].quantity: 0,
            })
          }
        }
        setCsvData(bill)
        setGenerate("Generate Invoice CSV")
      })
      .catch(function (error) {
        console.log(error);
        setGenerate("Failed Retry")
      })
  }
  return (
    <>
      {
        csvData.length === 0 ?
          <Button variant='contained' sx={{ fontSize: "10px", padding: "5px", ml: 2 }} onClick={() => getSubscriptionInvoice()}>{generate}</Button>
          : <CSVLink data={csvData}><Button variant='contained' sx={{ fontSize: "10px", padding: "5px", ml: 2 }}>Download Csv</Button></CSVLink>
      }
      <ToastContainer />
    </>
  )
}
