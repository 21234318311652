import { Card } from '@mui/material'
import React from 'react'
import InvoiceDownloadLink from '../Invoice/InvoiceDownloadLink'
import InvoiceSend from '../Invoice/InvoiceSend'
import moment from 'moment'
import UpdategstDetial from './UpdategstDetial'
import EditInvoice from '../Invoice/EditInvoice'
import InvoiceView from '../Invoice/InvoiceView'

export default function CardInvoice({inv,sub,notify,setInvoice}) {
  return (
    <Card 
      sx={{ 
            padding: "20px 30px", 
            margin: "10px 0", 
            boxShadow: "none", 
            position: "relative", 
            borderRadius: "13px",
            border: "1px solid #C6C6C6",
            width:"97%"
          }}
      key={inv.invoiceNumber}    
    >
    <div style={{ display: "flex", justifyContent: "space-between", width: "100%"}}>
      <div className="mainContainerInner">
        <div className="mainSubHeadingSub">Invoice Number</div>
        <div className="mainHeadingSub">{inv.invoiceNumber>7975?inv.invoiceNumber-7975:(inv.invoiceNumber>1200?inv.invoiceNumber-1200:inv.invoiceNumber)}</div>
      </div>
      <div className="mainContainerInner">
        <div className="mainSubHeadingSub">Zoho Number</div>
        <div className="mainHeadingSub">{inv.zohoInvoiceNumber}</div>
      </div>
      <div className="mainContainerInner">
        <div className="mainSubHeadingSub">Billing Date</div>
        <div className="mainHeadingSub">{moment(inv.BillingDate).format('DD-MMM-YYYY')}</div>
      </div>
      <div className="mainContainerInner">
        <div className="mainSubHeadingSub">Created Time</div>
        <div className="mainHeadingSub">{moment(inv.createdTime).format('DD-MMM-YYYY')}</div>
      </div>
      {
        inv.masterCancel ===true?(
          <div className="mainContainerInner">
        <div className="mainSubHeadingSub">Status</div>
        <div className="mainHeadingSub" >{"Void"}</div>
      </div>
        ):(
          <div className="mainContainerInner">
        <div className="mainSubHeadingSub">Status</div>
        <div className="mainHeadingSub" style={{color:inv.status!=="overdue"?"#008000":"#FF0000"}}>{inv.status[0].toUpperCase() + inv.status.substring(1)}</div>
      </div>
        )
      }
     
      <div className="mainContainerInner">
        <div className="mainSubHeadingSub">Amount</div>
        <div className="mainHeadingSub">{inv.actualTotalAmount}</div>
      </div>
      <div className="mainContainerInner">
        <div className="mainSubHeadingSub">UpSell</div>
        <div className="mainHeadingSub" >{inv.is_UpSell===true?"Yes":"No"}</div>
      </div>
      <div className="mainContainerInner">
        <div className="mainSubHeadingSub">First Invoice</div>
        <div className="mainHeadingSub" >{inv.isFirstInvoice?"Yes":"No"}</div>
      </div>
      <div className="mainContainerInner">
        <div className="mainSubHeadingSub">Cancel Status</div>
        <div className="mainHeadingSub" >{(inv.auditingCancel || inv.cancelled || inv.masterCancel)?"Yes":"No"}</div>
      </div>
      <div style={{display:"flex",gap:"10px",alignItems:"center"}}>
      
       { inv.is_UpSell && <EditInvoice invoice={inv} sub={sub} setInvoice={setInvoice}/> }
       <div><InvoiceView e={inv} ele={sub} notify={notify}/></div>

      <div><InvoiceDownloadLink e={inv} ele={sub} notify={notify}/></div>
      {/* <div>{(sub.hasOwnProperty("email")===true && sub.email!=="")?<InvoiceSend inv={inv} sub={sub} notify={notify}/>:"E-Mail Not Register"}</div> */}
      <div><UpdategstDetial inv={inv}/></div>
      </div>
     
    </div>
</Card>
  )
}
