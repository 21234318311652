import React, { useEffect } from "react";
import Popover from '@mui/material/Popover';
import { Formik, Field, Form, FieldArray } from "formik";
import {
  Grid,
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
  Typography,
  Box,
  Select,
  MenuItem,
  OutlinedInput,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Padding } from "@mui/icons-material";
import moment from "moment";
import { planDuration } from "../../Data";
import { editCreditNote } from "../Api";
import { subHistory } from "../../Function/SubscriptionHistory";
import { useSelector } from "react-redux";

function EditCreditNote({ data,date,refresh }) {
  let loginName = useSelector((state) => state.sar.data.user.first_name)

  const initialValues = {
    ...data,
    duration: {
      ...data.duration,
      lastDate: data?.duration?.lastDate!==undefined ?moment(new Date(data.duration.lastDate)).format("YYYY-MM-DD"):'',
      firstDate:data?.duration?.firstDate!==undefined? moment(new Date(data.duration.firstDate)).format("YYYY-MM-DD"):'',
    },
    transaction: (data.transaction || []).map((transaction) => ({
      ...transaction,
      date: moment(new Date(transaction.date)).format("YYYY-MM-DD"),
    })),
    virtualTransaction: (data.virtualTransaction || []).map((transaction) => ({
      ...transaction,
      date: moment(new Date(transaction.date)).format("YYYY-MM-DD"),
    })),
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [loading,setLoading]  = React.useState(false)
  const handleClick = (event) => {
    console.log(data);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const handleSubmit = async(values) => {
    setLoading(true)
   await editCreditNote({...values,cId:data._id}).then(async(res)=>{
    subHistory("Credit Note Edited", {...data,mewData:{...values,cId:data._id}}, `Credit Note Edited by ${loginName}`, "Credit Note Edited")

     setLoading(false)
     setAnchorEl(null);
    await refresh(date)
   })
    console.log(values);
  };
  return (
    <>
      <Button
        aria-describedby={id}
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
        Edit
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{maxWidth:'85%'}}
        anchorOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "left",
        }}
      >
        <Box sx={{ padding: "20px" }}>
          <Typography variant="h5" mb={2} mt={2}>Edit Credit Note</Typography>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}  enableReinitialize={true}>
            {({ values }) => {
            
              return (
                <Form>
                  <Grid container spacing={2}>
                    {/* Basic Information */}
                   
                    <Grid item xs={12} sm={6}>
                      <Field name="name">
                        {({ field }) => (
                          <TextField fullWidth label="Name" {...field} />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="phone">
                        {({ field }) => (
                          <TextField fullWidth label="Phone" {...field} />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="invoiceNumber">
                        {({ field }) => (
                          <TextField
                            fullWidth
                            label="Invoice Number"
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="amount">
                        {({ field }) => (
                          <TextField
                            fullWidth
                            label="Amount"
                            type="number"
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="invoiceAmount">
                        {({ field }) => (
                          <TextField
                            fullWidth
                            label="Invoice Amount"
                            type="number"
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="remainingAmount">
                        {({ field }) => (
                          <TextField
                            fullWidth
                            label="Remaining Amount"
                            type="number"
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="usedAmount">
                        {({ field }) => (
                          <TextField
                            fullWidth
                            label="Used Amount"
                            type="number"
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="status">
                        {({ field }) => (
                          <TextField fullWidth label="Status" {...field} />
                        )}
                      </Field>
                    </Grid>

                    {/* Transaction Section */}
                    <Grid item xs={12}>
                      <Typography variant="h6" mb={1}>Transaction</Typography>
                      <FieldArray name="transaction">
                        {({ push, remove }) => (
                          <>
                            {values.transaction.map((_, index) => (
                              <Grid container spacing={2} key={index}>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`transaction.${index}.usedAmount`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Used Amount"
                                        type="number"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`transaction.${index}.zohoInvoiceNumber`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Zoho Invoice Number"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`transaction.${index}.invoiceNumber`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Invoice Number"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`transaction.${index}.generatedHex`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Generated Hex"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field name={`transaction.${index}.reason`}>
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Reason"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field name={`transaction.${index}.date`}>
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Date"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            ))}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                push({
                                  usedAmount: "",
                                  zohoInvoiceNumber: "",
                                  invoiceNumber: "",
                                  generatedHex: "",
                                  reason: "",
                                  date: new Date(),
                                })
                              }
                            >
                              Add Transaction
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Grid>

                    {/* Virtual Transaction Section */}
                    <Grid item xs={12}>
                      <Typography variant="h6" mb={1}>Virtual Transaction</Typography>
                      <FieldArray name="virtualTransaction">
                        {({ push, remove }) => (
                          <>
                            {values.virtualTransaction.map((_, index) => (
                              <Grid container spacing={2} key={index}>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`virtualTransaction.${index}.usedAmount`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Used Amount"
                                        type="number"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`virtualTransaction.${index}.zohoInvoiceNumber`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Zoho Invoice Number"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`virtualTransaction.${index}.invoiceNumber`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Invoice Number"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`virtualTransaction.${index}.generatedHex`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Generated Hex"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`virtualTransaction.${index}.reason`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Reason"
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                  <Field
                                    name={`virtualTransaction.${index}.date`}
                                  >
                                    {({ field }) => (
                                      <TextField
                                        fullWidth
                                        label="Date"
                                        type="date"
                                        InputLabelProps={{ shrink: true }}
                                        {...field}
                                      />
                                    )}
                                  </Field>
                                </Grid>
                                <Grid item xs={12} mb={1}>
                                  <Button
                                    variant="contained"
                                    color="secondary"
                                    onClick={() => remove(index)}
                                  >
                                    Remove
                                  </Button>
                                </Grid>
                              </Grid>
                            ))}
                            <Button
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                push({
                                  usedAmount: "",
                                  zohoInvoiceNumber: "",
                                  invoiceNumber: "",
                                  generatedHex: "",
                                  reason: "",
                                  date: new Date(),
                                })
                              }
                            >
                              Add Virtual Transaction
                            </Button>
                          </>
                        )}
                      </FieldArray>
                    </Grid>

                    {/* Duration Section */}
                    <Grid item xs={12}>
                      <Typography variant="h6" mb={1}>Duration</Typography>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <Field name="duration.planFrequency">
                            {({ field }) => (
                                <FormControl sx={{height:'100%'}} fullWidth >
                                 <InputLabel fullWidth id="demo-simple-select-disabled-label">Plan Frequency</InputLabel>
                              <Select
                              labelId="demo-simple-select-disabled-label"
                              id="demo-simple-select-disabled"
                              label="Plan Frequency"
                                fullWidth
                                sx={{height:'100%',p:1}}
                                // input={<OutlinedInput label="Plan Frequency" />}
                                {...field}
                              >
                                {
                                    planDuration.map((option)=> <MenuItem value={option.value}>{option.value}</MenuItem>)
                                }
                               
                              </Select>
                              </FormControl>
                            )}
                          </Field>
                        </Grid>
                        {/* {console.log(moment(new Date(values.duration.firstDate)).format('DD/MM/YYYY'),values.duration.firstDate)} */}
                        <Grid item xs={12} sm={6}>
                          <Field name="duration.firstDate">
                            {({ field }) => (
                              <TextField
                                fullWidth
                                label="First Date"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                {...field}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field name="duration.lastDate">
                            {({ field }) => (
                              <TextField
                                fullWidth
                                label="Last Date"
                                type="date"
                                InputLabelProps={{ shrink: true }}
                                {...field}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field name="duration.totalAppliances">
                            {({ field }) => (
                              <TextField
                                fullWidth
                                label="Total Appliances"
                                type="number"
                                {...field}
                              />
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field name="duration.unusedDays">
                            {({ field }) => (
                              <TextField
                                fullWidth
                                label="Unused Days"
                                type="number"
                                {...field}
                              />
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* Master Cancel and Comments Section */}
                    <Grid item xs={12} sm={6}>
                    <Field name="comments">
                        {({ field }) => (
                          <TextField fullWidth label="Comments" {...field} />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Field name="cancelReason">
                        {({ field }) => (
                          <TextField
                            fullWidth
                            label="Cancel Reason"
                            {...field}
                          />
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12}>
                   
                      <Field name="masterCancel">
                        {({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox checked={field.value} {...field} />
                            }
                            label="Master Cancel"
                          />
                        )}
                      </Field>
                    </Grid>

                    <Grid item xs={12}>
                      <Button variant="contained" color="primary" disabled={loading} type="submit">
                        Save
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              );
            }}
          </Formik>
        </Box>
      </Popover>
    </>
  );
}

export default EditCreditNote;
