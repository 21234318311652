import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import "../ConvertedLead.css";
import "../DispatchData.css";
import "../PaymentLinkGen.css";
import moment, { months } from "moment/moment";
import { Link } from "react-router-dom";
import Qutoes from "../Qutoes";
import { pages, serverUrl } from "../ApiUrl";
import { useSelector } from "react-redux";
import CommentUdate from "../Function/CommentUdate";
import ChangeSecondTeleCaller from "../Function/ChangeSecondTeleCaller";
import {
  ElectricianIncentiveZone,
  interstedorNotClient,
  monthFilter,
  orderTyepes,
  planCost,
  readyConstrcuction,
  state,
  typeOFClient,
  user_roles,
} from "../Data";
import CityUpdate from "../Function/CityUpdate";
import AddressUpdate from "../Function/AddressUpdate";
import AlternateNumberUpdate from "../Function/AlternateNumberUpdate";
import ChangeInstallationDate from "../Function/ChangeInstallationDate";
import {
  fetchAddressLayoutApi,
  removeAddressLayout,
  updateAddressLayout,
} from "../Redux/Action/adderess&layout";
import { useDispatch } from "react-redux";
import ChangeHyperLink from "../Function/ChangeHyperLink";
import FinalInstallationDone from "../Final_Installed/FinalInstallationDone";
import OverdueInvoice from "./OverdueInvoice";
import OverdueInvoicesDropClient from "./OverdueInvoicesDropClient";
import SelectCityInput from "../city/SelectCityInput";
import { notifyFailed, notify } from "../Common/Notification";
import SiteVisitCreated, { siteVistcreateds } from "./SiteVisitCreated";
import { daysDifference } from "../Common/applianceDifference";
import { csvGenerateData, updateDetails } from "./Api";
import CallNotPickByClient from "./CallNotPickByClient";
import Deleteanddrop from "../Function/Deleteanddrop";
import { subHistory } from "../Function/SubscriptionHistory";
import { Button, Select } from "@mui/material";
import { clientData } from "../Client registation/data";
import { InterestedOrNot } from "./InterestedOrNot";
import AdminComment from "../Installation/AdminComment";
import { CSVLink } from "react-csv";
import PartialUpgradeDownGrade from "../SearchClient/PartialUpgradeDownGrade";
export default function PaymentLinkGen() {
  let saleData = useSelector((state) => state.salesUser);
  let addressLayouts = useSelector((state) => state.addressLayout);
  let employeeId = useSelector((state) => state.sar.data.user.employeeId)

  console.log(addressLayouts, "dddd");
  const dispatch = useDispatch();
  let [citys, setCity] = useState("");
  let [searchPage, setSearchPage] = useState("");
  let [displayData, setDisplayData] = useState([]);
  let [update, setUpdateData] = useState({});
  let [importantClient, setImportantClient] = useState([]);
  let [newClient, setNewClient] = useState([]);
  let [callNotPick, setCallNotPick] = useState([]);
  let [updateDetail, setUpdateDetail] = useState(false);
  let [websiteLead, setWebsiteLead] = useState([]);
  let [selectTelecaller, setSelectTelecaller] = useState("All");
  let dateChange = true;
  let [reloadW, setReload] = useState(false);
  let installationDates = useRef(null);
  const [timeHave, setTimeHave] = useState(true);
  let userRole = useSelector((state) => state.sar.data?.user?.roles);
  let [updateInfoBtn, setUpdateInfoBtn] = useState("Update");
  let [popPartial, setPopPartial] = useState(false);
  let [dispatchId, setDispatchId] = useState("");
  let [qutoId, setQutoId] = useState("");
  const [activeClients, setActiveClients] = useState("New Client");
  const [typeOfIntersted, setTypeOfIntersted] = useState(
    interstedorNotClient[0]
  );
  const [monthFilters, setMonthFilter] = useState(monthFilter[0]);
  const [totalClient, setTotalClient] = useState(0);
  const [constructionSite, setConstructionSite] = useState(
    readyConstrcuction[0]
  );
  const [selectedMonth, setSelectedMonth] = useState(
    moment(new Date()).format("MMM-YYYY")
  );
  const [activeType, setActiveType] = useState("All");

  let userDetail = useSelector((state) => state.sar.data.user);
  let token = useSelector((state) => state.sar.data.token);
  useEffect(() => {
    fetchAddressLayoutApi(userDetail, dispatch);
  }, []);
  useEffect(() => {
    let addressLayout = [];
    let dd = new Date();
    let previousMonth = dd.getMonth() - 1;
    let currentMonth = dd.getMonth();
    console.log(previousMonth, currentMonth, "mm");
    const date1 = new Date("November 01, 2023");
    if (activeClients === "All") {
      addressLayout = addressLayouts;
    } else if (activeClients === "Old Client") {
      addressLayout = addressLayouts.filter(
        (e) => new Date(e.createdAt) < date1
      );
    } else {
      addressLayout = addressLayouts.filter(
        (e) => new Date(e.createdAt) >= date1
      );
    }
    if (activeType === "Exclude FreeClient") {
      addressLayout = addressLayout.filter((e) => e.orderType !== "FreeClient");
    } else if (activeType !== "All") {
      addressLayout = addressLayout.filter((e) => e.orderType === activeType);
    }
    // if(typeOfIntersted.name!==interstedorNotClient[0].name){
    //   addressLayout = addressLayout.filter(e=>e.InterestedClient===typeOfIntersted.value)
    // }
    if (monthFilters.name !== monthFilter[0].name) {
      if (monthFilters.name === monthFilter[1].name) {
        addressLayout = addressLayout.filter((e) => {
          let mon = new Date(e.createdAt).getMonth();
          console.log(mon, "mmmcc");
          if (currentMonth === mon) {
            return e;
          }
        });
      } else if (monthFilters.name === monthFilter[2].name) {
        addressLayout = addressLayout.filter((e) => {
          let mon = new Date(e.createdAt).getMonth();
          console.log(mon, "mmm");
          if (previousMonth === mon) {
            return e;
          }
        });
      }
    }
    if (constructionSite.name !== readyConstrcuction[0].name) {
      addressLayout = addressLayout.filter(
        (e) => e.categoryOfInterestedClient === constructionSite.value
      );
    }
    if (selectTelecaller !== "All") {
      addressLayout = addressLayout.filter(
        (e) =>
          e.teleCallerId === selectTelecaller ||
          e.secondarySalesPerson === selectTelecaller
      );
    }
    setTotalClient(addressLayout.length);
    let website = addressLayout.filter(
      (e) =>
        (e.teleCallerId === "website" || e.teleCallerId === "WhatsApp") &&
        e.secondarySalesPerson === "empty"
    );
    setWebsiteLead(website);
    let notpick = addressLayout.filter((e) => e.callPickedOrNot === true);
    setCallNotPick(notpick);
    let imp = addressLayout.filter((e) => {
      if (
        (e.grivience === true ||
          e.installationToAddress === true ||
          e.electritianToAddress === true) &&
        e.callPickedOrNot === false
      ) {
        return e;
      }
    });
    setImportantClient(imp);
    let newC = addressLayout.filter((e) => {
      if (
        e.installationToAddress === false &&
        e.electritianToAddress === false &&
        e.changesInSubscription.length === 0 &&
        e.callPickedOrNot === false &&
        e.InstallationDate === null
      ) {
        return e;
      }
    });

    setNewClient(newC);
    let newData = [];
    for (let i of addressLayout) {
      if (i.callPickedOrNot === true) {
        continue;
      }
      let found = false;
      for (let j of imp) {
        if (i._id === j._id) {
          found = true;
        }
      }
      for (let j of newC) {
        if (i._id === j._id) {
          found = true;
        }
      }
      if (!found) {
        newData.push(i);
      }
    }
    newData.sort(function (a, b) {
      return new Date(a.InstallationDate) - new Date(b.InstallationDate);
    });
    let monthKeys = {};
    for (let client of newData) {
      if (
        monthKeys[moment(client.InstallationDate).format("MMM-YYYY")] ===
        undefined
      ) {
        monthKeys[moment(client.InstallationDate).format("MMM-YYYY")] = [];
      }
      monthKeys[moment(client.InstallationDate).format("MMM-YYYY")].push(
        client
      );
    }
    setDisplayData(monthKeys);
    // setSelectedMonth(moment(new Date()).format("MMM-YYYY"))
  }, [
    addressLayouts,
    activeClients,
    activeType,
    typeOfIntersted,
    monthFilters,
    constructionSite,
    selectTelecaller,
  ]);

  let updateStatus = async (data) => {
    if (
      data.city === "Any For Now" ||
      data.address === "" ||
      data.city === "" ||
      data.city === "Any for now"
    ) {
      notify("First Update City and Address");
      return;
    }
    console.log(data.zone, "zone");
    if (data.zone === "" || data.zone === "Select Zone") {
      notify("Plz Select Subscription zone Bofore Update");
      return;
    }
    if (data.orderType === typeOFClient[2].value) {
      if (!data.siteVisiteByElectrician) {
        let res = await siteVistcreateds(data);
        if (res.res !== undefined) {
          if (res.res.data.success) {
            subHistory(
              `${
                data.siteVisiteByElectrician
                  ? "Site visite revoke"
                  : "Site visite set"
              }`,
              data,
              "Site visite",
              data.InstallationDate
            );
          }
        }
      }
    }
    setDispatchId(data._id);
    await axios
      .post(`${serverUrl.u}/api/tele/updateDispatchStatus`, {
        subId: data._id,
        status: "ready to dispatch",
      })
      .then(function (response) {
        let detail = response;
        console.log(data._id);
        console.log(detail);

        if (response.data.success === true) {
          notify("Dispatched Successfully");
          dispatch(removeAddressLayout(data));
          subHistory(
            "Subscription send to installation page",
            data,
            "Send to Installation",
            data.InstallationDate
          );
          setUpdateData([]);
          setDisplayData([]);
        } else {
          notify(response.data.message);
        }
        setDispatchId("");
      })
      .catch(function (error) {
        console.log(error);
        notifyFailed(error.response.data.message);
        setDispatchId("");
      });
  };

  const downloadFile = (blob, fileName) => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    document.body.append(link);
    link.click();
    link.remove();
  };
  const quotationApi = async (data) => {
    let name = data.name;
    let seq = Number(data.zohoSecurity);
    let ins = Number(data.zohoInstallation);
    let qu = Number(data.totalAppliances);
    let planNamess =
      data.houseStructure[0]?.roomName !== undefined
        ? data.houseStructure[0].roomName
        : "Custom";
    let planDurations = data.planFrequency;
    let fee;
    if (planDurations === "Monthly") {
      fee = qu * planCost.Monthly * 30;
    } else if (planDurations === "Quarterly") {
      fee = qu * planCost.Quarterly * 90;
    } else if (planDurations === "Semi Annualy") {
      fee = qu * planCost.SemiAnnualy * 180;
    } else if (planDurations === "Annualy") {
      fee = qu * planCost.Annualy * 365;
    } else if (planDurations === "Annualy-3") {
      fee = qu * planCost.Annualy * 365;
    } else if (planDurations === "Test (Weekly)") {
      fee = qu * planCost.TestWeekly * 7;
    } else if (planDurations === "Two Years") {
      fee = qu * planCost.TwoYears * (365 * 2);
    } else if (planDurations === "Three Years") {
      fee = qu * planCost.ThreeYears * (365 * 3);
    }
    let total = fee + seq + ins;
    let tax = (fee + ins) * 0.18;
    let totalTax = total + tax;
    await axios
      .post(
        `${serverUrl.s}/misc/getQuotation`,
        {
          quoteTo: name,
          quoteNo: data.quotationNumber,
          itemsQuantity: { appliances: qu },
          packagePlanDetails: {
            packageName: planNamess,
            planName: planDurations,
            security: seq,
            installation: ins,
            fee: fee,

            total: total,
          },
          amounts: {
            subtotal: total,
            tax: tax,
            total: totalTax,
          },
        },
        {
          responseType: "blob",
          headers: {
            AccessToken: token,
          },
        }
      )
      .then(function (response) {
        setQutoId("");
        downloadFile(new Blob([response.data]), `Quote - ${name}.pdf`);
      })
      .catch(function (error) {
        console.log("quto   ", error);
        notify(error.data.message);
        setQutoId("");
      });
    setQutoId("");
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    let Data = {
      client_name: data.get("client_name"),
      address: data.get("address"),
      alternateNumber: data.get("alternateNumber"),
      city: data.get("city"),
      zipCode: data.get("zipCode"),
      comment: data.get("comment"),
      date: data.get("date"),
      incentiveZone: data.get("incentiveZone"),
    };
    if (Data.city === "empty") {
      notify("Plz Select City Bofore Update");
      return;
    }
    if (Data.incentiveZone === "") {
      notify("Plz Select Subscription zone Bofore Update");
      return;
    }
    setUpdateInfoBtn("Loading...");
    updateDetails(
      Data,
      update,
      dateChange,
      citys,
      setUpdateData,
      dispatch,
      updateAddressLayout,
      setUpdateDetail,
      userDetail,
      setUpdateDetail
    );
  };
  const search = (dummy) => {
    let newData = dummy?.filter((e) => {
      if (e.name?.toUpperCase().includes(searchPage.toUpperCase())) {
        return e;
      }
    });
    return newData;
  };
  useEffect(()=>{
    if(!popPartial){
      setUpdateData({})
    }
  },[popPartial])
  return (
    <div style={{ padding: "0 4%", marginTop: "4vh" }}>
      {
       !userRole?.includes(user_roles.Isp) && <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* {
          !userRole.includes(user_roles.Isp) ?
            <div style={{ display: "flex", gap: "5px" }}>
              <OverdueInvoice notify={notify} />
              <OverdueInvoicesDropClient notify={notify} />
            </div> : null
        } */}
        <div className="title-Container">
          <div
            className="title"
            style={{
              backgroundColor:
                activeClients === "All" ? clientData.activeColor : "white",
              color: activeClients === "All" ? "white" : "black",
              minWidth: "121px",
              height: "min-content",
            }}
            onClick={() => setActiveClients("All")}
          >
            {"All Client"}
          </div>
          <div
            className="title"
            style={{
              backgroundColor:
                activeClients === "Old Client"
                  ? clientData.activeColor
                  : "white",
              color: activeClients === "Old Client" ? "white" : "black",
              minWidth: "121px",
              height: "min-content",
            }}
            onClick={() => setActiveClients("Old Client")}
          >
            {"Old Client"}
          </div>
          <div
            className="title"
            style={{
              backgroundColor:
                activeClients === "New Client"
                  ? clientData.activeColor
                  : "white",
              color: activeClients === "New Client" ? "white" : "black",
              minWidth: "130px",
              height: "min-content",
            }}
            onClick={() => setActiveClients("New Client")}
          >
            {"New Client"}
          </div>
        </div>

        <div className="input-field" style={{ display: "flex" }}>
          <input
            style={{ width: "100% !important" }}
            type="text"
            placeholder="search by name..."
            onChange={(e) => setSearchPage(e.target.value)}
          />
        </div>
      </div>
      <div className="title-Container mt-2" style={{ textAlign: "center" }}>
        {orderTyepes.map((o) => (
          <div
            className="title"
            style={{
              backgroundColor:
                activeType === o.value ? clientData.activeColor : "white",
              color: activeType === o.value ? "white" : "black",
              minWidth: "121px",
              height: "min-content",
            }}
            onClick={() => setActiveType(o.value)}
          >
            {o.value}
          </div>
        ))}
      </div>
    
      <div className="title-Container mt-2" style={{ textAlign: "center" }}>
        {monthFilter.map((o) => (
          <div
            className="title"
            style={{
              backgroundColor:
                monthFilters.name === o.name ? clientData.activeColor : "white",
              color: monthFilters.name === o.name ? "white" : "black",
              minWidth: "121px",
              height: "min-content",
            }}
            onClick={() => setMonthFilter(o)}
          >
            {o.name}
          </div>
        ))}
      </div>
      <div className="title-Container mt-2" style={{ textAlign: "center" }}>
        {readyConstrcuction.map((o) => (
          <div
            className="title"
            style={{
              backgroundColor:
                constructionSite.name === o.name
                  ? clientData.activeColor
                  : "white",
              color: constructionSite.name === o.name ? "white" : "black",
              minWidth: "121px",
              height: "min-content",
            }}
            onClick={() => setConstructionSite(o)}
          >
            {o.name}
          </div>
        ))}
      </div>

      <div className="col-2">
        <label className="create-label1">TeleCaller</label>
        <div class="input-group ">
          <select
            class="custom-select height-select"
            onChange={(e) => {
              setSelectTelecaller(e.target.value);
            }}
            value={selectTelecaller}
          >
            <option value="All">All</option>
            {saleData?.map((option) => (
              <option value={option.first_name}>{option.first_name}</option>
            ))}
          </select>
        </div>
      </div>
      <div className="mt-2">Total Client :- {totalClient}</div>
      </>
      }
      {websiteLead.length !== 0 && (userRole.includes("Super Admin") || employeeId==="1024")? (
        <div className="mt-2">
          <div className="d-flex justify-content-between">
            <div>Website Lead</div>
            <CSVLink
              className="btn btn-primary"
              data={csvGenerateData(websiteLead)}
              filename="WebSiteLead.csv"
            >
              {"Download"}
            </CSVLink>
          </div>
          <div
            className="table-container1"
            style={{
              marginTop: "10px",
              marginBottom: "30px",
              height: "fit-content",
            }}
            key={"imp"}
          >
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>planType</th>
                  <th scope="col">
                    Name<span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Client Category</th>
                  <th scope="col">Telecaller Name</th>

                  <th scope="col">Second Telecaller Name</th>
                  <th scope="col">City</th>
                  <th scope="col">Appliances</th>
                  {userRole.includes(user_roles.SuperAdmin) && (
                    <>
                      <th style={{ width: "190px" }}>
                        Admin Comment <span style={{ color: "red" }}>*</span>
                      </th>
                    </>
                  )}
                  <th scope="col">
                    Comments <span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Electrician Comment</th>
                  <th scope="col">Selected Plan</th>
                  <th>
                    Address<span style={{ color: "red" }}>*</span>
                  </th>
                  <th>
                    Hyper Link<span style={{ color: "red" }}>*</span>
                  </th>
                  <th scope="col">Phone</th>
                  <th>Payment</th>
                  <th>Days Since</th>
                  <th scope="col">Created At</th>
                  <th>Installation Date</th>
                  <th>Site Viste</th>

                  <th style={{ width: "770px" }}>Action</th>
                  {userRole.includes(user_roles.SuperAdmin) && (
                    <>
                      <th style={{ width: "190px" }}>Delete & Drop</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {search(websiteLead).map((e, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{e.orderType}</td>
                      <td
                        className="pr-15 pl-55"
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(e.generatedHex);
                        }}
                      >
                        {e.name}
                      </td>
                      <td>{e.categoryOfInterestedClient}</td>
                      <td>{e.teleCallerId}</td>
                      <ChangeSecondTeleCaller
                        e={e}
                        userName={saleData}
                        filter={pages.addressLayout}
                      />
                      <CityUpdate e={e} page={pages.addressLayout} />
                      <td>
                        <div className="hover-wide">
                          {e.orderType !== orderTyepes[2].value ? (
                            e.totalAppliances
                          ) : (
                            <ul>
                              {e.upsellItems.map((product, i) => {
                                return (
                                  <li>
                                    Product Name - {product.productName},
                                    Selling Per Pic -{" "}
                                    {product.sellingPricePerPic}, Qty -{" "}
                                    {product.qty}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      </td>
                      {userRole.includes(user_roles.SuperAdmin) && (
                        <>
                          <td>
                            <AdminComment sub={e} page={pages.addressLayout} />
                          </td>
                        </>
                      )}
                      <CommentUdate
                        e={e}
                        websiteReload={reloadW}
                        SetWebsiteReload={setReload}
                        page={pages.addressLayout}
                      />
                      <td>
                        <div className="hover-wide">{e.installerComments}</div>
                      </td>
                      <td>{e.planFrequency}</td>
                      <AddressUpdate e={e} page={pages.addressLayout} />
                      <td>
                        <ChangeHyperLink
                          e={e}
                          page={pages.addressLayout}
                          notify={notify}
                        />
                      </td>
                      <td>
                        <a href={`tel:${e.phone}`}>{e.phone}</a>
                      </td>

                      <td
                        style={{
                          color: `${
                            e.partialPayment === false
                              ? "green"
                              : e.partialAmount === 0
                              ? "red"
                              : "orange"
                          }`,
                        }}
                      >
                        {e.partialPayment === false
                          ? "Paid"
                          : e.partialAmount === 0
                          ? "Zero"
                          : `Partial`}{" "}
                      </td>
                      <td>
                        <div
                          style={{
                            backgroundColor: daysDifference(e.createdAt)[0],
                            width: "fit-content",
                            padding: "5px 10px",
                            borderRadius: "70%",
                            color: "white",
                          }}
                        >
                          {daysDifference(e.createdAt)[1]}
                        </div>
                      </td>

                      <td>{moment(e.createdAt).format("DD-MM-YYYY")}</td>
                      <ChangeInstallationDate
                        e={e}
                        notify={notify}
                        decision={"td"}
                        page={pages.addressLayout}
                      />

                      <td>
                        {e.siteVisiteByElectrician === true
                          ? "Site Visit Set"
                          : "Not Set"}
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            type="button"
                            onClick={() => {
                              setUpdateData(e);
                              setUpdateDetail(true);
                              setCity(e.city);
                              setUpdateInfoBtn("Update");
                            }}
                          >
                            Update info
                          </Button>
                          {!userRole.includes(user_roles.Isp) ? (
                            <>
                              <SiteVisitCreated
                                sub={e}
                                page={pages.addressLayout}
                                btnName={"Site Visit Create"}
                                text={
                                  "Are you sure you mark this subcription as site visit ?"
                                }
                              />
                              <CallNotPickByClient hex={e.generatedHex} />
                              <InterestedOrNot sub={e} />
                            </>
                          ) : null}

                          <Button
                            variant="contained"
                            onClick={() => updateStatus(e)}
                            disabled={dispatchId === e._id ? true : false}
                          >
                            Dispatch
                          </Button>
                          
                          {
                            userRole.includes(user_roles.Isp) && (
                              <>
                              {
                                e.partialPayment===true && (
                                  <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setUpdateData(e);
                                    setPopPartial(true);
                                  }}
                                >
                                  DownGrade/Upgrade
                                </button>
                                )
                              }
                       
                              </>
                            )
                          }
                        </div>
                      </td>

                      {userRole.includes(user_roles.SuperAdmin) && (
                        <>
                          <td>
                            <Deleteanddrop
                              e={e._id}
                              data={e}
                              page={pages.addressLayout}
                            />
                          </td>
                          {/* <td><AdminComment sub={e} page={pages.addressLayout}/></td> */}
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      {newClient.length !== 0 ? (
        <div className="mt-2">
          <div className="d-flex justify-content-between">
            <div>New Client</div>
            <CSVLink
              className="btn btn-primary"
              data={csvGenerateData(newClient)}
              filename="New Client.csv"
            >
              {"Download"}
            </CSVLink>
          </div>
          <div
            className="table-container1"
            style={{
              marginTop: "10px",
              marginBottom: "30px",
              height: "fit-content",
            }}
            key={"imp"}
          >
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>planType</th>
                  <th scope="col">
                    Name<span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Client Category</th>
                  <th scope="col">Telecaller Name</th>

                  <th scope="col">Second Telecaller Name</th>
                  <th scope="col">City</th>
                  <th scope="col">Appliances</th>
                  {userRole.includes(user_roles.SuperAdmin) && (
                    <>
                      <th style={{ width: "190px" }}>
                        Admin Comment <span style={{ color: "red" }}>*</span>
                      </th>
                    </>
                  )}
                  <th scope="col">
                    Comments <span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Electrician Comment</th>
                  <th scope="col">Selected Plan</th>
                  <th>
                    Address<span style={{ color: "red" }}>*</span>
                  </th>
                  <th>
                    Hyper Link<span style={{ color: "red" }}>*</span>
                  </th>
                  <th scope="col">Phone</th>
                  <th>Payment</th>
                  <th>Days Since</th>
                  <th scope="col">Created At</th>
                  <th>Installation Date</th>
                  <th>Site Viste</th>

                  <th style={{ width: "770px" }}>Action</th>
                  {userRole.includes(user_roles.SuperAdmin) && (
                    <>
                      <th style={{ width: "190px" }}>Delete & Drop</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {search(newClient).map((e, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        backgroundColor:
                          e.grivience === true
                            ? "#ff00007d"
                            : e.installationToAddress == true
                            ? "rgb(150 129 235 / 56%)"
                            : e.electritianToAddress === true
                            ? "#ffff007a"
                            : "",
                      }}
                    >
                      <td>{i + 1}</td>
                      <td>{e.orderType}</td>
                      <td
                        className="pr-15 pl-55"
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(e.generatedHex);
                        }}
                      >
                        {e.name}
                      </td>
                      <td>{e.categoryOfInterestedClient}</td>
                      <td>{e.teleCallerId}</td>
                      <ChangeSecondTeleCaller
                        e={e}
                        userName={saleData}
                        filter={pages.addressLayout}
                      />
                      <CityUpdate e={e} page={pages.addressLayout} />
                      <td>
                        <div className="hover-wide">
                          {e.orderType !== orderTyepes[2].value ? (
                            e.totalAppliances
                          ) : (
                            <ul>
                              {e.upsellItems.map((product, i) => {
                                return (
                                  <li>
                                    Product Name - {product.productName},
                                    Selling Per Pic -{" "}
                                    {product.sellingPricePerPic}, Qty -{" "}
                                    {product.qty}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      </td>
                      {userRole.includes(user_roles.SuperAdmin) && (
                        <>
                          <td>
                            <AdminComment sub={e} page={pages.addressLayout} />
                          </td>
                        </>
                      )}
                      <CommentUdate
                        e={e}
                        websiteReload={reloadW}
                        SetWebsiteReload={setReload}
                        page={pages.addressLayout}
                      />
                      <td>
                        <div className="hover-wide">{e.installerComments}</div>
                      </td>
                      <td>{e.planFrequency}</td>
                      <AddressUpdate e={e} page={pages.addressLayout} />
                      <td>
                        <ChangeHyperLink
                          e={e}
                          page={pages.addressLayout}
                          notify={notify}
                        />
                      </td>
                      <td>
                        <a href={`tel:${e.phone}`}>{e.phone}</a>
                      </td>

                      <td
                        style={{
                          color: `${
                            e.partialPayment === false
                              ? "green"
                              : e.partialAmount === 0
                              ? "red"
                              : "orange"
                          }`,
                        }}
                      >
                        {e.partialPayment === false
                          ? "Paid"
                          : e.partialAmount === 0
                          ? "Zero"
                          : `Partial`}{" "}
                      </td>
                      <td>
                        <div
                          style={{
                            backgroundColor: daysDifference(e.createdAt)[0],
                            width: "fit-content",
                            padding: "5px 10px",
                            borderRadius: "70%",
                            color: "white",
                          }}
                        >
                          {daysDifference(e.createdAt)[1]}
                        </div>
                      </td>

                      <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                      <ChangeInstallationDate
                        e={e}
                        notify={notify}
                        decision={"td"}
                        page={pages.addressLayout}
                      />

                      <td>
                        {e.siteVisiteByElectrician === true
                          ? "Site Visit Set"
                          : "Not Set"}
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            type="button"
                            onClick={() => {
                              setUpdateData(e);
                              setUpdateDetail(true);
                              setCity(e.city);
                            }}
                          >
                            Update info
                          </Button>
                          {!userRole.includes(user_roles.Isp) ? (
                            <>
                              <SiteVisitCreated
                                sub={e}
                                page={pages.addressLayout}
                                btnName={"Site Visit Create"}
                                text={
                                  "Are you sure you mark this subcription as site visit ?"
                                }
                              />
                                  <CallNotPickByClient hex={e.generatedHex} />

                               <InterestedOrNot sub={e} />
                            </>
                          ) : null}

                          <Button
                            variant="contained"
                            onClick={() => updateStatus(e)}
                            disabled={dispatchId === e._id ? true : false}
                          >
                            Dispatch
                          </Button>
                      
                          {
                            userRole.includes(user_roles.Isp) && (
                              <>
                              {
                                e.partialPayment===true && (
                                  <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setUpdateData(e);
                                    setPopPartial(true);
                                  }}
                                >
                                  DownGrade/Upgrade
                                </button>
                                )
                              }
                              </>
                            )
                          }
                        </div>
                      </td>
                      {/* <td>{e.grivience === true ? <FinalInstallationDone data={e} notify={notify} /> : null}</td> */}
                      {userRole.includes(user_roles.SuperAdmin) && (
                        <>
                          <td>
                            <Deleteanddrop
                              e={e._id}
                              data={e}
                              page={pages.addressLayout}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      {importantClient.length !== 0 ? (
        <div className="mt-2">
          <div className="d-flex justify-content-between">
            <div>Client Returned From Electrician</div>
            <CSVLink
              className="btn btn-primary"
              data={csvGenerateData(importantClient)}
              filename="Client Returned From Electrician.csv"
            >
              {"Download"}
            </CSVLink>
          </div>
          {!userRole.includes(user_roles.Isp) ? (
            <div style={{ display: "flex" }}>
              <div
                style={{
                  display: "flex",
                  marginLeft: "20px",
                  marginTop: "20px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "rgb(150 129 235 / 56%)",
                    height: "17px",
                    width: "17px",
                    marginRight: "10px",
                  }}
                ></div>
                <div>This color means client comes from installation page</div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "20px",
                  marginTop: "20px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ffff007a",
                    height: "17px",
                    width: "17px",
                    marginRight: "10px",
                  }}
                ></div>
                <div>This color means client comes from electrician page</div>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "20px",
                  marginTop: "20px",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    backgroundColor: "#ff00007d",
                    height: "17px",
                    width: "17px",
                    marginRight: "10px",
                  }}
                ></div>
                <div>This color means client has Grievance</div>
              </div>
            </div>
          ) : null}
          <div
            className="table-container1"
            style={{
              marginTop: "10px",
              marginBottom: "30px",
              height: "fit-content",
            }}
            key={"imp"}
          >
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Type</th>
                  <th scope="col">
                    Name<span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Client Category</th>
                  <th scope="col">Telecaller Name</th>
                  <th scope="col">Second Telecaller Name</th>
                  <th scope="col">City</th>
                  <th scope="col">Appliances</th>
                  {userRole.includes(user_roles.SuperAdmin) && (
                    <>
                      <th style={{ width: "190px" }}>
                        Admin Comment <span style={{ color: "red" }}>*</span>
                      </th>
                    </>
                  )}
                  <th scope="col">
                    Comments <span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Electrician Comment</th>
                  <th scope="col">Selected Plan</th>
                  <th>
                    Address<span style={{ color: "red" }}>*</span>
                  </th>
                  <th>
                    Hyper Link<span style={{ color: "red" }}>*</span>
                  </th>
                  <th scope="col">Phone</th>
                  <th>Payment</th>
                  <th>Days Since</th>
                  <th scope="col">Created At</th>
                  <th>Installation Date</th>
                  <th>Site Viste</th>

                  <th style={{ width: "720px" }}>Action</th>
                  <th style={{ width: "160px" }}>Final Installation</th>
                  {userRole.includes(user_roles.SuperAdmin) && (
                    <>
                      <th style={{ width: "190px" }}>Delete & Drop</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {search(importantClient).map((e, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        backgroundColor:
                          e.grivience === true
                            ? "#ff00007d"
                            : e.installationToAddress == true
                            ? "rgb(150 129 235 / 56%)"
                            : e.electritianToAddress === true
                            ? "#ffff007a"
                            : "",
                      }}
                    >
                      <td>{i + 1}</td>
                      <td>{e.orderType}</td>
                      <td
                        className="pr-15 pl-55"
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(e.generatedHex);
                        }}
                      >
                        {e.name}
                      </td>
                      <td>{e.categoryOfInterestedClient}</td>
                      <td>{e.teleCallerId}</td>
                      <ChangeSecondTeleCaller
                        e={e}
                        userName={saleData}
                        filter={pages.addressLayout}
                      />
                      <CityUpdate e={e} page={pages.addressLayout} />
                      <td>
                        <div className="hover-wide">
                          {e.orderType !== orderTyepes[2].value ? (
                            e.totalAppliances
                          ) : (
                            <ul>
                              {e.upsellItems.map((product, i) => {
                                return (
                                  <li>
                                    Product Name - {product.productName},
                                    Selling Per Pic -{" "}
                                    {product.sellingPricePerPic}, Qty -{" "}
                                    {product.qty}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      </td>
                      {userRole.includes(user_roles.SuperAdmin) && (
                        <>
                          <td>
                            <AdminComment sub={e} page={pages.addressLayout} />
                          </td>
                        </>
                      )}
                      <CommentUdate
                        e={e}
                        websiteReload={reloadW}
                        SetWebsiteReload={setReload}
                        page={pages.addressLayout}
                      />
                      <td>
                        <div className="hover-wide">{e.installerComments}</div>
                      </td>
                      <td>{e.planFrequency}</td>
                      <AddressUpdate e={e} page={pages.addressLayout} />
                      <td>
                        <ChangeHyperLink
                          e={e}
                          page={pages.addressLayout}
                          notify={notify}
                        />
                      </td>
                      <td>
                        <a href={`tel:${e.phone}`}>{e.phone}</a>
                      </td>

                      <td
                        style={{
                          color: `${
                            e.partialPayment === false
                              ? "green"
                              : e.partialAmount === 0
                              ? "red"
                              : "orange"
                          }`,
                        }}
                      >
                        {e.partialPayment === false
                          ? "Paid"
                          : e.partialAmount === 0
                          ? "Zero"
                          : `Partial`}{" "}
                      </td>
                      <td>
                        <div
                          style={{
                            backgroundColor: daysDifference(e.createdAt)[0],
                            width: "fit-content",
                            padding: "5px 10px",
                            borderRadius: "70%",
                            color: "white",
                          }}
                        >
                          {daysDifference(e.createdAt)[1]}
                        </div>
                      </td>

                      <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                      <ChangeInstallationDate
                        e={e}
                        notify={notify}
                        decision={"td"}
                        page={pages.addressLayout}
                      />

                      <td>
                        {e.siteVisiteByElectrician === true
                          ? "Site Visit Set"
                          : "Not Set"}
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            type="button"
                            onClick={() => {
                              setUpdateData(e);
                              setUpdateDetail(true);
                              setCity(e.city);
                            }}
                          >
                            Update info
                          </Button>
                          {!userRole.includes(user_roles.Isp) ? (
                            <>
                              
                              <SiteVisitCreated
                                sub={e}
                                page={pages.addressLayout}
                                btnName={"Site Visit Create"}
                                text={
                                  "Are you sure you mark this subcription as site visit ?"
                                }
                              />
                                <InterestedOrNot sub={e} />
                            </>
                          ) : null}
                          <Button
                            variant="contained"
                            onClick={() => updateStatus(e)}
                            disabled={dispatchId === e._id ? true : false}
                          >
                            Dispatch
                          </Button>
                        
                          {
                            userRole.includes(user_roles.Isp) && (
                              <>
                              {
                                e.partialPayment===true && (
                                  <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setUpdateData(e);
                                    setPopPartial(true);
                                  }}
                                >
                                  DownGrade/Upgrade
                                </button>
                                )
                              }
                       
                              </>
                            )
                          }
                        </div>
                      </td>
                      <td>
                        {e.grivience === true ? (
                          <FinalInstallationDone data={e} notify={notify} />
                        ) : null}
                      </td>
                      {userRole.includes(user_roles.SuperAdmin) && (
                        <>
                          <td>
                            <Deleteanddrop
                              e={e._id}
                              data={e}
                              page={pages.addressLayout}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}

      {callNotPick.length !== 0 ? (
        <div className="mt-2">
          
          <div className="d-flex justify-content-between">
            <div>Client Call Not Pick</div>
            <CSVLink
              className="btn btn-primary"
              data={csvGenerateData(callNotPick)}
              filename="Client Call Not Pick.csv"
            >
              {"Download"}
            </CSVLink>
          </div>
          <div
            className="table-container1"
            style={{
              marginTop: "10px",
              marginBottom: "30px",
              height: "fit-content",
            }}
            key={"imp"}
          >
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Type</th>
                  <th scope="col">
                    Name<span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Client Category</th>
                  <th scope="col">Telecaller Name</th>

                  <th scope="col">Second Telecaller Name</th>
                  <th scope="col">City</th>
                  <th scope="col">Appliances</th>
                  {userRole.includes(user_roles.SuperAdmin) && (
                    <>
                      <th style={{ width: "190px" }}>
                        Admin Commentv<span style={{ color: "red" }}>*</span>
                      </th>
                    </>
                  )}
                  <th scope="col">
                    Comments <span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Electrician Comment</th>
                  <th scope="col">Selected Plan</th>
                  <th>
                    Address<span style={{ color: "red" }}>*</span>
                  </th>
                  <th>
                    Hyper Link<span style={{ color: "red" }}>*</span>
                  </th>
                  <th scope="col">Phone</th>
                  <th>Payment</th>
                  <th>Days Since</th>
                  <th scope="col">Created At</th>
                  <th>Installation Date</th>
                  <th>Site Viste</th>

                  <th style={{ width: "720px" }}>Action</th>
                  {userRole.includes(user_roles.SuperAdmin) && (
                    <>
                      <th style={{ width: "190px" }}>Delete & Drop</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {search(callNotPick).map((e, i) => {
                  return (
                    <tr
                      key={i}
                      style={{
                        backgroundColor:
                          e.grivience === true
                            ? "#ff00007d"
                            : e.installationToAddress == true
                            ? "rgb(150 129 235 / 56%)"
                            : e.electritianToAddress === true
                            ? "#ffff007a"
                            : "",
                      }}
                    >
                      <td>{i + 1}</td>
                      <td>{e.orderType}</td>
                      <td
                        className="pr-15 pl-55"
                        style={{ fontWeight: "bold", cursor: "pointer" }}
                        onClick={() => {
                          navigator.clipboard.writeText(e.generatedHex);
                        }}
                      >
                        {e.name}
                      </td>
                      <td>{e.categoryOfInterestedClient}</td>
                      <td>{e.teleCallerId}</td>
                      <ChangeSecondTeleCaller
                        e={e}
                        userName={saleData}
                        filter={pages.addressLayout}
                      />
                      <CityUpdate e={e} page={pages.addressLayout} />
                      <td>
                        <div className="hover-wide">
                          {e.orderType !== orderTyepes[2].value ? (
                            e.totalAppliances
                          ) : (
                            <ul>
                              {e.upsellItems.map((product, i) => {
                                return (
                                  <li>
                                    Product Name - {product.productName},
                                    Selling Per Pic -{" "}
                                    {product.sellingPricePerPic}, Qty -{" "}
                                    {product.qty}
                                  </li>
                                );
                              })}
                            </ul>
                          )}
                        </div>
                      </td>
                      {userRole.includes(user_roles.SuperAdmin) && (
                        <>
                          <td>
                            <AdminComment sub={e} page={pages.addressLayout} />
                          </td>
                        </>
                      )}
                      <CommentUdate
                        e={e}
                        websiteReload={reloadW}
                        SetWebsiteReload={setReload}
                        page={pages.addressLayout}
                      />
                      <td>
                        <div className="hover-wide">{e.installerComments}</div>
                      </td>
                      <td>{e.planFrequency}</td>
                      <AddressUpdate e={e} page={pages.addressLayout} />
                      <td>
                        <ChangeHyperLink
                          e={e}
                          page={pages.addressLayout}
                          notify={notify}
                        />
                      </td>
                      <td>
                        <a href={`tel:${e.phone}`}>{e.phone}</a>
                      </td>

                      <td
                        style={{
                          color: `${
                            e.partialPayment === false
                              ? "green"
                              : e.partialAmount === 0
                              ? "red"
                              : "orange"
                          }`,
                        }}
                      >
                        {e.partialPayment === false
                          ? "Paid"
                          : e.partialAmount === 0
                          ? "Zero"
                          : `Partial`}{" "}
                      </td>
                      <td>
                        <div
                          style={{
                            backgroundColor: daysDifference(e.createdAt)[0],
                            width: "fit-content",
                            padding: "5px 10px",
                            borderRadius: "70%",
                            color: "white",
                          }}
                        >
                          {daysDifference(e.createdAt)[1]}
                        </div>
                      </td>
                      <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                      <ChangeInstallationDate
                        e={e}
                        notify={notify}
                        decision={"td"}
                        page={pages.addressLayout}
                      />

                      <td>
                        {e.siteVisiteByElectrician === true
                          ? "Site Visit Set"
                          : "Not Set"}
                      </td>

                      <td>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-evenly",
                          }}
                        >
                          <Button
                            variant="contained"
                            size="small"
                            type="button"
                            onClick={() => {
                              setUpdateData(e);
                              setUpdateDetail(true);
                              setCity(e.city);
                            }}
                          >
                            Update info
                          </Button>
                          {!userRole.includes(user_roles.Isp) ? (
                            <>
                              <SiteVisitCreated
                                sub={e}
                                page={pages.addressLayout}
                                btnName={"Site Visit Create"}
                                text={
                                  "Are you sure you mark this subcription as site visit ?"
                                }
                              />
                               <InterestedOrNot sub={e}/>
                            </>
                          ) : null}

                          <Button
                            variant="contained"
                            onClick={() => updateStatus(e)}
                            disabled={dispatchId === e._id ? true : false}
                          >
                            Dispatch
                          </Button>
                         
                          {
                            userRole.includes(user_roles.Isp) && (
                              <>
                              {
                                e.partialPayment===true && (
                                  <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setUpdateData(e);
                                    setPopPartial(true);
                                  }}
                                >
                                  DownGrade/Upgrade
                                </button>
                                )
                              }
                       
                              </>
                            )
                          }
                        </div>
                      </td>
                      {/* <td>{e.grivience === true ? <FinalInstallationDone data={e} notify={notify} /> : null}</td> */}
                      {userRole.includes(user_roles.SuperAdmin) && (
                        <>
                          <td>
                            <Deleteanddrop
                              e={e._id}
                              data={e}
                              page={pages.addressLayout}
                            />
                          </td>
                        </>
                      )}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      ) : null}
      <div style={{ display: "flex", gap: "10px" }}>
        {Object.keys(displayData).map((month, index) => {
          return (
            <Button
              variant={selectedMonth === month ? "contained" : "outlined"}
              onClick={() => setSelectedMonth(month)}
            >
              {month}
            </Button>
          );
        })}
      </div>
      {Object.keys(displayData).length > 0 && (
        <div key={selectedMonth} style={{ marginTop: "5vh" }}>
          
          <div className="d-flex justify-content-between">
          <div
            key={selectedMonth}
            style={{
              fontSize: "large",
              marginLeft: "1.5vw",
              marginBottom: "10px",
            }}
          >
            {selectedMonth}
          </div>
            <CSVLink
              className="btn btn-primary"
              data={csvGenerateData(displayData[selectedMonth]?.filter((i=>{
                if( i.grivience === false &&
                  i.installationToAddress === false &&
                  i.electritianToAddress === false){
                    return i
                  }
              }
            )))}
              filename={`${selectedMonth}.csv`}
            >
              {"Download"}
            </CSVLink>
          </div>
          <div
            className="table-container1"
            style={{
              marginTop: 0,
              marginBottom: "30px",
              height: "fit-content",
            }}
            key={selectedMonth}
          >
             
            
            <table className="table table-sm table-bordered">
              <thead>
                <tr>
                  <th>Sr. No.</th>
                  <th>Type</th>
                  <th scope="col">
                    Name<span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Client Category</th>
                  <th scope="col">Telecaller Name</th>
                  <th scope="col">Second Telecaller Name</th>
                  <th scope="col">City</th>
                  <th scope="col">Appliances</th>
                  {userRole.includes(user_roles.SuperAdmin) && (
                    <>
                      <th style={{ width: "190px" }}>
                        Admin Comment <span style={{ color: "red" }}>*</span>
                      </th>
                    </>
                  )}
                  <th scope="col">
                    Comments <span style={{ color: "red" }}>*</span>
                  </th>
                  <th>Electrician Comment</th>
                  <th scope="col">Selected Plan</th>
                  <th>
                    Address<span style={{ color: "red" }}>*</span>
                  </th>
                  <th>
                    Hyper Link<span style={{ color: "red" }}>*</span>
                  </th>
                  <th scope="col">Phone</th>
                  <th>Payment</th>
                  <th>Days Since</th>
                  <th scope="col">Created At</th>
                  <th>Installation Date</th>
                  <th>Site Visit</th>

                  <th style={{ width: "770px" }}>Action</th>
                  {userRole.includes(user_roles.SuperAdmin) && (
                    <>
                      <th style={{ width: "190px" }}>Delete & Drop</th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {selectedMonth !== "" &&
                search(displayData[selectedMonth])?.length !== 0
                  ? search(displayData[selectedMonth])?.map((e, i) => {
                      if (
                        e.grivience === true ||
                        e.installationToAddress === true ||
                        e.electritianToAddress === true
                      ) {
                        return;
                      } else {
                        return (
                          <tr
                            key={i}
                            style={{
                              backgroundColor:
                                e.grivience === true
                                  ? "#ff00007d"
                                  : e.installationToAddress == true
                                  ? "rgb(150 129 235 / 56%)"
                                  : e.electritianToAddress === true
                                  ? "#ffff007a"
                                  : "",
                            }}
                          >
                            <td>{i + 1}</td>
                            <td>{e.orderType}</td>
                            <td
                              className="pr-15 pl-55"
                              style={{ fontWeight: "bold", cursor: "pointer" }}
                              onClick={() => {
                                navigator.clipboard.writeText(e.generatedHex);
                              }}
                            >
                              {e.name}
                            </td>
                            <td>{e.categoryOfInterestedClient}</td>
                            <td>{e.teleCallerId}</td>
                            <ChangeSecondTeleCaller
                              e={e}
                              userName={saleData}
                              filter={pages.addressLayout}
                            />
                            <CityUpdate e={e} page={pages.addressLayout} />
                            <td>
                              <div className="hover-wide">
                                {e.orderType !== orderTyepes[2].value ? (
                                  e.totalAppliances
                                ) : (
                                  <ul>
                                    {e.upsellItems.map((product, i) => {
                                      return (
                                        <li>
                                          Product Name - {product.productName},
                                          Selling Per Pic -{" "}
                                          {product.sellingPricePerPic}, Qty -{" "}
                                          {product.qty}
                                        </li>
                                      );
                                    })}
                                  </ul>
                                )}
                              </div>
                            </td>
                            {userRole.includes(user_roles.SuperAdmin) && (
                              <>
                                <td>
                                  <AdminComment
                                    sub={e}
                                    page={pages.addressLayout}
                                  />
                                </td>
                              </>
                            )}
                            <CommentUdate
                              e={e}
                              websiteReload={reloadW}
                              SetWebsiteReload={setReload}
                              page={pages.addressLayout}
                            />
                            <td>
                              <div className="hover-wide">
                                {e.installerComments}
                              </div>
                            </td>
                            <td>{e.planFrequency}</td>
                            <AddressUpdate e={e} page={pages.addressLayout} />
                            <td>
                              <ChangeHyperLink
                                e={e}
                                page={pages.addressLayout}
                                notify={notify}
                              />
                            </td>
                            <td>
                              <a href={`tel:${e.phone}`}>{e.phone}</a>
                            </td>
                            <td
                              style={{
                                color: `${
                                  e.partialPayment === false
                                    ? "green"
                                    : e.partialAmount === 0
                                    ? "red"
                                    : "orange"
                                }`,
                              }}
                            >
                              {e.partialPayment === false
                                ? "Paid"
                                : e.partialAmount === 0
                                ? "Zero"
                                : `Partial`}{" "}
                            </td>
                            <td>
                              <div
                                style={{
                                  backgroundColor: daysDifference(
                                    e.createdAt
                                  )[0],
                                  width: "fit-content",
                                  padding: "5px 10px",
                                  borderRadius: "70%",
                                  color: "white",
                                }}
                              >
                                {daysDifference(e.createdAt)[1]}
                              </div>
                            </td>{" "}
                            <td>{moment(e.createdAt).format("MMM Do LT")}</td>
                            <ChangeInstallationDate
                              e={e}
                              setReload={setReload}
                              reload={reloadW}
                              notify={notify}
                              decision={"td"}
                              page={pages.addressLayout}
                            />
                            <td>
                              {e.siteVisiteByElectrician === true
                                ? "Site Visit Set"
                                : "Not Set"}
                            </td>
                            <td>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                }}
                              >
                                <Button
                                  variant="contained"
                                  size="small"
                                  type="button"
                                  onClick={() => {
                                    setUpdateData(e);
                                    setUpdateDetail(true);
                                    setCity(e.city);
                                  }}
                                >
                                  Update info
                                </Button>
                                {!userRole.includes(user_roles.Isp) ? (
                                  <>
                                  
                                    <SiteVisitCreated
                                      sub={e}
                                      page={pages.addressLayout}
                                      btnName={"Site Visit Create"}
                                      text={
                                        "Are you sure you mark this subcription as site visit ?"
                                      }
                                    />
                                      <CallNotPickByClient hex={e.generatedHex} />
                                <InterestedOrNot sub={e} />
                                  </>
                                ) : null}
                                <Button
                                  variant="contained"
                                  onClick={() => updateStatus(e)}
                                  disabled={dispatchId === e._id ? true : false}
                                >
                                  Dispatch
                                </Button>
                              
                                {
                            userRole.includes(user_roles.Isp) && (
                              <>
                              {
                                e.partialPayment===true && (
                                  <button
                                  className="btn btn-primary"
                                  onClick={() => {
                                    setUpdateData(e);
                                    setPopPartial(true);
                                  }}
                                >
                                  DownGrade/Upgrade
                                </button>
                                )
                              }
                       
                              </>
                            )
                          }
                              </div>
                            </td>
                            {userRole.includes(user_roles.SuperAdmin) && (
                              <>
                                <td>
                                  <Deleteanddrop
                                    e={e._id}
                                    data={e}
                                    page={pages.addressLayout}
                                  />
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      }
                    })
                  : null}
              </tbody>
            </table>
          </div>
        </div>
      )}
       <PartialUpgradeDownGrade
        setPopPartial={setPopPartial}
        popPartial={popPartial}
        data={update}
        notify={notify}
        setReload={setReload}
        reload={reloadW}
      />
      {updateDetail === true ? (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            overflowX: "scroll",
            zIndex: 5,
            height: "100vh",
            width: "100vw",
          }}
        >
          <div
            className="update-form"
            style={{
              position: "relative",
              top: "10%",
            }}
          >
            <div>
              <span
                className="cross"
                style={{
                  position: "absolute",
                  top: "-5px",
                  fontSize: "28px",
                }}
                onClick={() => {
                  setUpdateDetail(false);
                  setUpdateInfoBtn("Update");
                }}
              >
                x
              </span>

              <form
                validate={true.toString()}
                onSubmit={handleSubmit}
                id="create-course-form"
              >
                <div className="row">
                  <div className="col-12">
                    <label>
                      Client Name <span className="required_star">*</span>
                    </label>
                    <div class="input-group mb-1">
                      <input
                        type="text"
                        required
                        class="form-control"
                        defaultValue={update.name}
                        id="client_name"
                        name="client_name"
                        autoComplete="client_name"
                        placeholder="client_name"
                        aria-label="client_name"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label>
                      Address<span className="required_star">*</span>
                    </label>
                    <div class="input-group mb-1">
                      <input
                        type="text"
                        required
                        class="form-control"
                        id="address"
                        defaultValue={update.address}
                        name="address"
                        autoComplete="current-address"
                        placeholder="Address"
                        aria-label="address"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label>Alternate Number</label>
                    <div class="input-group mb-1">
                      <input
                        type="number"
                        onWheel={(event) => event.currentTarget.blur()}
                        className="form-control"
                        id="alternateNumber"
                        min="10"
                        defaultValue={update.alternatePhone}
                        name="alternateNumber"
                        autoComplete="current-alternateNumber"
                        placeholder="alternateNumber"
                        aria-label="alternateNumber"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label>City</label>
                    <div class="input-group mb-1">
                      <SelectCityInput value={citys} setValue={setCity} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label className="create-label1">
                      Subscription Zone <span className="required_star">*</span>
                    </label>
                    <div class="input-group mb-1">
                      <select
                        class="custom-select height-select"
                        required
                        id="inputGroupSelect01"
                        name="incentiveZone"
                        label="incentiveZone"
                        autoComplete="current-incentiveZone"
                        defaultValue={
                          update.zone === "" ? "Select Zone" : update.zone
                        }
                      >
                        <option value={"Select Zone"}>Select Zone</option>
                        {ElectricianIncentiveZone.map((option) => (
                          <option value={option.value}>{option.value}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label>Zip Code</label>
                    <div class="input-group mb-1">
                      <input
                        type="text"
                        class="form-control"
                        defaultValue={update.zip}
                        id="zipCode"
                        name="zipCode"
                        autoComplete="current-zipCode"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label>Comments</label>
                    <div class="input-group mb-1">
                      <input
                        type="text"
                        class="form-control"
                        defaultValue={update.comments}
                        id="comment"
                        name="comment"
                        autoComplete="current-comment"
                        aria-label="comment"
                        aria-describedby="basic-addon1"
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <label className="create-label1">
                      Installation Time?{" "}
                      <span className="required_star">*</span>
                    </label>
                    <div class="input-group mb-1">
                      <select
                        class="custom-select height-select"
                        required
                        id="inputGroupSelect01"
                        name="time"
                        label="time"
                        autoComplete="current-time"
                        defaultValue={"true"}
                        onChange={(e) =>
                          setTimeHave(e.target.value === "false" ? false : true)
                        }
                      >
                        <option value={"true"}>{"Yes"}</option>
                        <option value={"false"}>{"No"}</option>
                      </select>
                    </div>
                  </div>
                </div>
                {timeHave ? (
                  <div className="row">
                    <div className="col-12">
                      <label>
                        Installation Date{" "}
                        {moment.utc(update?.InstallationDate).format("llll")}
                        <span className="required_star">*</span>
                      </label>
                      <div class="input-group mb-1">
                        <input
                          type="datetime-local"
                          class="form-control"
                          ref={installationDates}
                          required
                          defaultValue={moment(update?.InstallationDate).format(
                            "llll"
                          )}
                          id="date"
                          name="date"
                          autoComplete="current-date"
                          aria-label="date"
                          aria-describedby="date"
                        />
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="row">
                    <div className="col-12">
                      <label>
                        Installation Date{" "}
                        {moment.utc(update?.InstallationDate).format("llll")}
                        <span className="required_star">*</span>
                      </label>
                      <div class="input-group mb-1">
                        <input
                          type="date"
                          class="form-control"
                          ref={installationDates}
                          required
                          defaultValue={moment(update?.InstallationDate).format(
                            "llll"
                          )}
                          id="date"
                          name="date"
                          autoComplete="current-date"
                          aria-label="date"
                          aria-describedby="date"
                        />
                      </div>
                    </div>
                  </div>
                )}

                <div className="row">
                  <div className="col-12">
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={updateInfoBtn !== "Update" ? true : false}
                    >
                      {updateInfoBtn}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      ) : null}

      <Qutoes />
    </div>
  );
}
